<template>
  <div :style="{ 'display':'flex','justify-content':'center','align-items':'center','height': '100%','background-image': 'url('+this.pictureUrl+')','background-repeat': 'no-repeat','background-position':position,'background-size': 'cover','font-family': 'inherit'}">
        <div id="__layout" style="width: 90%;">
            <div style="padding-bottom: 20px;">
                <div style="text-align:center;top:-20px;">
                    <img src="../assets/images/flag.png" width="200">
                </div>
                <div style="text-align:center;padding-top: 10px;">
                    <font style="color:#fff;font-size: 28px;font-weight: 900;">ຫວຍລາວເຊົ້າ</font>
                </div>
                <div style="text-align: center;padding-top: 10px;">
                    <font style="color:#fff;font-size: 28px;"><span style="font-style: normal;font-weight: normal;">ງວດວັນທີ</span> <strong>{{ (this.datetime)?formatDate(this.datetime):formatDate(this.first.datetime) }}</strong></font>
                </div>
                <div style="text-align:center;padding-top: 10px;">
                    <font style="color:#fff;font-size: 28px;"><span style="font-style: normal; font-weight: normal;">ເວລາອອກຫວຍ</span> <strong>08:30</strong></font>
                </div>
            </div>
            <div class="container">
                <div id="content">
                        <div class="today-result" style="width: 100%;">
                            <div style="text-align:center;margin-top:10px;padding-bottom:10px;padding-top:10px;">
                                <h1>
                                    <div style="display: flex; justify-content: center;">
                                        <div style="border-color: #0285CD;border-width: 3px;border-style: solid;padding: 2px;font-size: 80px;background-color: #fff;">
                                            <span class="block-numbers" style="color:#f90000;">
                                                <div class="block" style="border-color: #0285CD; border-width: 2px;border-style: solid;">
                                                    <span id="dice5">{{(this.first && this.first.five)?this.first.five.charAt(0):''}}</span>
                                                </div>
                                                <div class="block" style="border-color: #0285CD; border-width: 2px;border-style: solid;  margin-left: 1px;">
                                                    <span id="dice4">{{(this.first && this.first.five)?this.first.five.charAt(1):''}}</span>
                                                </div>
                                                <div class="block" style="border-color: #0285CD; border-width: 2px;border-style: solid; margin-left: 1px;">
                                                    <span id="dice3">{{(this.first && this.first.five)?this.first.five.charAt(2):''}}</span>
                                                </div>
                                                <div class="block" style="border-color: #0285CD; border-width: 2px;border-style: solid;  margin-left: 1px;">
                                                    <span id="dice2">{{(this.first && this.first.five)?this.first.five.charAt(3):''}}</span>
                                                </div>
                                                <div class="block" style="border-color: #0285CD; border-width: 2px;border-style: solid; margin-left: 1px;">
                                                    <span id="dice1">{{(this.first && this.first.five)?this.first.five.charAt(4):''}}</span>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </h1>
                            </div>
                            <div style="display: flex; justify-content: center;">
                                <table>
                                    <tbody v-if="this.first && this.first.five">
                                        <tr>
                                            <td style="color:#fff; font-size: 25px; font-weight: 900;">ລາງວັນເລກ  5 ໂຕ</td>
                                            <td style="color:#fff;">
                                                <div class="block" style="border-color: #0285CD;background-color: #fff; border-width: 2px;border-style: solid; margin-left: 1px;color:red; letter-spacing: 2px;width: 120px;font-size: 35px;">
                                                    <strong>{{(!this.AniDiceRandom)?this.first.five:'-'}}</strong>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="color:#fff; font-size: 25px; font-weight: 900;">ລາງວັນເລກ 4 ໂຕ</td>
                                            <td  v-if="!this.AniDiceRandom" style="color:#fff;">
                                                <div class="block" style="border-color: #0285CD;background-color: #fff; border-width: 2px;border-style: solid; margin-left: 1px;color:red; letter-spacing: 2px;width: 120px;font-size: 35px;">
                                                    <strong>{{this.first.five.charAt(1)}}{{this.first.five.charAt(2)}}{{this.first.five.charAt(3)}}{{this.first.five.charAt(4)}}</strong>
                                                </div>
                                            </td>
                                            <td style="color:#fff; font-size: 25px;" v-else>
                                                <div class="block" style="border-color: #0285CD;background-color: #fff; border-width: 2px;border-style: solid; margin-left: 1px;color:red; letter-spacing: 2px;width: 120px;font-size: 35px;">-</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="color:#fff; font-size: 25px; font-weight: 900;">ລາງວັນເລກ 3 ໂຕ</td>
                                            <td  v-if="!this.AniDiceRandom" style="color:#fff;">
                                                <div class="block" style="border-color: #0285CD;background-color: #fff; border-width: 2px;border-style: solid; margin-left: 1px;color:red; letter-spacing: 2px;width: 120px;font-size: 35px;">
                                                    <strong>{{this.first.five.charAt(2)}}{{this.first.five.charAt(3)}}{{this.first.five.charAt(4)}}</strong>
                                                </div>
                                            </td>
                                            <td style="color:#fff; font-size: 25px;" v-else>
                                                <div class="block" style="border-color: #0285CD;background-color: #fff; border-width: 2px;border-style: solid; margin-left: 1px;color:red; letter-spacing: 2px;width: 120px;font-size: 35px;">-</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="color:#fff; font-size: 25px; font-weight: 900;">ລາງວັນເລກ 2 ໂຕ</td>
                                            <td  v-if="!this.AniDiceRandom" style="color:#fff;">
                                                <div class="block" style="border-color: #0285CD;background-color: #fff; border-width: 2px;border-style: solid; margin-left: 1px;color:red; letter-spacing: 2px;width: 120px;font-size: 35px;">
                                                    <strong>{{this.first.five.charAt(3)}}{{this.first.five.charAt(4)}}</strong>
                                                </div>
                                            </td>
                                            <td style="color:#fff; font-size: 25px;" v-else>
                                                <div class="block" style="border-color: #0285CD;background-color: #fff; border-width: 2px;border-style: solid; margin-left: 1px;color:red; letter-spacing: 2px;width: 120px;font-size: 35px;">-</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="color:#fff; font-size: 25px; font-weight: 900;">ລາງວັນເລກ 1 ໂຕ</td>
                                            <td  v-if="!this.AniDiceRandom" style="color:#fff;">
                                                <div class="block" style="border-color: #0285CD;background-color: #fff; border-width: 2px;border-style: solid; margin-left: 1px;color:red; letter-spacing: 2px;width: 120px;font-size: 35px;">
                                                    <strong>{{this.first.five.charAt(4)}}</strong>
                                                </div>
                                            </td>
                                            <td style="color:#fff; font-size: 25px;" v-else>
                                                <div class="block" style="border-color: #0285CD;background-color: #fff; border-width: 2px;border-style: solid; margin-left: 1px;color:red; letter-spacing: 2px;width: 120px;font-size: 35px;">-</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div align="center" style="max-width: 100%;height: auto;padding-top: 20px;">
                                <div align="center">
                                    <font style="font-size: 25px; color:#fff; font-weight: 900;">ຜລຫວຍຍ້ວນຫລັງ</font>
                                </div>
                                <table
                                    class="tbl"
                                        data-test="historical-data-table"
                                        style="--freeze-column-width:100px;"
                                    >
                                        <thead class="datatable_head__ysELt">
                                        <tr class="datatable_row__qHMpQ">
                                            <th class="datatable_cell__0y0eu">
                                            <div class="datatable_cell__wrapper__ddKL4">
                                                <span style="color:white;font-size: 20px;"><strong>ງວດວັນທີ</strong></span>
                                            </div>
                                            </th>
                                            <th
                                            class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
                                            >
                                            <div class="datatable_cell__wrapper__ddKL4">
                                                <span style="color:white;font-size: 20px;"><strong>ເລຂທີວວກ</strong></span>
                                            </div>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody class="datatable_body__cs8vJ">
                                            <tr class="datatable_row__qHMpQ" data-test="historical-data-table-row" v-for="(item,index) in data">
                                            <td class="datatable_cell__0y0eu font-bold" style="text-align: center;color:#fff;font-size: 18px;">
                                                <strong>{{ showDate(item.datetime) }}</strong>
                                            </td>
                                            <td class="datatable_cell__0y0eu datatable_cell--align-end__fwomz datatable_cell--up__Gt_ed" dir="ltr" style="text-align: center;color:#fff;font-size: 18px;">
                                                <strong>{{item.five}}</strong>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                            </div>
                            <div align="center"><img src="../assets/images/live.gif" style="max-width: 100%;height: auto;opacity: 0;"></div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/app'
import moment from 'moment-timezone'
import axios from 'axios'
export default {
  name: 'Home',
  mounted () {
    if (window.innerWidth < 768){
        this.position = 'center'
        this.pictureUrl = require('../assets/images/img.jpg')
    }else{
        this.position = 'center';
        this.pictureUrl = require('../assets/images/bg.png')
    }
    //this.getBackGround()
    this.getLotto()
    //this.getBanners()
  },
  computed: {
  },
  data () {
    return {
        position:'center',
        AniDiceRandom:false,
      dic: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
      },
      pictureUrl: require('../assets/images/img.jpg'),
      first: '',
      data: [],
      banner: [],
      seconds: 0,
      datetime:'',
    }
  },
  methods: {
    async getTimeServer() {
        return moment().tz('Asia/Bangkok').toISOString()
    },
    showDate(datetime){
      return datetime.split(' ')[0];
    },
    AniDice (dic) {
      this.dic[dic] = setInterval(this.rolldice, 100, dic)
    },
    rolldice (dic) {
      var ranNum = Math.floor(1 + Math.random() * 6)
      document.getElementById('dice' + dic).innerHTML = ranNum
    },
    stopDice (dic) {
      clearInterval(this.dic[dic])
      if (dic == 1) {
        document.getElementById('dice' + dic).innerHTML = this.first.five.charAt(4)
      } else if (dic == 2) {
        document.getElementById('dice' + dic).innerHTML = this.first.five.charAt(3)
      } else if (dic == 3) {
        document.getElementById('dice' + dic).innerHTML = this.first.five.charAt(2)
      } else if (dic == 4) {
        document.getElementById('dice' + dic).innerHTML = this.first.five.charAt(1)
      } else if (dic == 5) {
        document.getElementById('dice' + dic).innerHTML = this.first.five.charAt(0)
      }
    },
    async getLotto () {
        const timezone = await this.getTimeServer()
      const data2 = (await firebase.database().ref('/huay/15').orderByChild('datetime').limitToLast(8).once('value')).val()
      const data = _.orderBy(data2, ['datetime'], ['desc'])
      if (data) {
        for (const x in data) {
          const d = data[x].datetime.split(' ')
          const date = d[0].split('-')
          const time = d[1].split(':')
          const datetime = Number(date[0] + '' + date[1] + '' + date[2] + '' + time[0] + '' + time[1])
          if (datetime > Number(moment(timezone).format('YYYYMMDDHHmm'))) {
            continue
          }
          if (!this.first) { this.first = data[x] }
          this.data.push(data[x])
        }
      }
      setInterval(this.getLastData, 1000)
    },
    getFullYear () {
      return moment().tz('Asia/Bangkok').format('YYYY')
    },
    convertToLaosMonth(month) {
      switch(month) {
        case "January":
          return "ມັງກອນ";
        case "February":
          return "ກຸມພາ";
        case "March":
          return "ມີນາ";
        case "April":
          return "ເມສາ";
        case "May":
          return "ພຶດສະພາ";
        case "June":
          return "ມິຖຸນາ";
        case "July":
          return "ກໍລະກົດ";
        case "August":
          return "ສິງຫາ";
        case "September":
          return "ກັນຍາ";
        case "October":
          return "ຕຸລາ";
        case "November":
          return "ພະຈິກ";
        case "December":
          return "ທັນວາ";
        default:
          return month;
      }
    },
    formatDate (date) {
      const dd = moment(date).tz('Asia/Bangkok').format('DD')
      const year = moment(date).tz('Asia/Bangkok').format('YYYY')
      const month = this.convertToLaosMonth(moment(date).tz('Asia/Bangkok').format('MMMM'))
      return dd + ' ' + month + ' ' + year
    },
    formatTime(date){
      return moment(date).tz('Asia/Bangkok').format('HH:mm')
    },
    dateToday () {
      return moment().tz('Asia/Bangkok').format('dddd, DD MMMM YYYY')
    },
    async getLastData () {
      const timezone = await this.getTimeServer()
      const datas = (await firebase.database().ref('/huay/15').orderByChild('datetime').startAt(moment(timezone).format('YYYY-MM-DD HH:mm')).limitToFirst(1).once('value')).val()
      if (datas) {
        for (const x in datas) {
          const data = datas[x]
          if (data && data.datetime === moment(timezone).format('YYYY-MM-DD HH:mm')) {
            if (this.first.five !== data.five) {
              this.first = data
              setTimeout(() => {
                this.stopDice(1)
                this.AniDiceRandom = false
              }, Number(6 + '000') + 1000)
              setTimeout(() => {
                this.stopDice(2)
              }, Number(5 + '000') + 1000)
              setTimeout(() => {
                this.stopDice(3)
              }, Number(4 + '000') + 1000)
              setTimeout(() => {
                this.stopDice(4)
              }, Number(3 + '000') + 1000)
              setTimeout(() => {
                this.stopDice(5)
              }, Number(2 + '000') + 1000)
            }
          } else {
            const t1 = moment().tz('Asia/Bangkok')
            const t2 = moment(data.datetime).tz('Asia/Bangkok')
            const diff = t2.diff(t1, 'seconds')
            // $('#clock-lottery').FlipClock(diff, {
            //   countdown: true
            // });
            if(diff <= 1800 && this.AniDiceRandom === false){
                this.AniDiceRandom = true;
                this.AniDice(1)
                this.AniDice(2)
                this.AniDice(3)
                this.AniDice(4)
                this.AniDice(5)
                this.datetime = data.datetime
            }
          }
        }
      }
    }
  }
}
</script>
<style scoped>
        @charset "UTF-8";
        /*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
        article,
        aside,
        figcaption,
        figure,
        footer,
        header,
        hgroup,
        main,
        nav,
        section {
            display: block
        }

        body {
            margin: 0;
            font-family: 'Leelawadee UI', Arial, sans-serif;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            text-align: left;
            background-color: #fff
        }

        [tabindex="-1"]:focus:not(.focus-visible),
        [tabindex="-1"]:focus:not(:focus-visible) {
            outline: 0 !important
        }

        hr {
            box-sizing: content-box;
            height: 0;
            overflow: visible
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-top: 0;
            margin-bottom: .5rem
        }

        p {
            margin-top: 0;
            margin-bottom: 1rem
        }

        abbr[data-original-title],
        abbr[title] {
            text-decoration: underline;
            -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted;
            cursor: help;
            border-bottom: 0;
            -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none
        }

        address {
            font-style: normal;
            line-height: inherit
        }

        address,
        dl,
        ol,
        ul {
            margin-bottom: 1rem
        }

        dl,
        ol,
        ul {
            margin-top: 0
        }

        ol ol,
        ol ul,
        ul ol,
        ul ul {
            margin-bottom: 0
        }

        dt {
            font-weight: 700
        }

        dd {
            margin-bottom: .5rem;
            margin-left: 0
        }

        blockquote {
            margin: 0 0 1rem
        }

        b,
        strong {
            font-weight: bolder
        }

        small {
            font-size: 80%
        }

        sub,
        sup {
            position: relative;
            font-size: 75%;
            line-height: 0;
            vertical-align: baseline
        }

        sub {
            bottom: -.25em
        }

        sup {
            top: -.5em
        }

        a {
            color: #007bff;
            text-decoration: none;
            background-color: transparent
        }

        a:hover {
            color: #0056b3;
            text-decoration: underline
        }

        a:not([href]):not([class]),
        a:not([href]):not([class]):hover {
            color: inherit;
            text-decoration: none
        }

        code,
        kbd,
        pre,
        samp {
            font-family: monospace;
            font-size: 1em
        }

        pre {
            margin-top: 0;
            margin-bottom: 1rem;
            overflow: auto;
            -ms-overflow-style: scrollbar
        }

        figure {
            margin: 0 0 1rem
        }

        img {
            border-style: none
        }

        img,
        svg {
            vertical-align: middle
        }

        svg {
            overflow: hidden
        }


        caption {
            padding-top: .75rem;
            padding-bottom: .75rem;
            color: #6c757d;
            text-align: left;
            caption-side: bottom
        }

        th {
            text-align: inherit;
            text-align: -webkit-match-parent
        }

        label {
            display: inline-block;
            margin-bottom: .5rem
        }

        button {
            border-radius: 0
        }

        button:focus:not(.focus-visible),
        button:focus:not(:focus-visible) {
            outline: 0
        }

        button,
        input,
        optgroup,
        select,
        textarea {
            margin: 0;
            font-family: inherit;
            font-size: inherit;
            line-height: inherit
        }

        button,
        input {
            overflow: visible
        }

        button,
        select {
            text-transform: none
        }

        [role=button] {
            cursor: pointer
        }

        select {
            word-wrap: normal
        }

        [type=button],
        [type=reset],
        [type=submit],
        button {
            -webkit-appearance: button
        }

        [type=button]:not(:disabled),
        [type=reset]:not(:disabled),
        [type=submit]:not(:disabled),
        button:not(:disabled) {
            cursor: pointer
        }

        [type=button]::-moz-focus-inner,
        [type=reset]::-moz-focus-inner,
        [type=submit]::-moz-focus-inner,
        button::-moz-focus-inner {
            padding: 0;
            border-style: none
        }

        input[type=checkbox],
        input[type=radio] {
            box-sizing: border-box;
            padding: 0
        }

        textarea {
            overflow: auto;
            resize: vertical
        }

        fieldset {
            min-width: 0;
            padding: 0;
            margin: 0;
            border: 0
        }

        legend {
            display: block;
            width: 100%;
            max-width: 100%;
            padding: 0;
            margin-bottom: .5rem;
            font-size: 1.5rem;
            line-height: inherit;
            color: inherit;
            white-space: normal
        }

        progress {
            vertical-align: baseline
        }

        [type=number]::-webkit-inner-spin-button,
        [type=number]::-webkit-outer-spin-button {
            height: auto
        }

        [type=search] {
            outline-offset: -2px;
            -webkit-appearance: none
        }

        [type=search]::-webkit-search-decoration {
            -webkit-appearance: none
        }

        ::-webkit-file-upload-button {
            font: inherit;
            -webkit-appearance: button
        }

        output {
            display: inline-block
        }

        summary {
            display: list-item;
            cursor: pointer
        }

        template {
            display: none
        }

        [hidden] {
            display: none !important
        }

        .h1,
        .h2,
        .h3,
        .h4,
        .h5,
        .h6,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: .5rem;
            font-weight: 500;
            line-height: 1.2
        }

        .h1,
        h1 {
            font-size: 2.5rem
        }

        .h2,
        h2 {
            font-size: 2rem
        }

        .h3,
        h3 {
            font-size: 1.75rem
        }

        .h4,
        h4 {
            font-size: 1.5rem
        }

        .h5,
        h5 {
            font-size: 1.25rem
        }

        .h6,
        h6 {
            font-size: 1rem
        }

        .lead {
            font-size: 1.25rem;
            font-weight: 300
        }

        .display-1 {
            font-size: 6rem
        }

        .display-1,
        .display-2 {
            font-weight: 300;
            line-height: 1.2
        }

        .display-2 {
            font-size: 5.5rem
        }

        .display-3 {
            font-size: 4.5rem
        }

        .display-3,
        .display-4 {
            font-weight: 300;
            line-height: 1.2
        }

        .display-4 {
            font-size: 3.5rem
        }

        hr {
            margin-top: 1rem;
            margin-bottom: 1rem;
            border: 0;
            border-top: 1px solid rgba(0, 0, 0, .1)
        }

        .small,
        small {
            font-size: 80%;
            font-weight: 400
        }

        .mark,
        mark {
            padding: .2em;
            background-color: #fcf8e3
        }

        .list-inline,
        .list-unstyled {
            padding-left: 0;
            list-style: none
        }

        .list-inline-item {
            display: inline-block
        }

        .list-inline-item:not(:last-child) {
            margin-right: .5rem
        }

        .initialism {
            font-size: 90%;
            text-transform: uppercase
        }

        .blockquote {
            margin-bottom: 1rem;
            font-size: 1.25rem
        }

        .blockquote-footer {
            display: block;
            font-size: 80%;
            color: #6c757d
        }

        .blockquote-footer:before {
            content: "\2014\00A0"
        }

        .img-fluid,
        .img-thumbnail {
            max-width: 100%;
            height: auto
        }

        .img-thumbnail {
            padding: .25rem;
            background-color: #fff;
            border: 1px solid #dee2e6;
            border-radius: .25rem
        }

        .figure {
            display: inline-block
        }

        .figure-img {
            margin-bottom: .5rem;
            line-height: 1
        }

        .figure-caption {
            font-size: 90%;
            color: #6c757d
        }

        code {
            font-size: 87.5%;
            color: #e83e8c;
            word-wrap: break-word
        }

        a>code {
            color: inherit
        }

        kbd {
            padding: .2rem .4rem;
            font-size: 87.5%;
            color: #fff;
            background-color: #212529;
            border-radius: .2rem
        }

        kbd kbd {
            padding: 0;
            font-size: 100%;
            font-weight: 700
        }

        pre {
            display: block;
            font-size: 87.5%;
            color: #212529
        }

        pre code {
            font-size: inherit;
            color: inherit;
            word-break: normal
        }

        .pre-scrollable {
            max-height: 340px;
            overflow-y: scroll
        }

        .container,
        .container-fluid,
        .container-lg,
        .container-md,
        .container-sm,
        .container-xl {
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            margin-right: auto;
            margin-left: auto
        }

        @media (min-width:576px) {

            .container,
            .container-sm {
                max-width: 540px
            }
        }

        @media (min-width:768px) {

            .container,
            .container-md,
            .container-sm {
                max-width: 720px
            }
        }

        @media (min-width:992px) {

            .container,
            .container-lg,
            .container-md,
            .container-sm {
                max-width: 960px
            }
        }

        @media (min-width:1200px) {

            .container,
            .container-lg,
            .container-md,
            .container-sm,
            .container-xl {
                max-width: 1140px
            }
        }

        .row {
            display: flex;
            flex-wrap: wrap;
            margin-right: -15px;
            margin-left: -15px
        }

        .no-gutters {
            margin-right: 0;
            margin-left: 0
        }

        .no-gutters>.col,
        .no-gutters>[class*=col-] {
            padding-right: 0;
            padding-left: 0
        }

        .col,
        .col-1,
        .col-10,
        .col-11,
        .col-12,
        .col-2,
        .col-3,
        .col-4,
        .col-5,
        .col-6,
        .col-7,
        .col-8,
        .col-9,
        .col-auto,
        .col-lg,
        .col-lg-1,
        .col-lg-10,
        .col-lg-11,
        .col-lg-12,
        .col-lg-2,
        .col-lg-3,
        .col-lg-4,
        .col-lg-5,
        .col-lg-6,
        .col-lg-7,
        .col-lg-8,
        .col-lg-9,
        .col-lg-auto,
        .col-md,
        .col-md-1,
        .col-md-10,
        .col-md-11,
        .col-md-12,
        .col-md-2,
        .col-md-3,
        .col-md-4,
        .col-md-5,
        .col-md-6,
        .col-md-7,
        .col-md-8,
        .col-md-9,
        .col-md-auto,
        .col-sm,
        .col-sm-1,
        .col-sm-10,
        .col-sm-11,
        .col-sm-12,
        .col-sm-2,
        .col-sm-3,
        .col-sm-4,
        .col-sm-5,
        .col-sm-6,
        .col-sm-7,
        .col-sm-8,
        .col-sm-9,
        .col-sm-auto,
        .col-xl,
        .col-xl-1,
        .col-xl-10,
        .col-xl-11,
        .col-xl-12,
        .col-xl-2,
        .col-xl-3,
        .col-xl-4,
        .col-xl-5,
        .col-xl-6,
        .col-xl-7,
        .col-xl-8,
        .col-xl-9,
        .col-xl-auto {
            position: relative;
            width: 100%;
            padding-right: 15px;
            padding-left: 15px
        }

        .col {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%
        }

        .row-cols-1>* {
            flex: 0 0 100%;
            max-width: 100%
        }

        .row-cols-2>* {
            flex: 0 0 50%;
            max-width: 50%
        }

        .row-cols-3>* {
            flex: 0 0 33.333333%;
            max-width: 33.333333%
        }

        .row-cols-4>* {
            flex: 0 0 25%;
            max-width: 25%
        }

        .row-cols-5>* {
            flex: 0 0 20%;
            max-width: 20%
        }

        .row-cols-6>* {
            flex: 0 0 16.666667%;
            max-width: 16.666667%
        }

        .col-auto {
            flex: 0 0 auto;
            width: auto;
            max-width: 100%
        }

        .col-1 {
            flex: 0 0 8.333333%;
            max-width: 8.333333%
        }

        .col-2 {
            flex: 0 0 16.666667%;
            max-width: 16.666667%
        }

        .col-3 {
            flex: 0 0 25%;
            max-width: 25%
        }

        .col-4 {
            flex: 0 0 33.333333%;
            max-width: 33.333333%
        }

        .col-5 {
            flex: 0 0 41.666667%;
            max-width: 41.666667%
        }

        .col-6 {
            flex: 0 0 50%;
            max-width: 50%
        }

        .col-7 {
            flex: 0 0 58.333333%;
            max-width: 58.333333%
        }

        .col-8 {
            flex: 0 0 66.666667%;
            max-width: 66.666667%
        }

        .col-9 {
            flex: 0 0 75%;
            max-width: 75%
        }

        .col-10 {
            flex: 0 0 83.333333%;
            max-width: 83.333333%
        }

        .col-11 {
            flex: 0 0 91.666667%;
            max-width: 91.666667%
        }

        .col-12 {
            flex: 0 0 100%;
            max-width: 100%
        }

        .order-first {
            order: -1
        }

        .order-last {
            order: 13
        }

        .order-0 {
            order: 0
        }

        .order-1 {
            order: 1
        }

        .order-2 {
            order: 2
        }

        .order-3 {
            order: 3
        }

        .order-4 {
            order: 4
        }

        .order-5 {
            order: 5
        }

        .order-6 {
            order: 6
        }

        .order-7 {
            order: 7
        }

        .order-8 {
            order: 8
        }

        .order-9 {
            order: 9
        }

        .order-10 {
            order: 10
        }

        .order-11 {
            order: 11
        }

        .order-12 {
            order: 12
        }

        .offset-1 {
            margin-left: 8.333333%
        }

        .offset-2 {
            margin-left: 16.666667%
        }

        .offset-3 {
            margin-left: 25%
        }

        .offset-4 {
            margin-left: 33.333333%
        }

        .offset-5 {
            margin-left: 41.666667%
        }

        .offset-6 {
            margin-left: 50%
        }

        .offset-7 {
            margin-left: 58.333333%
        }

        .offset-8 {
            margin-left: 66.666667%
        }

        .offset-9 {
            margin-left: 75%
        }

        .offset-10 {
            margin-left: 83.333333%
        }

        .offset-11 {
            margin-left: 91.666667%
        }

        @media (min-width:576px) {
            .col-sm {
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%
            }

            .row-cols-sm-1>* {
                flex: 0 0 100%;
                max-width: 100%
            }

            .row-cols-sm-2>* {
                flex: 0 0 50%;
                max-width: 50%
            }

            .row-cols-sm-3>* {
                flex: 0 0 33.333333%;
                max-width: 33.333333%
            }

            .row-cols-sm-4>* {
                flex: 0 0 25%;
                max-width: 25%
            }

            .row-cols-sm-5>* {
                flex: 0 0 20%;
                max-width: 20%
            }

            .row-cols-sm-6>* {
                flex: 0 0 16.666667%;
                max-width: 16.666667%
            }

            .col-sm-auto {
                flex: 0 0 auto;
                width: auto;
                max-width: 100%
            }

            .col-sm-1 {
                flex: 0 0 8.333333%;
                max-width: 8.333333%
            }

            .col-sm-2 {
                flex: 0 0 16.666667%;
                max-width: 16.666667%
            }

            .col-sm-3 {
                flex: 0 0 25%;
                max-width: 25%
            }

            .col-sm-4 {
                flex: 0 0 33.333333%;
                max-width: 33.333333%
            }

            .col-sm-5 {
                flex: 0 0 41.666667%;
                max-width: 41.666667%
            }

            .col-sm-6 {
                flex: 0 0 50%;
                max-width: 50%
            }

            .col-sm-7 {
                flex: 0 0 58.333333%;
                max-width: 58.333333%
            }

            .col-sm-8 {
                flex: 0 0 66.666667%;
                max-width: 66.666667%
            }

            .col-sm-9 {
                flex: 0 0 75%;
                max-width: 75%
            }

            .col-sm-10 {
                flex: 0 0 83.333333%;
                max-width: 83.333333%
            }

            .col-sm-11 {
                flex: 0 0 91.666667%;
                max-width: 91.666667%
            }

            .col-sm-12 {
                flex: 0 0 100%;
                max-width: 100%
            }

            .order-sm-first {
                order: -1
            }

            .order-sm-last {
                order: 13
            }

            .order-sm-0 {
                order: 0
            }

            .order-sm-1 {
                order: 1
            }

            .order-sm-2 {
                order: 2
            }

            .order-sm-3 {
                order: 3
            }

            .order-sm-4 {
                order: 4
            }

            .order-sm-5 {
                order: 5
            }

            .order-sm-6 {
                order: 6
            }

            .order-sm-7 {
                order: 7
            }

            .order-sm-8 {
                order: 8
            }

            .order-sm-9 {
                order: 9
            }

            .order-sm-10 {
                order: 10
            }

            .order-sm-11 {
                order: 11
            }

            .order-sm-12 {
                order: 12
            }

            .offset-sm-0 {
                margin-left: 0
            }

            .offset-sm-1 {
                margin-left: 8.333333%
            }

            .offset-sm-2 {
                margin-left: 16.666667%
            }

            .offset-sm-3 {
                margin-left: 25%
            }

            .offset-sm-4 {
                margin-left: 33.333333%
            }

            .offset-sm-5 {
                margin-left: 41.666667%
            }

            .offset-sm-6 {
                margin-left: 50%
            }

            .offset-sm-7 {
                margin-left: 58.333333%
            }

            .offset-sm-8 {
                margin-left: 66.666667%
            }

            .offset-sm-9 {
                margin-left: 75%
            }

            .offset-sm-10 {
                margin-left: 83.333333%
            }

            .offset-sm-11 {
                margin-left: 91.666667%
            }
        }

        @media (min-width:768px) {
            .col-md {
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%
            }

            .row-cols-md-1>* {
                flex: 0 0 100%;
                max-width: 100%
            }

            .row-cols-md-2>* {
                flex: 0 0 50%;
                max-width: 50%
            }

            .row-cols-md-3>* {
                flex: 0 0 33.333333%;
                max-width: 33.333333%
            }

            .row-cols-md-4>* {
                flex: 0 0 25%;
                max-width: 25%
            }

            .row-cols-md-5>* {
                flex: 0 0 20%;
                max-width: 20%
            }

            .row-cols-md-6>* {
                flex: 0 0 16.666667%;
                max-width: 16.666667%
            }

            .col-md-auto {
                flex: 0 0 auto;
                width: auto;
                max-width: 100%
            }

            .col-md-1 {
                flex: 0 0 8.333333%;
                max-width: 8.333333%
            }

            .col-md-2 {
                flex: 0 0 16.666667%;
                max-width: 16.666667%
            }

            .col-md-3 {
                flex: 0 0 25%;
                max-width: 25%
            }

            .col-md-4 {
                flex: 0 0 33.333333%;
                max-width: 33.333333%
            }

            .col-md-5 {
                flex: 0 0 41.666667%;
                max-width: 41.666667%
            }

            .col-md-6 {
                flex: 0 0 50%;
                max-width: 50%
            }

            .col-md-7 {
                flex: 0 0 58.333333%;
                max-width: 58.333333%
            }

            .col-md-8 {
                flex: 0 0 66.666667%;
                max-width: 66.666667%
            }

            .col-md-9 {
                flex: 0 0 75%;
                max-width: 75%
            }

            .col-md-10 {
                flex: 0 0 83.333333%;
                max-width: 83.333333%
            }

            .col-md-11 {
                flex: 0 0 91.666667%;
                max-width: 91.666667%
            }

            .col-md-12 {
                flex: 0 0 100%;
                max-width: 100%
            }

            .order-md-first {
                order: -1
            }

            .order-md-last {
                order: 13
            }

            .order-md-0 {
                order: 0
            }

            .order-md-1 {
                order: 1
            }

            .order-md-2 {
                order: 2
            }

            .order-md-3 {
                order: 3
            }

            .order-md-4 {
                order: 4
            }

            .order-md-5 {
                order: 5
            }

            .order-md-6 {
                order: 6
            }

            .order-md-7 {
                order: 7
            }

            .order-md-8 {
                order: 8
            }

            .order-md-9 {
                order: 9
            }

            .order-md-10 {
                order: 10
            }

            .order-md-11 {
                order: 11
            }

            .order-md-12 {
                order: 12
            }

            .offset-md-0 {
                margin-left: 0
            }

            .offset-md-1 {
                margin-left: 8.333333%
            }

            .offset-md-2 {
                margin-left: 16.666667%
            }

            .offset-md-3 {
                margin-left: 25%
            }

            .offset-md-4 {
                margin-left: 33.333333%
            }

            .offset-md-5 {
                margin-left: 41.666667%
            }

            .offset-md-6 {
                margin-left: 50%
            }

            .offset-md-7 {
                margin-left: 58.333333%
            }

            .offset-md-8 {
                margin-left: 66.666667%
            }

            .offset-md-9 {
                margin-left: 75%
            }

            .offset-md-10 {
                margin-left: 83.333333%
            }

            .offset-md-11 {
                margin-left: 91.666667%
            }
        }

        @media (min-width:992px) {
            .col-lg {
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%
            }

            .row-cols-lg-1>* {
                flex: 0 0 100%;
                max-width: 100%
            }

            .row-cols-lg-2>* {
                flex: 0 0 50%;
                max-width: 50%
            }

            .row-cols-lg-3>* {
                flex: 0 0 33.333333%;
                max-width: 33.333333%
            }

            .row-cols-lg-4>* {
                flex: 0 0 25%;
                max-width: 25%
            }

            .row-cols-lg-5>* {
                flex: 0 0 20%;
                max-width: 20%
            }

            .row-cols-lg-6>* {
                flex: 0 0 16.666667%;
                max-width: 16.666667%
            }

            .col-lg-auto {
                flex: 0 0 auto;
                width: auto;
                max-width: 100%
            }

            .col-lg-1 {
                flex: 0 0 8.333333%;
                max-width: 8.333333%
            }

            .col-lg-2 {
                flex: 0 0 16.666667%;
                max-width: 16.666667%
            }

            .col-lg-3 {
                flex: 0 0 25%;
                max-width: 25%
            }

            .col-lg-4 {
                flex: 0 0 33.333333%;
                max-width: 33.333333%
            }

            .col-lg-5 {
                flex: 0 0 41.666667%;
                max-width: 41.666667%
            }

            .col-lg-6 {
                flex: 0 0 50%;
                max-width: 50%
            }

            .col-lg-7 {
                flex: 0 0 58.333333%;
                max-width: 58.333333%
            }

            .col-lg-8 {
                flex: 0 0 66.666667%;
                max-width: 66.666667%
            }

            .col-lg-9 {
                flex: 0 0 75%;
                max-width: 75%
            }

            .col-lg-10 {
                flex: 0 0 83.333333%;
                max-width: 83.333333%
            }

            .col-lg-11 {
                flex: 0 0 91.666667%;
                max-width: 91.666667%
            }

            .col-lg-12 {
                flex: 0 0 100%;
                max-width: 100%
            }

            .order-lg-first {
                order: -1
            }

            .order-lg-last {
                order: 13
            }

            .order-lg-0 {
                order: 0
            }

            .order-lg-1 {
                order: 1
            }

            .order-lg-2 {
                order: 2
            }

            .order-lg-3 {
                order: 3
            }

            .order-lg-4 {
                order: 4
            }

            .order-lg-5 {
                order: 5
            }

            .order-lg-6 {
                order: 6
            }

            .order-lg-7 {
                order: 7
            }

            .order-lg-8 {
                order: 8
            }

            .order-lg-9 {
                order: 9
            }

            .order-lg-10 {
                order: 10
            }

            .order-lg-11 {
                order: 11
            }

            .order-lg-12 {
                order: 12
            }

            .offset-lg-0 {
                margin-left: 0
            }

            .offset-lg-1 {
                margin-left: 8.333333%
            }

            .offset-lg-2 {
                margin-left: 16.666667%
            }

            .offset-lg-3 {
                margin-left: 25%
            }

            .offset-lg-4 {
                margin-left: 33.333333%
            }

            .offset-lg-5 {
                margin-left: 41.666667%
            }

            .offset-lg-6 {
                margin-left: 50%
            }

            .offset-lg-7 {
                margin-left: 58.333333%
            }

            .offset-lg-8 {
                margin-left: 66.666667%
            }

            .offset-lg-9 {
                margin-left: 75%
            }

            .offset-lg-10 {
                margin-left: 83.333333%
            }

            .offset-lg-11 {
                margin-left: 91.666667%
            }
        }

        @media (min-width:1200px) {
            .col-xl {
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%
            }

            .row-cols-xl-1>* {
                flex: 0 0 100%;
                max-width: 100%
            }

            .row-cols-xl-2>* {
                flex: 0 0 50%;
                max-width: 50%
            }

            .row-cols-xl-3>* {
                flex: 0 0 33.333333%;
                max-width: 33.333333%
            }

            .row-cols-xl-4>* {
                flex: 0 0 25%;
                max-width: 25%
            }

            .row-cols-xl-5>* {
                flex: 0 0 20%;
                max-width: 20%
            }

            .row-cols-xl-6>* {
                flex: 0 0 16.666667%;
                max-width: 16.666667%
            }

            .col-xl-auto {
                flex: 0 0 auto;
                width: auto;
                max-width: 100%
            }

            .col-xl-1 {
                flex: 0 0 8.333333%;
                max-width: 8.333333%
            }

            .col-xl-2 {
                flex: 0 0 16.666667%;
                max-width: 16.666667%
            }

            .col-xl-3 {
                flex: 0 0 25%;
                max-width: 25%
            }

            .col-xl-4 {
                flex: 0 0 33.333333%;
                max-width: 33.333333%
            }

            .col-xl-5 {
                flex: 0 0 41.666667%;
                max-width: 41.666667%
            }

            .col-xl-6 {
                flex: 0 0 50%;
                max-width: 50%
            }

            .col-xl-7 {
                flex: 0 0 58.333333%;
                max-width: 58.333333%
            }

            .col-xl-8 {
                flex: 0 0 66.666667%;
                max-width: 66.666667%
            }

            .col-xl-9 {
                flex: 0 0 75%;
                max-width: 75%
            }

            .col-xl-10 {
                flex: 0 0 83.333333%;
                max-width: 83.333333%
            }

            .col-xl-11 {
                flex: 0 0 91.666667%;
                max-width: 91.666667%
            }

            .col-xl-12 {
                flex: 0 0 100%;
                max-width: 100%
            }

            .order-xl-first {
                order: -1
            }

            .order-xl-last {
                order: 13
            }

            .order-xl-0 {
                order: 0
            }

            .order-xl-1 {
                order: 1
            }

            .order-xl-2 {
                order: 2
            }

            .order-xl-3 {
                order: 3
            }

            .order-xl-4 {
                order: 4
            }

            .order-xl-5 {
                order: 5
            }

            .order-xl-6 {
                order: 6
            }

            .order-xl-7 {
                order: 7
            }

            .order-xl-8 {
                order: 8
            }

            .order-xl-9 {
                order: 9
            }

            .order-xl-10 {
                order: 10
            }

            .order-xl-11 {
                order: 11
            }

            .order-xl-12 {
                order: 12
            }

            .offset-xl-0 {
                margin-left: 0
            }

            .offset-xl-1 {
                margin-left: 8.333333%
            }

            .offset-xl-2 {
                margin-left: 16.666667%
            }

            .offset-xl-3 {
                margin-left: 25%
            }

            .offset-xl-4 {
                margin-left: 33.333333%
            }

            .offset-xl-5 {
                margin-left: 41.666667%
            }

            .offset-xl-6 {
                margin-left: 50%
            }

            .offset-xl-7 {
                margin-left: 58.333333%
            }

            .offset-xl-8 {
                margin-left: 66.666667%
            }

            .offset-xl-9 {
                margin-left: 75%
            }

            .offset-xl-10 {
                margin-left: 83.333333%
            }

            .offset-xl-11 {
                margin-left: 91.666667%
            }
        }

        .form-control {
            display: block;
            width: 100%;
            height: calc(1.5em + .75rem + 2px);
            padding: .375rem .75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: .25rem;
            transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
        }

        @media (prefers-reduced-motion:reduce) {
            .form-control {
                transition: none
            }
        }

        .form-control::-ms-expand {
            background-color: transparent;
            border: 0
        }

        .form-control:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 #495057
        }

        .form-control:focus {
            color: #495057;
            background-color: #fff;
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
        }

        .form-control::-moz-placeholder {
            color: #6c757d;
            opacity: 1
        }

        .form-control:-ms-input-placeholder {
            color: #6c757d;
            opacity: 1
        }

        .form-control::placeholder {
            color: #6c757d;
            opacity: 1
        }

        .form-control:disabled,
        .form-control[readonly] {
            background-color: #e9ecef;
            opacity: 1
        }

        input[type=date].form-control,
        input[type=datetime-local].form-control,
        input[type=month].form-control,
        input[type=time].form-control {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none
        }

        select.form-control:focus::-ms-value {
            color: #495057;
            background-color: #fff
        }

        .form-control-file,
        .form-control-range {
            display: block;
            width: 100%
        }

        .col-form-label {
            padding-top: calc(.375rem + 1px);
            padding-bottom: calc(.375rem + 1px);
            margin-bottom: 0;
            font-size: inherit;
            line-height: 1.5
        }

        .col-form-label-lg {
            padding-top: calc(.5rem + 1px);
            padding-bottom: calc(.5rem + 1px);
            font-size: 1.25rem;
            line-height: 1.5
        }

        .col-form-label-sm {
            padding-top: calc(.25rem + 1px);
            padding-bottom: calc(.25rem + 1px);
            font-size: .875rem;
            line-height: 1.5
        }

        .form-control-plaintext {
            display: block;
            width: 100%;
            padding: .375rem 0;
            margin-bottom: 0;
            font-size: 1rem;
            line-height: 1.5;
            color: #212529;
            background-color: transparent;
            border: solid transparent;
            border-width: 1px 0
        }

        .form-control-plaintext.form-control-lg,
        .form-control-plaintext.form-control-sm {
            padding-right: 0;
            padding-left: 0
        }

        .form-control-sm {
            height: calc(1.5em + .5rem + 2px);
            padding: .25rem .5rem;
            font-size: .875rem;
            line-height: 1.5;
            border-radius: .2rem
        }

        .form-control-lg {
            height: calc(1.5em + 1rem + 2px);
            padding: .5rem 1rem;
            font-size: 1.25rem;
            line-height: 1.5;
            border-radius: .3rem
        }

        select.form-control[multiple],
        select.form-control[size],
        textarea.form-control {
            height: auto
        }

        .form-group {
            margin-bottom: 1rem
        }

        .form-text {
            display: block;
            margin-top: .25rem
        }

        .form-row {
            display: flex;
            flex-wrap: wrap;
            margin-right: -5px;
            margin-left: -5px
        }

        .form-row>.col,
        .form-row>[class*=col-] {
            padding-right: 5px;
            padding-left: 5px
        }

        .form-check {
            position: relative;
            display: block;
            padding-left: 1.25rem
        }

        .form-check-input {
            position: absolute;
            margin-top: .3rem;
            margin-left: -1.25rem
        }

        .form-check-input:disabled~.form-check-label,
        .form-check-input[disabled]~.form-check-label {
            color: #6c757d
        }

        .form-check-label {
            margin-bottom: 0
        }

        .form-check-inline {
            display: inline-flex;
            align-items: center;
            padding-left: 0;
            margin-right: .75rem
        }

        .form-check-inline .form-check-input {
            position: static;
            margin-top: 0;
            margin-right: .3125rem;
            margin-left: 0
        }

        .valid-feedback {
            display: none;
            width: 100%;
            margin-top: .25rem;
            font-size: 80%;
            color: #28a745
        }

        .valid-tooltip {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 5;
            display: none;
            max-width: 100%;
            padding: .25rem .5rem;
            margin-top: .1rem;
            font-size: .875rem;
            line-height: 1.5;
            color: #fff;
            background-color: rgba(40, 167, 69, .9);
            border-radius: .25rem
        }

        .form-row>.col>.valid-tooltip,
        .form-row>[class*=col-]>.valid-tooltip {
            left: 5px
        }

        .is-valid~.valid-feedback,
        .is-valid~.valid-tooltip,
        .was-validated :valid~.valid-feedback,
        .was-validated :valid~.valid-tooltip {
            display: block
        }

        .form-control.is-valid,
        .was-validated .form-control:valid {
            border-color: #28a745;
            padding-right: calc(1.5em + .75rem);
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: right calc(.375em + .1875rem) center;
            background-size: calc(.75em + .375rem) calc(.75em + .375rem)
        }

        .form-control.is-valid:focus,
        .was-validated .form-control:valid:focus {
            border-color: #28a745;
            box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
        }

        .was-validated textarea.form-control:valid,
        textarea.form-control.is-valid {
            padding-right: calc(1.5em + .75rem);
            background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
        }

        .custom-select.is-valid,
        .was-validated .custom-select:valid {
            border-color: #28a745;
            padding-right: calc(.75em + 2.3125rem);
            background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right .75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E") center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem) no-repeat
        }

        .custom-select.is-valid:focus,
        .was-validated .custom-select:valid:focus {
            border-color: #28a745;
            box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
        }

        .form-check-input.is-valid~.form-check-label,
        .was-validated .form-check-input:valid~.form-check-label {
            color: #28a745
        }

        .form-check-input.is-valid~.valid-feedback,
        .form-check-input.is-valid~.valid-tooltip,
        .was-validated .form-check-input:valid~.valid-feedback,
        .was-validated .form-check-input:valid~.valid-tooltip {
            display: block
        }

        .custom-control-input.is-valid~.custom-control-label,
        .was-validated .custom-control-input:valid~.custom-control-label {
            color: #28a745
        }

        .custom-control-input.is-valid~.custom-control-label:before,
        .was-validated .custom-control-input:valid~.custom-control-label:before {
            border-color: #28a745
        }

        .custom-control-input.is-valid:checked~.custom-control-label:before,
        .was-validated .custom-control-input:valid:checked~.custom-control-label:before {
            border-color: #34ce57;
            background-color: #34ce57
        }

        .custom-control-input.is-valid:focus~.custom-control-label:before,
        .was-validated .custom-control-input:valid:focus~.custom-control-label:before {
            box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
        }

        .custom-control-input.is-valid:focus:not(:checked)~.custom-control-label:before,
        .custom-file-input.is-valid~.custom-file-label,
        .was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label:before,
        .was-validated .custom-file-input:valid~.custom-file-label {
            border-color: #28a745
        }

        .custom-file-input.is-valid:focus~.custom-file-label,
        .was-validated .custom-file-input:valid:focus~.custom-file-label {
            border-color: #28a745;
            box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
        }

        .invalid-feedback {
            display: none;
            width: 100%;
            margin-top: .25rem;
            font-size: 80%;
            color: #dc3545
        }

        .invalid-tooltip {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 5;
            display: none;
            max-width: 100%;
            padding: .25rem .5rem;
            margin-top: .1rem;
            font-size: .875rem;
            line-height: 1.5;
            color: #fff;
            background-color: rgba(220, 53, 69, .9);
            border-radius: .25rem
        }

        .form-row>.col>.invalid-tooltip,
        .form-row>[class*=col-]>.invalid-tooltip {
            left: 5px
        }

        .is-invalid~.invalid-feedback,
        .is-invalid~.invalid-tooltip,
        .was-validated :invalid~.invalid-feedback,
        .was-validated :invalid~.invalid-tooltip {
            display: block
        }

        .form-control.is-invalid,
        .was-validated .form-control:invalid {
            border-color: #dc3545;
            padding-right: calc(1.5em + .75rem);
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: right calc(.375em + .1875rem) center;
            background-size: calc(.75em + .375rem) calc(.75em + .375rem)
        }

        .form-control.is-invalid:focus,
        .was-validated .form-control:invalid:focus {
            border-color: #dc3545;
            box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
        }

        .was-validated textarea.form-control:invalid,
        textarea.form-control.is-invalid {
            padding-right: calc(1.5em + .75rem);
            background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
        }

        .custom-select.is-invalid,
        .was-validated .custom-select:invalid {
            border-color: #dc3545;
            padding-right: calc(.75em + 2.3125rem);
            background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right .75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E") center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem) no-repeat
        }

        .custom-select.is-invalid:focus,
        .was-validated .custom-select:invalid:focus {
            border-color: #dc3545;
            box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
        }

        .form-check-input.is-invalid~.form-check-label,
        .was-validated .form-check-input:invalid~.form-check-label {
            color: #dc3545
        }

        .form-check-input.is-invalid~.invalid-feedback,
        .form-check-input.is-invalid~.invalid-tooltip,
        .was-validated .form-check-input:invalid~.invalid-feedback,
        .was-validated .form-check-input:invalid~.invalid-tooltip {
            display: block
        }

        .custom-control-input.is-invalid~.custom-control-label,
        .was-validated .custom-control-input:invalid~.custom-control-label {
            color: #dc3545
        }

        .custom-control-input.is-invalid~.custom-control-label:before,
        .was-validated .custom-control-input:invalid~.custom-control-label:before {
            border-color: #dc3545
        }

        .custom-control-input.is-invalid:checked~.custom-control-label:before,
        .was-validated .custom-control-input:invalid:checked~.custom-control-label:before {
            border-color: #e4606d;
            background-color: #e4606d
        }

        .custom-control-input.is-invalid:focus~.custom-control-label:before,
        .was-validated .custom-control-input:invalid:focus~.custom-control-label:before {
            box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
        }

        .custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label:before,
        .custom-file-input.is-invalid~.custom-file-label,
        .was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label:before,
        .was-validated .custom-file-input:invalid~.custom-file-label {
            border-color: #dc3545
        }

        .custom-file-input.is-invalid:focus~.custom-file-label,
        .was-validated .custom-file-input:invalid:focus~.custom-file-label {
            border-color: #dc3545;
            box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
        }

        .form-inline {
            display: flex;
            flex-flow: row wrap;
            align-items: center
        }

        .form-inline .form-check {
            width: 100%
        }

        @media (min-width:576px) {
            .form-inline label {
                justify-content: center
            }

            .form-inline .form-group,
            .form-inline label {
                display: flex;
                align-items: center;
                margin-bottom: 0
            }

            .form-inline .form-group {
                flex: 0 0 auto;
                flex-flow: row wrap
            }

            .form-inline .form-control {
                display: inline-block;
                width: auto;
                vertical-align: middle
            }

            .form-inline .form-control-plaintext {
                display: inline-block
            }

            .form-inline .custom-select,
            .form-inline .input-group {
                width: auto
            }

            .form-inline .form-check {
                display: flex;
                align-items: center;
                justify-content: center;
                width: auto;
                padding-left: 0
            }

            .form-inline .form-check-input {
                position: relative;
                flex-shrink: 0;
                margin-top: 0;
                margin-right: .25rem;
                margin-left: 0
            }

            .form-inline .custom-control {
                align-items: center;
                justify-content: center
            }

            .form-inline .custom-control-label {
                margin-bottom: 0
            }
        }

        .btn {
            display: inline-block;
            font-weight: 400;
            color: #212529;
            text-align: center;
            vertical-align: middle;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            background-color: transparent;
            border: 1px solid transparent;
            padding: .375rem .75rem;
            font-size: 1rem;
            line-height: 1.5;
            border-radius: .25rem;
            transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
        }

        @media (prefers-reduced-motion:reduce) {
            .btn {
                transition: none
            }
        }

        .btn:hover {
            color: #212529;
            text-decoration: none
        }

        .btn.focus,
        .btn:focus {
            outline: 0;
            box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
        }

        .btn.disabled,
        .btn:disabled {
            opacity: .65
        }

        .btn:not(:disabled):not(.disabled) {
            cursor: pointer
        }

        a.btn.disabled,
        fieldset:disabled a.btn {
            pointer-events: none
        }

        .btn-primary {
            color: #fff;
            background-color: #007bff;
            border-color: #007bff
        }

        .btn-primary.focus,
        .btn-primary:focus,
        .btn-primary:hover {
            color: #fff;
            background-color: #0069d9;
            border-color: #0062cc
        }

        .btn-primary.focus,
        .btn-primary:focus {
            box-shadow: 0 0 0 .2rem rgba(38, 143, 255, .5)
        }

        .btn-primary.disabled,
        .btn-primary:disabled {
            color: #fff;
            background-color: #007bff;
            border-color: #007bff
        }

        .btn-primary:not(:disabled):not(.disabled).active,
        .btn-primary:not(:disabled):not(.disabled):active,
        .show>.btn-primary.dropdown-toggle {
            color: #fff;
            background-color: #0062cc;
            border-color: #005cbf
        }

        .btn-primary:not(:disabled):not(.disabled).active:focus,
        .btn-primary:not(:disabled):not(.disabled):active:focus,
        .show>.btn-primary.dropdown-toggle:focus {
            box-shadow: 0 0 0 .2rem rgba(38, 143, 255, .5)
        }

        .btn-secondary {
            color: #fff;
            background-color: #6c757d;
            border-color: #6c757d
        }

        .btn-secondary.focus,
        .btn-secondary:focus,
        .btn-secondary:hover {
            color: #fff;
            background-color: #5a6268;
            border-color: #545b62
        }

        .btn-secondary.focus,
        .btn-secondary:focus {
            box-shadow: 0 0 0 .2rem rgba(130, 138, 145, .5)
        }

        .btn-secondary.disabled,
        .btn-secondary:disabled {
            color: #fff;
            background-color: #6c757d;
            border-color: #6c757d
        }

        .btn-secondary:not(:disabled):not(.disabled).active,
        .btn-secondary:not(:disabled):not(.disabled):active,
        .show>.btn-secondary.dropdown-toggle {
            color: #fff;
            background-color: #545b62;
            border-color: #4e555b
        }

        .btn-secondary:not(:disabled):not(.disabled).active:focus,
        .btn-secondary:not(:disabled):not(.disabled):active:focus,
        .show>.btn-secondary.dropdown-toggle:focus {
            box-shadow: 0 0 0 .2rem rgba(130, 138, 145, .5)
        }

        .btn-success {
            color: #fff;
            background-color: #28a745;
            border-color: #28a745
        }

        .btn-success.focus,
        .btn-success:focus,
        .btn-success:hover {
            color: #fff;
            background-color: #218838;
            border-color: #1e7e34
        }

        .btn-success.focus,
        .btn-success:focus {
            box-shadow: 0 0 0 .2rem rgba(72, 180, 97, .5)
        }

        .btn-success.disabled,
        .btn-success:disabled {
            color: #fff;
            background-color: #28a745;
            border-color: #28a745
        }

        .btn-success:not(:disabled):not(.disabled).active,
        .btn-success:not(:disabled):not(.disabled):active,
        .show>.btn-success.dropdown-toggle {
            color: #fff;
            background-color: #1e7e34;
            border-color: #1c7430
        }

        .btn-success:not(:disabled):not(.disabled).active:focus,
        .btn-success:not(:disabled):not(.disabled):active:focus,
        .show>.btn-success.dropdown-toggle:focus {
            box-shadow: 0 0 0 .2rem rgba(72, 180, 97, .5)
        }

        .btn-info {
            color: #fff;
            background-color: #17a2b8;
            border-color: #17a2b8
        }

        .btn-info.focus,
        .btn-info:focus,
        .btn-info:hover {
            color: #fff;
            background-color: #138496;
            border-color: #117a8b
        }

        .btn-info.focus,
        .btn-info:focus {
            box-shadow: 0 0 0 .2rem rgba(58, 176, 195, .5)
        }

        .btn-info.disabled,
        .btn-info:disabled {
            color: #fff;
            background-color: #17a2b8;
            border-color: #17a2b8
        }

        .btn-info:not(:disabled):not(.disabled).active,
        .btn-info:not(:disabled):not(.disabled):active,
        .show>.btn-info.dropdown-toggle {
            color: #fff;
            background-color: #117a8b;
            border-color: #10707f
        }

        .btn-info:not(:disabled):not(.disabled).active:focus,
        .btn-info:not(:disabled):not(.disabled):active:focus,
        .show>.btn-info.dropdown-toggle:focus {
            box-shadow: 0 0 0 .2rem rgba(58, 176, 195, .5)
        }

        .btn-warning {
            color: #212529;
            background-color: #ffc107;
            border-color: #ffc107
        }

        .btn-warning.focus,
        .btn-warning:focus,
        .btn-warning:hover {
            color: #212529;
            background-color: #e0a800;
            border-color: #d39e00
        }

        .btn-warning.focus,
        .btn-warning:focus {
            box-shadow: 0 0 0 .2rem rgba(222, 170, 12, .5)
        }

        .btn-warning.disabled,
        .btn-warning:disabled {
            color: #212529;
            background-color: #ffc107;
            border-color: #ffc107
        }

        .btn-warning:not(:disabled):not(.disabled).active,
        .btn-warning:not(:disabled):not(.disabled):active,
        .show>.btn-warning.dropdown-toggle {
            color: #212529;
            background-color: #d39e00;
            border-color: #c69500
        }

        .btn-warning:not(:disabled):not(.disabled).active:focus,
        .btn-warning:not(:disabled):not(.disabled):active:focus,
        .show>.btn-warning.dropdown-toggle:focus {
            box-shadow: 0 0 0 .2rem rgba(222, 170, 12, .5)
        }

        .btn-danger {
            color: #fff;
            background-color: #dc3545;
            border-color: #dc3545
        }

        .btn-danger.focus,
        .btn-danger:focus,
        .btn-danger:hover {
            color: #fff;
            background-color: #c82333;
            border-color: #bd2130
        }

        .btn-danger.focus,
        .btn-danger:focus {
            box-shadow: 0 0 0 .2rem rgba(225, 83, 97, .5)
        }

        .btn-danger.disabled,
        .btn-danger:disabled {
            color: #fff;
            background-color: #dc3545;
            border-color: #dc3545
        }

        .btn-danger:not(:disabled):not(.disabled).active,
        .btn-danger:not(:disabled):not(.disabled):active,
        .show>.btn-danger.dropdown-toggle {
            color: #fff;
            background-color: #bd2130;
            border-color: #b21f2d
        }

        .btn-danger:not(:disabled):not(.disabled).active:focus,
        .btn-danger:not(:disabled):not(.disabled):active:focus,
        .show>.btn-danger.dropdown-toggle:focus {
            box-shadow: 0 0 0 .2rem rgba(225, 83, 97, .5)
        }

        .btn-light {
            color: #212529;
            background-color: #f8f9fa;
            border-color: #f8f9fa
        }

        .btn-light.focus,
        .btn-light:focus,
        .btn-light:hover {
            color: #212529;
            background-color: #e2e6ea;
            border-color: #dae0e5
        }

        .btn-light.focus,
        .btn-light:focus {
            box-shadow: 0 0 0 .2rem rgba(216, 217, 219, .5)
        }

        .btn-light.disabled,
        .btn-light:disabled {
            color: #212529;
            background-color: #f8f9fa;
            border-color: #f8f9fa
        }

        .btn-light:not(:disabled):not(.disabled).active,
        .btn-light:not(:disabled):not(.disabled):active,
        .show>.btn-light.dropdown-toggle {
            color: #212529;
            background-color: #dae0e5;
            border-color: #d3d9df
        }

        .btn-light:not(:disabled):not(.disabled).active:focus,
        .btn-light:not(:disabled):not(.disabled):active:focus,
        .show>.btn-light.dropdown-toggle:focus {
            box-shadow: 0 0 0 .2rem rgba(216, 217, 219, .5)
        }

        .btn-dark {
            color: #fff;
            background-color: #343a40;
            border-color: #343a40
        }

        .btn-dark.focus,
        .btn-dark:focus,
        .btn-dark:hover {
            color: #fff;
            background-color: #23272b;
            border-color: #1d2124
        }

        .btn-dark.focus,
        .btn-dark:focus {
            box-shadow: 0 0 0 .2rem rgba(82, 88, 93, .5)
        }

        .btn-dark.disabled,
        .btn-dark:disabled {
            color: #fff;
            background-color: #343a40;
            border-color: #343a40
        }

        .btn-dark:not(:disabled):not(.disabled).active,
        .btn-dark:not(:disabled):not(.disabled):active,
        .show>.btn-dark.dropdown-toggle {
            color: #fff;
            background-color: #1d2124;
            border-color: #171a1d
        }

        .btn-dark:not(:disabled):not(.disabled).active:focus,
        .btn-dark:not(:disabled):not(.disabled):active:focus,
        .show>.btn-dark.dropdown-toggle:focus {
            box-shadow: 0 0 0 .2rem rgba(82, 88, 93, .5)
        }

        .btn-outline-primary {
            color: #007bff;
            border-color: #007bff
        }

        .btn-outline-primary:hover {
            color: #fff;
            background-color: #007bff;
            border-color: #007bff
        }

        .btn-outline-primary.focus,
        .btn-outline-primary:focus {
            box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
        }

        .btn-outline-primary.disabled,
        .btn-outline-primary:disabled {
            color: #007bff;
            background-color: transparent
        }

        .btn-outline-primary:not(:disabled):not(.disabled).active,
        .btn-outline-primary:not(:disabled):not(.disabled):active,
        .show>.btn-outline-primary.dropdown-toggle {
            color: #fff;
            background-color: #007bff;
            border-color: #007bff
        }

        .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
        .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
        .show>.btn-outline-primary.dropdown-toggle:focus {
            box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
        }

        .btn-outline-secondary {
            color: #6c757d;
            border-color: #6c757d
        }

        .btn-outline-secondary:hover {
            color: #fff;
            background-color: #6c757d;
            border-color: #6c757d
        }

        .btn-outline-secondary.focus,
        .btn-outline-secondary:focus {
            box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
        }

        .btn-outline-secondary.disabled,
        .btn-outline-secondary:disabled {
            color: #6c757d;
            background-color: transparent
        }

        .btn-outline-secondary:not(:disabled):not(.disabled).active,
        .btn-outline-secondary:not(:disabled):not(.disabled):active,
        .show>.btn-outline-secondary.dropdown-toggle {
            color: #fff;
            background-color: #6c757d;
            border-color: #6c757d
        }

        .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
        .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
        .show>.btn-outline-secondary.dropdown-toggle:focus {
            box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
        }

        .btn-outline-success {
            color: #28a745;
            border-color: #28a745
        }

        .btn-outline-success:hover {
            color: #fff;
            background-color: #28a745;
            border-color: #28a745
        }

        .btn-outline-success.focus,
        .btn-outline-success:focus {
            box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
        }

        .btn-outline-success.disabled,
        .btn-outline-success:disabled {
            color: #28a745;
            background-color: transparent
        }

        .btn-outline-success:not(:disabled):not(.disabled).active,
        .btn-outline-success:not(:disabled):not(.disabled):active,
        .show>.btn-outline-success.dropdown-toggle {
            color: #fff;
            background-color: #28a745;
            border-color: #28a745
        }

        .btn-outline-success:not(:disabled):not(.disabled).active:focus,
        .btn-outline-success:not(:disabled):not(.disabled):active:focus,
        .show>.btn-outline-success.dropdown-toggle:focus {
            box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
        }

        .btn-outline-info {
            color: #17a2b8;
            border-color: #17a2b8
        }

        .btn-outline-info:hover {
            color: #fff;
            background-color: #17a2b8;
            border-color: #17a2b8
        }

        .btn-outline-info.focus,
        .btn-outline-info:focus {
            box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
        }

        .btn-outline-info.disabled,
        .btn-outline-info:disabled {
            color: #17a2b8;
            background-color: transparent
        }

        .btn-outline-info:not(:disabled):not(.disabled).active,
        .btn-outline-info:not(:disabled):not(.disabled):active,
        .show>.btn-outline-info.dropdown-toggle {
            color: #fff;
            background-color: #17a2b8;
            border-color: #17a2b8
        }

        .btn-outline-info:not(:disabled):not(.disabled).active:focus,
        .btn-outline-info:not(:disabled):not(.disabled):active:focus,
        .show>.btn-outline-info.dropdown-toggle:focus {
            box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
        }

        .btn-outline-warning {
            color: #ffc107;
            border-color: #ffc107
        }

        .btn-outline-warning:hover {
            color: #212529;
            background-color: #ffc107;
            border-color: #ffc107
        }

        .btn-outline-warning.focus,
        .btn-outline-warning:focus {
            box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
        }

        .btn-outline-warning.disabled,
        .btn-outline-warning:disabled {
            color: #ffc107;
            background-color: transparent
        }

        .btn-outline-warning:not(:disabled):not(.disabled).active,
        .btn-outline-warning:not(:disabled):not(.disabled):active,
        .show>.btn-outline-warning.dropdown-toggle {
            color: #212529;
            background-color: #ffc107;
            border-color: #ffc107
        }

        .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
        .btn-outline-warning:not(:disabled):not(.disabled):active:focus,
        .show>.btn-outline-warning.dropdown-toggle:focus {
            box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
        }

        .btn-outline-danger {
            color: #dc3545;
            border-color: #dc3545
        }

        .btn-outline-danger:hover {
            color: #fff;
            background-color: #dc3545;
            border-color: #dc3545
        }

        .btn-outline-danger.focus,
        .btn-outline-danger:focus {
            box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
        }

        .btn-outline-danger.disabled,
        .btn-outline-danger:disabled {
            color: #dc3545;
            background-color: transparent
        }

        .btn-outline-danger:not(:disabled):not(.disabled).active,
        .btn-outline-danger:not(:disabled):not(.disabled):active,
        .show>.btn-outline-danger.dropdown-toggle {
            color: #fff;
            background-color: #dc3545;
            border-color: #dc3545
        }

        .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
        .btn-outline-danger:not(:disabled):not(.disabled):active:focus,
        .show>.btn-outline-danger.dropdown-toggle:focus {
            box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
        }

        .btn-outline-light {
            color: #f8f9fa;
            border-color: #f8f9fa
        }

        .btn-outline-light:hover {
            color: #212529;
            background-color: #f8f9fa;
            border-color: #f8f9fa
        }

        .btn-outline-light.focus,
        .btn-outline-light:focus {
            box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
        }

        .btn-outline-light.disabled,
        .btn-outline-light:disabled {
            color: #f8f9fa;
            background-color: transparent
        }

        .btn-outline-light:not(:disabled):not(.disabled).active,
        .btn-outline-light:not(:disabled):not(.disabled):active,
        .show>.btn-outline-light.dropdown-toggle {
            color: #212529;
            background-color: #f8f9fa;
            border-color: #f8f9fa
        }

        .btn-outline-light:not(:disabled):not(.disabled).active:focus,
        .btn-outline-light:not(:disabled):not(.disabled):active:focus,
        .show>.btn-outline-light.dropdown-toggle:focus {
            box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
        }

        .btn-outline-dark {
            color: #343a40;
            border-color: #343a40
        }

        .btn-outline-dark:hover {
            color: #fff;
            background-color: #343a40;
            border-color: #343a40
        }

        .btn-outline-dark.focus,
        .btn-outline-dark:focus {
            box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
        }

        .btn-outline-dark.disabled,
        .btn-outline-dark:disabled {
            color: #343a40;
            background-color: transparent
        }

        .btn-outline-dark:not(:disabled):not(.disabled).active,
        .btn-outline-dark:not(:disabled):not(.disabled):active,
        .show>.btn-outline-dark.dropdown-toggle {
            color: #fff;
            background-color: #343a40;
            border-color: #343a40
        }

        .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
        .btn-outline-dark:not(:disabled):not(.disabled):active:focus,
        .show>.btn-outline-dark.dropdown-toggle:focus {
            box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
        }

        .btn-link {
            font-weight: 400;
            color: #007bff;
            text-decoration: none
        }

        .btn-link:hover {
            color: #0056b3
        }

        .btn-link.focus,
        .btn-link:focus,
        .btn-link:hover {
            text-decoration: underline
        }

        .btn-link.disabled,
        .btn-link:disabled {
            color: #6c757d;
            pointer-events: none
        }

        .btn-group-lg>.btn,
        .btn-lg {
            padding: .5rem 1rem;
            font-size: 1.25rem;
            line-height: 1.5;
            border-radius: .3rem
        }

        .btn-group-sm>.btn,
        .btn-sm {
            padding: .25rem .5rem;
            font-size: .875rem;
            line-height: 1.5;
            border-radius: .2rem
        }

        .btn-block {
            display: block;
            width: 100%
        }

        .btn-block+.btn-block {
            margin-top: .5rem
        }

        input[type=button].btn-block,
        input[type=reset].btn-block,
        input[type=submit].btn-block {
            width: 100%
        }

        .fade {
            transition: opacity .15s linear
        }

        @media (prefers-reduced-motion:reduce) {
            .fade {
                transition: none
            }
        }

        .fade:not(.show) {
            opacity: 0
        }

        .collapse:not(.show) {
            display: none
        }

        .collapsing {
            position: relative;
            height: 0;
            overflow: hidden;
            transition: height .35s ease
        }

        @media (prefers-reduced-motion:reduce) {
            .collapsing {
                transition: none
            }
        }

        .dropdown,
        .dropleft,
        .dropright,
        .dropup {
            position: relative
        }

        .dropdown-toggle {
            white-space: nowrap
        }

        .dropdown-toggle:after {
            display: inline-block;
            margin-left: .255em;
            vertical-align: .255em;
            content: "";
            border-top: .3em solid;
            border-right: .3em solid transparent;
            border-bottom: 0;
            border-left: .3em solid transparent
        }

        .dropdown-toggle:empty:after {
            margin-left: 0
        }

        .dropdown-menu {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 1000;
            display: none;
            float: left;
            min-width: 10rem;
            padding: .5rem 0;
            margin: .125rem 0 0;
            font-size: 1rem;
            color: #212529;
            text-align: left;
            list-style: none;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid rgba(0, 0, 0, .15);
            border-radius: .25rem
        }

        .dropdown-menu-left {
            right: auto;
            left: 0
        }

        .dropdown-menu-right {
            right: 0;
            left: auto
        }
        .block{
                width: 70px;
        }
        @media (min-width:576px) {
            .block{
                width: 50px;
            }
            .dropdown-menu-sm-left {
                right: auto;
                left: 0
            }

            .dropdown-menu-sm-right {
                right: 0;
                left: auto
            }
        }

        cmedia (min-width:768px) {
            .block{
                width: 80px;
            }
            .dropdown-menu-md-left {
                right: auto;
                left: 0
            }

            .dropdown-menu-md-right {
                right: 0;
                left: auto
            }
        }

        @media (min-width:992px) {
            .block{
                width: 80px;
            }
            .dropdown-menu-lg-left {
                right: auto;
                left: 0
            }

            .dropdown-menu-lg-right {
                right: 0;
                left: auto
            }
        }

        @media (min-width:1200px) {
            .block{
                width: 100px;
            }
            .dropdown-menu-xl-left {
                right: auto;
                left: 0
            }

            .dropdown-menu-xl-right {
                right: 0;
                left: auto
            }
        }

        .dropup .dropdown-menu {
            top: auto;
            bottom: 100%;
            margin-top: 0;
            margin-bottom: .125rem
        }

        .dropup .dropdown-toggle:after {
            display: inline-block;
            margin-left: .255em;
            vertical-align: .255em;
            content: "";
            border-top: 0;
            border-right: .3em solid transparent;
            border-bottom: .3em solid;
            border-left: .3em solid transparent
        }

        .dropup .dropdown-toggle:empty:after {
            margin-left: 0
        }

        .dropright .dropdown-menu {
            top: 0;
            right: auto;
            left: 100%;
            margin-top: 0;
            margin-left: .125rem
        }

        .dropright .dropdown-toggle:after {
            display: inline-block;
            margin-left: .255em;
            vertical-align: .255em;
            content: "";
            border-top: .3em solid transparent;
            border-right: 0;
            border-bottom: .3em solid transparent;
            border-left: .3em solid
        }

        .dropright .dropdown-toggle:empty:after {
            margin-left: 0
        }

        .dropright .dropdown-toggle:after {
            vertical-align: 0
        }

        .dropleft .dropdown-menu {
            top: 0;
            right: 100%;
            left: auto;
            margin-top: 0;
            margin-right: .125rem
        }

        .dropleft .dropdown-toggle:after {
            display: inline-block;
            margin-left: .255em;
            vertical-align: .255em;
            content: "";
            display: none
        }

        .dropleft .dropdown-toggle:before {
            display: inline-block;
            margin-right: .255em;
            vertical-align: .255em;
            content: "";
            border-top: .3em solid transparent;
            border-right: .3em solid;
            border-bottom: .3em solid transparent
        }

        .dropleft .dropdown-toggle:empty:after {
            margin-left: 0
        }

        .dropleft .dropdown-toggle:before {
            vertical-align: 0
        }

        .dropdown-menu[x-placement^=bottom],
        .dropdown-menu[x-placement^=left],
        .dropdown-menu[x-placement^=right],
        .dropdown-menu[x-placement^=top] {
            right: auto;
            bottom: auto
        }

        .dropdown-divider {
            height: 0;
            margin: .5rem 0;
            overflow: hidden;
            border-top: 1px solid #e9ecef
        }

        .dropdown-item {
            display: block;
            width: 100%;
            padding: .25rem 1.5rem;
            clear: both;
            font-weight: 400;
            color: #212529;
            text-align: inherit;
            white-space: nowrap;
            background-color: transparent;
            border: 0
        }

        .dropdown-item:focus,
        .dropdown-item:hover {
            color: #16181b;
            text-decoration: none;
            background-color: #e9ecef
        }

        .dropdown-item.active,
        .dropdown-item:active {
            color: #fff;
            text-decoration: none;
            background-color: #007bff
        }

        .dropdown-item.disabled,
        .dropdown-item:disabled {
            color: #adb5bd;
            pointer-events: none;
            background-color: transparent
        }

        .dropdown-menu.show {
            display: block
        }

        .dropdown-header {
            display: block;
            padding: .5rem 1.5rem;
            margin-bottom: 0;
            font-size: .875rem;
            color: #6c757d;
            white-space: nowrap
        }

        .dropdown-item-text {
            display: block;
            padding: .25rem 1.5rem;
            color: #212529
        }

        .btn-group,
        .btn-group-vertical {
            position: relative;
            display: inline-flex;
            vertical-align: middle
        }

        .btn-group-vertical>.btn,
        .btn-group>.btn {
            position: relative;
            flex: 1 1 auto
        }

        .btn-group-vertical>.btn.active,
        .btn-group-vertical>.btn:active,
        .btn-group-vertical>.btn:focus,
        .btn-group-vertical>.btn:hover,
        .btn-group>.btn.active,
        .btn-group>.btn:active,
        .btn-group>.btn:focus,
        .btn-group>.btn:hover {
            z-index: 1
        }

        .btn-toolbar {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start
        }

        .btn-toolbar .input-group {
            width: auto
        }

        .btn-group>.btn-group:not(:first-child),
        .btn-group>.btn:not(:first-child) {
            margin-left: -1px
        }

        .btn-group>.btn-group:not(:last-child)>.btn,
        .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0
        }

        .btn-group>.btn-group:not(:first-child)>.btn,
        .btn-group>.btn:not(:first-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0
        }

        .dropdown-toggle-split {
            padding-right: .5625rem;
            padding-left: .5625rem
        }

        .dropdown-toggle-split:after,
        .dropright .dropdown-toggle-split:after,
        .dropup .dropdown-toggle-split:after {
            margin-left: 0
        }

        .dropleft .dropdown-toggle-split:before {
            margin-right: 0
        }

        .btn-group-sm>.btn+.dropdown-toggle-split,
        .btn-sm+.dropdown-toggle-split {
            padding-right: .375rem;
            padding-left: .375rem
        }

        .btn-group-lg>.btn+.dropdown-toggle-split,
        .btn-lg+.dropdown-toggle-split {
            padding-right: .75rem;
            padding-left: .75rem
        }

        .btn-group-vertical {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center
        }

        .btn-group-vertical>.btn,
        .btn-group-vertical>.btn-group {
            width: 100%
        }

        .btn-group-vertical>.btn-group:not(:first-child),
        .btn-group-vertical>.btn:not(:first-child) {
            margin-top: -1px
        }

        .btn-group-vertical>.btn-group:not(:last-child)>.btn,
        .btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0
        }

        .btn-group-vertical>.btn-group:not(:first-child)>.btn,
        .btn-group-vertical>.btn:not(:first-child) {
            border-top-left-radius: 0;
            border-top-right-radius: 0
        }

        .btn-group-toggle>.btn,
        .btn-group-toggle>.btn-group>.btn {
            margin-bottom: 0
        }

        .btn-group-toggle>.btn input[type=checkbox],
        .btn-group-toggle>.btn input[type=radio],
        .btn-group-toggle>.btn-group>.btn input[type=checkbox],
        .btn-group-toggle>.btn-group>.btn input[type=radio] {
            position: absolute;
            clip: rect(0, 0, 0, 0);
            pointer-events: none
        }

        .input-group {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            width: 100%
        }

        .input-group>.custom-file,
        .input-group>.custom-select,
        .input-group>.form-control,
        .input-group>.form-control-plaintext {
            position: relative;
            flex: 1 1 auto;
            width: 1%;
            min-width: 0;
            margin-bottom: 0
        }

        .input-group>.custom-file+.custom-file,
        .input-group>.custom-file+.custom-select,
        .input-group>.custom-file+.form-control,
        .input-group>.custom-select+.custom-file,
        .input-group>.custom-select+.custom-select,
        .input-group>.custom-select+.form-control,
        .input-group>.form-control+.custom-file,
        .input-group>.form-control+.custom-select,
        .input-group>.form-control+.form-control,
        .input-group>.form-control-plaintext+.custom-file,
        .input-group>.form-control-plaintext+.custom-select,
        .input-group>.form-control-plaintext+.form-control {
            margin-left: -1px
        }

        .input-group>.custom-file .custom-file-input:focus~.custom-file-label,
        .input-group>.custom-select:focus,
        .input-group>.form-control:focus {
            z-index: 3
        }

        .input-group>.custom-file .custom-file-input:focus {
            z-index: 4
        }

        .input-group>.custom-select:not(:first-child),
        .input-group>.form-control:not(:first-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0
        }

        .input-group>.custom-file {
            display: flex;
            align-items: center
        }

        .input-group>.custom-file:not(:first-child) .custom-file-label,
        .input-group>.custom-file:not(:last-child) .custom-file-label {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0
        }

        .input-group.has-validation>.custom-file:nth-last-child(n+3) .custom-file-label:after,
        .input-group.has-validation>.custom-select:nth-last-child(n+3),
        .input-group.has-validation>.form-control:nth-last-child(n+3),
        .input-group:not(.has-validation)>.custom-file:not(:last-child) .custom-file-label:after,
        .input-group:not(.has-validation)>.custom-select:not(:last-child),
        .input-group:not(.has-validation)>.form-control:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0
        }

        .input-group-append,
        .input-group-prepend {
            display: flex
        }

        .input-group-append .btn,
        .input-group-prepend .btn {
            position: relative;
            z-index: 2
        }

        .input-group-append .btn:focus,
        .input-group-prepend .btn:focus {
            z-index: 3
        }

        .input-group-append .btn+.btn,
        .input-group-append .btn+.input-group-text,
        .input-group-append .input-group-text+.btn,
        .input-group-append .input-group-text+.input-group-text,
        .input-group-prepend .btn+.btn,
        .input-group-prepend .btn+.input-group-text,
        .input-group-prepend .input-group-text+.btn,
        .input-group-prepend .input-group-text+.input-group-text {
            margin-left: -1px
        }

        .input-group-prepend {
            margin-right: -1px
        }

        .input-group-append {
            margin-left: -1px
        }

        .input-group-text {
            display: flex;
            align-items: center;
            padding: .375rem .75rem;
            margin-bottom: 0;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            text-align: center;
            white-space: nowrap;
            background-color: #e9ecef;
            border: 1px solid #ced4da;
            border-radius: .25rem
        }

        .input-group-text input[type=checkbox],
        .input-group-text input[type=radio] {
            margin-top: 0
        }

        .input-group-lg>.custom-select,
        .input-group-lg>.form-control:not(textarea) {
            height: calc(1.5em + 1rem + 2px)
        }

        .input-group-lg>.custom-select,
        .input-group-lg>.form-control,
        .input-group-lg>.input-group-append>.btn,
        .input-group-lg>.input-group-append>.input-group-text,
        .input-group-lg>.input-group-prepend>.btn,
        .input-group-lg>.input-group-prepend>.input-group-text {
            padding: .5rem 1rem;
            font-size: 1.25rem;
            line-height: 1.5;
            border-radius: .3rem
        }

        .input-group-sm>.custom-select,
        .input-group-sm>.form-control:not(textarea) {
            height: calc(1.5em + .5rem + 2px)
        }

        .input-group-sm>.custom-select,
        .input-group-sm>.form-control,
        .input-group-sm>.input-group-append>.btn,
        .input-group-sm>.input-group-append>.input-group-text,
        .input-group-sm>.input-group-prepend>.btn,
        .input-group-sm>.input-group-prepend>.input-group-text {
            padding: .25rem .5rem;
            font-size: .875rem;
            line-height: 1.5;
            border-radius: .2rem
        }

        .input-group-lg>.custom-select,
        .input-group-sm>.custom-select {
            padding-right: 1.75rem
        }

        .input-group.has-validation>.input-group-append:nth-last-child(n+3)>.btn,
        .input-group.has-validation>.input-group-append:nth-last-child(n+3)>.input-group-text,
        .input-group:not(.has-validation)>.input-group-append:not(:last-child)>.btn,
        .input-group:not(.has-validation)>.input-group-append:not(:last-child)>.input-group-text,
        .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
        .input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
        .input-group>.input-group-prepend>.btn,
        .input-group>.input-group-prepend>.input-group-text {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0
        }

        .input-group>.input-group-append>.btn,
        .input-group>.input-group-append>.input-group-text,
        .input-group>.input-group-prepend:first-child>.btn:not(:first-child),
        .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
        .input-group>.input-group-prepend:not(:first-child)>.btn,
        .input-group>.input-group-prepend:not(:first-child)>.input-group-text {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0
        }

        .custom-control {
            position: relative;
            z-index: 1;
            display: block;
            min-height: 1.5rem;
            padding-left: 1.5rem;
            -webkit-print-color-adjust: exact;
            color-adjust: exact
        }

        .custom-control-inline {
            display: inline-flex;
            margin-right: 1rem
        }

        .custom-control-input {
            position: absolute;
            left: 0;
            z-index: -1;
            width: 1rem;
            height: 1.25rem;
            opacity: 0
        }

        .custom-control-input:checked~.custom-control-label:before {
            color: #fff;
            border-color: #007bff;
            background-color: #007bff
        }

        .custom-control-input:focus~.custom-control-label:before {
            box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
        }

        .custom-control-input:focus:not(:checked)~.custom-control-label:before {
            border-color: #80bdff
        }

        .custom-control-input:not(:disabled):active~.custom-control-label:before {
            color: #fff;
            background-color: #b3d7ff;
            border-color: #b3d7ff
        }

        .custom-control-input:disabled~.custom-control-label,
        .custom-control-input[disabled]~.custom-control-label {
            color: #6c757d
        }

        .custom-control-input:disabled~.custom-control-label:before,
        .custom-control-input[disabled]~.custom-control-label:before {
            background-color: #e9ecef
        }

        .custom-control-label {
            position: relative;
            margin-bottom: 0;
            vertical-align: top
        }

        .custom-control-label:before {
            pointer-events: none;
            background-color: #fff;
            border: 1px solid #adb5bd
        }

        .custom-control-label:after,
        .custom-control-label:before {
            position: absolute;
            top: .25rem;
            left: -1.5rem;
            display: block;
            width: 1rem;
            height: 1rem;
            content: ""
        }

        .custom-control-label:after {
            background: 50%/50% 50% no-repeat
        }

        .custom-checkbox .custom-control-label:before {
            border-radius: .25rem
        }

        .custom-checkbox .custom-control-input:checked~.custom-control-label:after {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E")
        }

        .custom-checkbox .custom-control-input:indeterminate~.custom-control-label:before {
            border-color: #007bff;
            background-color: #007bff
        }

        .custom-checkbox .custom-control-input:indeterminate~.custom-control-label:after {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E")
        }

        .custom-checkbox .custom-control-input:disabled:checked~.custom-control-label:before {
            background-color: rgba(0, 123, 255, .5)
        }

        .custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label:before {
            background-color: rgba(0, 123, 255, .5)
        }

        .custom-radio .custom-control-label:before {
            border-radius: 50%
        }

        .custom-radio .custom-control-input:checked~.custom-control-label:after {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E")
        }

        .custom-radio .custom-control-input:disabled:checked~.custom-control-label:before {
            background-color: rgba(0, 123, 255, .5)
        }

        .custom-switch {
            padding-left: 2.25rem
        }

        .custom-switch .custom-control-label:before {
            left: -2.25rem;
            width: 1.75rem;
            pointer-events: all;
            border-radius: .5rem
        }

        .custom-switch .custom-control-label:after {
            top: calc(.25rem + 2px);
            left: calc(-2.25rem + 2px);
            width: calc(1rem - 4px);
            height: calc(1rem - 4px);
            background-color: #adb5bd;
            border-radius: .5rem;
            transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
        }

        @media (prefers-reduced-motion:reduce) {
            .custom-switch .custom-control-label:after {
                transition: none
            }
        }

        .custom-switch .custom-control-input:checked~.custom-control-label:after {
            background-color: #fff;
            transform: translateX(.75rem)
        }

        .custom-switch .custom-control-input:disabled:checked~.custom-control-label:before {
            background-color: rgba(0, 123, 255, .5)
        }

        .custom-select {
            display: inline-block;
            width: 100%;
            height: calc(1.5em + .75rem + 2px);
            padding: .375rem 1.75rem .375rem .75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            vertical-align: middle;
            background: #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right .75rem center/8px 10px no-repeat;
            border: 1px solid #ced4da;
            border-radius: .25rem;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none
        }

        .custom-select:focus {
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
        }

        .custom-select:focus::-ms-value {
            color: #495057;
            background-color: #fff
        }

        .custom-select[multiple],
        .custom-select[size]:not([size="1"]) {
            height: auto;
            padding-right: .75rem;
            background-image: none
        }

        .custom-select:disabled {
            color: #6c757d;
            background-color: #e9ecef
        }

        .custom-select::-ms-expand {
            display: none
        }

        .custom-select:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 #495057
        }

        .custom-select-sm {
            height: calc(1.5em + .5rem + 2px);
            padding-top: .25rem;
            padding-bottom: .25rem;
            padding-left: .5rem;
            font-size: .875rem
        }

        .custom-select-lg {
            height: calc(1.5em + 1rem + 2px);
            padding-top: .5rem;
            padding-bottom: .5rem;
            padding-left: 1rem;
            font-size: 1.25rem
        }

        .custom-file {
            display: inline-block;
            margin-bottom: 0
        }

        .custom-file,
        .custom-file-input {
            position: relative;
            width: 100%;
            height: calc(1.5em + .75rem + 2px)
        }

        .custom-file-input {
            z-index: 2;
            margin: 0;
            overflow: hidden;
            opacity: 0
        }

        .custom-file-input:focus~.custom-file-label {
            border-color: #80bdff;
            box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
        }

        .custom-file-input:disabled~.custom-file-label,
        .custom-file-input[disabled]~.custom-file-label {
            background-color: #e9ecef
        }

        .custom-file-input:lang(en)~.custom-file-label:after {
            content: "Browse"
        }

        .custom-file-input~.custom-file-label[data-browse]:after {
            content: attr(data-browse)
        }

        .custom-file-label {
            left: 0;
            z-index: 1;
            height: calc(1.5em + .75rem + 2px);
            overflow: hidden;
            font-weight: 400;
            background-color: #fff;
            border: 1px solid #ced4da;
            border-radius: .25rem
        }

        .custom-file-label,
        .custom-file-label:after {
            position: absolute;
            top: 0;
            right: 0;
            padding: .375rem .75rem;
            line-height: 1.5;
            color: #495057
        }

        .custom-file-label:after {
            bottom: 0;
            z-index: 3;
            display: block;
            height: calc(1.5em + .75rem);
            content: "Browse";
            background-color: #e9ecef;
            border-left: inherit;
            border-radius: 0 .25rem .25rem 0
        }

        .custom-range {
            width: 100%;
            height: 1.4rem;
            padding: 0;
            background-color: transparent;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none
        }

        .custom-range:focus {
            outline: 0
        }

        .custom-range:focus::-webkit-slider-thumb {
            box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(0, 123, 255, .25)
        }

        .custom-range:focus::-moz-range-thumb {
            box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(0, 123, 255, .25)
        }

        .custom-range:focus::-ms-thumb {
            box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(0, 123, 255, .25)
        }

        .custom-range::-moz-focus-outer {
            border: 0
        }

        .custom-range::-webkit-slider-thumb {
            width: 1rem;
            height: 1rem;
            margin-top: -.25rem;
            background-color: #007bff;
            border: 0;
            border-radius: 1rem;
            -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            -webkit-appearance: none;
            appearance: none
        }

        @media (prefers-reduced-motion:reduce) {
            .custom-range::-webkit-slider-thumb {
                -webkit-transition: none;
                transition: none
            }
        }

        .custom-range::-webkit-slider-thumb:active {
            background-color: #b3d7ff
        }

        .custom-range::-webkit-slider-runnable-track {
            width: 100%;
            height: .5rem;
            color: transparent;
            cursor: pointer;
            background-color: #dee2e6;
            border-color: transparent;
            border-radius: 1rem
        }

        .custom-range::-moz-range-thumb {
            width: 1rem;
            height: 1rem;
            background-color: #007bff;
            border: 0;
            border-radius: 1rem;
            -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            -moz-appearance: none;
            appearance: none
        }

        @media (prefers-reduced-motion:reduce) {
            .custom-range::-moz-range-thumb {
                -moz-transition: none;
                transition: none
            }
        }

        .custom-range::-moz-range-thumb:active {
            background-color: #b3d7ff
        }

        .custom-range::-moz-range-track {
            width: 100%;
            height: .5rem;
            color: transparent;
            cursor: pointer;
            background-color: #dee2e6;
            border-color: transparent;
            border-radius: 1rem
        }

        .custom-range::-ms-thumb {
            width: 1rem;
            height: 1rem;
            margin-top: 0;
            margin-right: .2rem;
            margin-left: .2rem;
            background-color: #007bff;
            border: 0;
            border-radius: 1rem;
            -ms-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            appearance: none
        }

        @media (prefers-reduced-motion:reduce) {
            .custom-range::-ms-thumb {
                -ms-transition: none;
                transition: none
            }
        }

        .custom-range::-ms-thumb:active {
            background-color: #b3d7ff
        }

        .custom-range::-ms-track {
            width: 100%;
            height: .5rem;
            color: transparent;
            cursor: pointer;
            background-color: transparent;
            border-color: transparent;
            border-width: .5rem
        }

        .custom-range::-ms-fill-lower,
        .custom-range::-ms-fill-upper {
            background-color: #dee2e6;
            border-radius: 1rem
        }

        .custom-range::-ms-fill-upper {
            margin-right: 15px
        }

        .custom-range:disabled::-webkit-slider-thumb {
            background-color: #adb5bd
        }

        .custom-range:disabled::-webkit-slider-runnable-track {
            cursor: default
        }

        .custom-range:disabled::-moz-range-thumb {
            background-color: #adb5bd
        }

        .custom-range:disabled::-moz-range-track {
            cursor: default
        }

        .custom-range:disabled::-ms-thumb {
            background-color: #adb5bd
        }

        .custom-control-label:before,
        .custom-file-label,
        .custom-select {
            transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
        }

        @media (prefers-reduced-motion:reduce) {

            .custom-control-label:before,
            .custom-file-label,
            .custom-select {
                transition: none
            }
        }

        .nav {
            display: flex;
            flex-wrap: wrap;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none
        }

        .nav-link {
            display: block;
            padding: .5rem 1rem
        }

        .nav-link:focus,
        .nav-link:hover {
            text-decoration: none
        }

        .nav-link.disabled {
            color: #6c757d;
            pointer-events: none;
            cursor: default
        }

        .nav-tabs {
            border-bottom: 1px solid #dee2e6
        }

        .nav-tabs .nav-link {
            margin-bottom: -1px;
            border: 1px solid transparent;
            border-top-left-radius: .25rem;
            border-top-right-radius: .25rem
        }

        .nav-tabs .nav-link:focus,
        .nav-tabs .nav-link:hover {
            border-color: #e9ecef #e9ecef #dee2e6
        }

        .nav-tabs .nav-link.disabled {
            color: #6c757d;
            background-color: transparent;
            border-color: transparent
        }

        .nav-tabs .nav-item.show .nav-link,
        .nav-tabs .nav-link.active {
            color: #495057;
            background-color: #fff;
            border-color: #dee2e6 #dee2e6 #fff
        }

        .nav-tabs .dropdown-menu {
            margin-top: -1px;
            border-top-left-radius: 0;
            border-top-right-radius: 0
        }

        .nav-pills .nav-link {
            border-radius: .25rem
        }

        .nav-pills .nav-link.active,
        .nav-pills .show>.nav-link {
            color: #fff;
            background-color: #007bff
        }

        .nav-fill .nav-item,
        .nav-fill>.nav-link {
            flex: 1 1 auto;
            text-align: center
        }

        .nav-justified .nav-item,
        .nav-justified>.nav-link {
            flex-basis: 0;
            flex-grow: 1;
            text-align: center
        }

        .tab-content>.tab-pane {
            display: none
        }

        .tab-content>.active {
            display: block
        }

        .navbar {
            position: relative;
            padding: .5rem 1rem
        }

        .navbar,
        .navbar .container,
        .navbar .container-fluid,
        .navbar .container-lg,
        .navbar .container-md,
        .navbar .container-sm,
        .navbar .container-xl {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between
        }

        .navbar-brand {
            display: inline-block;
            padding-top: .3125rem;
            padding-bottom: .3125rem;
            margin-right: 1rem;
            font-size: 1.25rem;
            line-height: inherit;
            white-space: nowrap
        }

        .navbar-brand:focus,
        .navbar-brand:hover {
            text-decoration: none
        }

        .navbar-nav {
            display: flex;
            flex-direction: column;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none
        }

        .navbar-nav .nav-link {
            padding-right: 0;
            padding-left: 0
        }

        .navbar-nav .dropdown-menu {
            position: static;
            float: none
        }

        .navbar-text {
            display: inline-block;
            padding-top: .5rem;
            padding-bottom: .5rem
        }

        .navbar-collapse {
            flex-basis: 100%;
            flex-grow: 1;
            align-items: center
        }

        .navbar-toggler {
            padding: .25rem .75rem;
            font-size: 1.25rem;
            line-height: 1;
            background-color: transparent;
            border: 1px solid transparent;
            border-radius: .25rem
        }

        .navbar-toggler:focus,
        .navbar-toggler:hover {
            text-decoration: none
        }

        .navbar-toggler-icon {
            display: inline-block;
            width: 1.5em;
            height: 1.5em;
            vertical-align: middle;
            content: "";
            background: 50%/100% 100% no-repeat
        }

        .navbar-nav-scroll {
            max-height: 75vh;
            overflow-y: auto
        }

        @media (max-width:575.98px) {

            .navbar-expand-sm>.container,
            .navbar-expand-sm>.container-fluid,
            .navbar-expand-sm>.container-lg,
            .navbar-expand-sm>.container-md,
            .navbar-expand-sm>.container-sm,
            .navbar-expand-sm>.container-xl {
                padding-right: 0;
                padding-left: 0
            }
        }

        @media (min-width:576px) {
            .navbar-expand-sm {
                flex-flow: row nowrap;
                justify-content: flex-start
            }

            .navbar-expand-sm .navbar-nav {
                flex-direction: row
            }

            .navbar-expand-sm .navbar-nav .dropdown-menu {
                position: absolute
            }

            .navbar-expand-sm .navbar-nav .nav-link {
                padding-right: .5rem;
                padding-left: .5rem
            }

            .navbar-expand-sm>.container,
            .navbar-expand-sm>.container-fluid,
            .navbar-expand-sm>.container-lg,
            .navbar-expand-sm>.container-md,
            .navbar-expand-sm>.container-sm,
            .navbar-expand-sm>.container-xl {
                flex-wrap: nowrap
            }

            .navbar-expand-sm .navbar-nav-scroll {
                overflow: visible
            }

            .navbar-expand-sm .navbar-collapse {
                display: flex !important;
                flex-basis: auto
            }

            .navbar-expand-sm .navbar-toggler {
                display: none
            }
        }

        @media (max-width:767.98px) {

            .navbar-expand-md>.container,
            .navbar-expand-md>.container-fluid,
            .navbar-expand-md>.container-lg,
            .navbar-expand-md>.container-md,
            .navbar-expand-md>.container-sm,
            .navbar-expand-md>.container-xl {
                padding-right: 0;
                padding-left: 0
            }
        }

        @media (min-width:768px) {
            .navbar-expand-md {
                flex-flow: row nowrap;
                justify-content: flex-start
            }

            .navbar-expand-md .navbar-nav {
                flex-direction: row
            }

            .navbar-expand-md .navbar-nav .dropdown-menu {
                position: absolute
            }

            .navbar-expand-md .navbar-nav .nav-link {
                padding-right: .5rem;
                padding-left: .5rem
            }

            .navbar-expand-md>.container,
            .navbar-expand-md>.container-fluid,
            .navbar-expand-md>.container-lg,
            .navbar-expand-md>.container-md,
            .navbar-expand-md>.container-sm,
            .navbar-expand-md>.container-xl {
                flex-wrap: nowrap
            }

            .navbar-expand-md .navbar-nav-scroll {
                overflow: visible
            }

            .navbar-expand-md .navbar-collapse {
                display: flex !important;
                flex-basis: auto
            }

            .navbar-expand-md .navbar-toggler {
                display: none
            }
        }

        @media (max-width:991.98px) {

            .navbar-expand-lg>.container,
            .navbar-expand-lg>.container-fluid,
            .navbar-expand-lg>.container-lg,
            .navbar-expand-lg>.container-md,
            .navbar-expand-lg>.container-sm,
            .navbar-expand-lg>.container-xl {
                padding-right: 0;
                padding-left: 0
            }
        }

        @media (min-width:992px) {
            .navbar-expand-lg {
                flex-flow: row nowrap;
                justify-content: flex-start
            }

            .navbar-expand-lg .navbar-nav {
                flex-direction: row
            }

            .navbar-expand-lg .navbar-nav .dropdown-menu {
                position: absolute
            }

            .navbar-expand-lg .navbar-nav .nav-link {
                padding-right: .5rem;
                padding-left: .5rem
            }

            .navbar-expand-lg>.container,
            .navbar-expand-lg>.container-fluid,
            .navbar-expand-lg>.container-lg,
            .navbar-expand-lg>.container-md,
            .navbar-expand-lg>.container-sm,
            .navbar-expand-lg>.container-xl {
                flex-wrap: nowrap
            }

            .navbar-expand-lg .navbar-nav-scroll {
                overflow: visible
            }

            .navbar-expand-lg .navbar-collapse {
                display: flex !important;
                flex-basis: auto
            }

            .navbar-expand-lg .navbar-toggler {
                display: none
            }
        }

        @media (max-width:1199.98px) {

            .navbar-expand-xl>.container,
            .navbar-expand-xl>.container-fluid,
            .navbar-expand-xl>.container-lg,
            .navbar-expand-xl>.container-md,
            .navbar-expand-xl>.container-sm,
            .navbar-expand-xl>.container-xl {
                padding-right: 0;
                padding-left: 0
            }
        }

        @media (min-width:1200px) {
            .navbar-expand-xl {
                flex-flow: row nowrap;
                justify-content: flex-start
            }

            .navbar-expand-xl .navbar-nav {
                flex-direction: row
            }

            .navbar-expand-xl .navbar-nav .dropdown-menu {
                position: absolute
            }

            .navbar-expand-xl .navbar-nav .nav-link {
                padding-right: .5rem;
                padding-left: .5rem
            }

            .navbar-expand-xl>.container,
            .navbar-expand-xl>.container-fluid,
            .navbar-expand-xl>.container-lg,
            .navbar-expand-xl>.container-md,
            .navbar-expand-xl>.container-sm,
            .navbar-expand-xl>.container-xl {
                flex-wrap: nowrap
            }

            .navbar-expand-xl .navbar-nav-scroll {
                overflow: visible
            }

            .navbar-expand-xl .navbar-collapse {
                display: flex !important;
                flex-basis: auto
            }

            .navbar-expand-xl .navbar-toggler {
                display: none
            }
        }

        .navbar-expand {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand>.container,
        .navbar-expand>.container-fluid,
        .navbar-expand>.container-lg,
        .navbar-expand>.container-md,
        .navbar-expand>.container-sm,
        .navbar-expand>.container-xl {
            padding-right: 0;
            padding-left: 0
        }

        .navbar-expand .navbar-nav {
            flex-direction: row
        }

        .navbar-expand .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand>.container,
        .navbar-expand>.container-fluid,
        .navbar-expand>.container-lg,
        .navbar-expand>.container-md,
        .navbar-expand>.container-sm,
        .navbar-expand>.container-xl {
            flex-wrap: nowrap
        }

        .navbar-expand .navbar-nav-scroll {
            overflow: visible
        }

        .navbar-expand .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand .navbar-toggler {
            display: none
        }

        .navbar-light .navbar-brand,
        .navbar-light .navbar-brand:focus,
        .navbar-light .navbar-brand:hover {
            color: rgba(0, 0, 0, .9)
        }

        .navbar-light .navbar-nav .nav-link {
            color: rgba(0, 0, 0, .5)
        }

        .navbar-light .navbar-nav .nav-link:focus,
        .navbar-light .navbar-nav .nav-link:hover {
            color: rgba(0, 0, 0, .7)
        }

        .navbar-light .navbar-nav .nav-link.disabled {
            color: rgba(0, 0, 0, .3)
        }

        .navbar-light .navbar-nav .active>.nav-link,
        .navbar-light .navbar-nav .nav-link.active,
        .navbar-light .navbar-nav .nav-link.show,
        .navbar-light .navbar-nav .show>.nav-link {
            color: rgba(0, 0, 0, .9)
        }

        .navbar-light .navbar-toggler {
            color: rgba(0, 0, 0, .5);
            border-color: rgba(0, 0, 0, .1)
        }

        .navbar-light .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
        }

        .navbar-light .navbar-text {
            color: rgba(0, 0, 0, .5)
        }

        .navbar-light .navbar-text a,
        .navbar-light .navbar-text a:focus,
        .navbar-light .navbar-text a:hover {
            color: rgba(0, 0, 0, .9)
        }

        .navbar-dark .navbar-brand,
        .navbar-dark .navbar-brand:focus,
        .navbar-dark .navbar-brand:hover {
            color: #fff
        }

        .navbar-dark .navbar-nav .nav-link {
            color: hsla(0, 0%, 100%, .5)
        }

        .navbar-dark .navbar-nav .nav-link:focus,
        .navbar-dark .navbar-nav .nav-link:hover {
            color: hsla(0, 0%, 100%, .75)
        }

        .navbar-dark .navbar-nav .nav-link.disabled {
            color: hsla(0, 0%, 100%, .25)
        }

        .navbar-dark .navbar-nav .active>.nav-link,
        .navbar-dark .navbar-nav .nav-link.active,
        .navbar-dark .navbar-nav .nav-link.show,
        .navbar-dark .navbar-nav .show>.nav-link {
            color: #fff
        }

        .navbar-dark .navbar-toggler {
            color: hsla(0, 0%, 100%, .5);
            border-color: hsla(0, 0%, 100%, .1)
        }

        .navbar-dark .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
        }

        .navbar-dark .navbar-text {
            color: hsla(0, 0%, 100%, .5)
        }

        .navbar-dark .navbar-text a,
        .navbar-dark .navbar-text a:focus,
        .navbar-dark .navbar-text a:hover {
            color: #fff
        }

        .card {
            position: relative;
            display: flex;
            flex-direction: column;
            min-width: 0;
            word-wrap: break-word;
            background-color: #fff;
            background-clip: border-box;
            border: 1px solid rgba(0, 0, 0, .125);
            border-radius: .25rem
        }

        .card>hr {
            margin-right: 0;
            margin-left: 0
        }

        .card>.list-group {
            border-top: inherit;
            border-bottom: inherit
        }

        .card>.list-group:first-child {
            border-top-width: 0;
            border-top-left-radius: calc(.25rem - 1px);
            border-top-right-radius: calc(.25rem - 1px)
        }

        .card>.list-group:last-child {
            border-bottom-width: 0;
            border-bottom-right-radius: calc(.25rem - 1px);
            border-bottom-left-radius: calc(.25rem - 1px)
        }

        .card>.card-header+.list-group,
        .card>.list-group+.card-footer {
            border-top: 0
        }

        .card-body {
            flex: 1 1 auto;
            min-height: 1px;
            padding: 1.25rem
        }

        .card-title {
            margin-bottom: .75rem
        }

        .card-subtitle {
            margin-top: -.375rem
        }

        .card-subtitle,
        .card-text:last-child {
            margin-bottom: 0
        }

        .card-link:hover {
            text-decoration: none
        }

        .card-link+.card-link {
            margin-left: 1.25rem
        }

        .card-header {
            padding: .75rem 1.25rem;
            margin-bottom: 0;
            background-color: rgba(0, 0, 0, .03);
            border-bottom: 1px solid rgba(0, 0, 0, .125)
        }

        .card-header:first-child {
            border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0
        }

        .card-footer {
            padding: .75rem 1.25rem;
            background-color: rgba(0, 0, 0, .03);
            border-top: 1px solid rgba(0, 0, 0, .125)
        }

        .card-footer:last-child {
            border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px)
        }

        .card-header-tabs {
            margin-bottom: -.75rem;
            border-bottom: 0
        }

        .card-header-pills,
        .card-header-tabs {
            margin-right: -.625rem;
            margin-left: -.625rem
        }

        .card-img-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 1.25rem;
            border-radius: calc(.25rem - 1px)
        }

        .card-img,
        .card-img-bottom,
        .card-img-top {
            flex-shrink: 0;
            width: 100%
        }

        .card-img,
        .card-img-top {
            border-top-left-radius: calc(.25rem - 1px);
            border-top-right-radius: calc(.25rem - 1px)
        }

        .card-img,
        .card-img-bottom {
            border-bottom-right-radius: calc(.25rem - 1px);
            border-bottom-left-radius: calc(.25rem - 1px)
        }

        .card-deck .card {
            margin-bottom: 15px
        }

        @media (min-width:576px) {
            .card-deck {
                display: flex;
                flex-flow: row wrap;
                margin-right: -15px;
                margin-left: -15px
            }

            .card-deck .card {
                flex: 1 0 0%;
                margin-right: 15px;
                margin-bottom: 0;
                margin-left: 15px
            }
        }

        .card-group>.card {
            margin-bottom: 15px
        }

        @media (min-width:576px) {
            .card-group {
                display: flex;
                flex-flow: row wrap
            }

            .card-group>.card {
                flex: 1 0 0%;
                margin-bottom: 0
            }

            .card-group>.card+.card {
                margin-left: 0;
                border-left: 0
            }

            .card-group>.card:not(:last-child) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0
            }

            .card-group>.card:not(:last-child) .card-header,
            .card-group>.card:not(:last-child) .card-img-top {
                border-top-right-radius: 0
            }

            .card-group>.card:not(:last-child) .card-footer,
            .card-group>.card:not(:last-child) .card-img-bottom {
                border-bottom-right-radius: 0
            }

            .card-group>.card:not(:first-child) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0
            }

            .card-group>.card:not(:first-child) .card-header,
            .card-group>.card:not(:first-child) .card-img-top {
                border-top-left-radius: 0
            }

            .card-group>.card:not(:first-child) .card-footer,
            .card-group>.card:not(:first-child) .card-img-bottom {
                border-bottom-left-radius: 0
            }
        }

        .card-columns .card {
            margin-bottom: .75rem
        }

        @media (min-width:576px) {
            .card-columns {
                -moz-column-count: 3;
                column-count: 3;
                -webkit-column-gap: 1.25rem;
                -moz-column-gap: 1.25rem;
                grid-column-gap: 1.25rem;
                column-gap: 1.25rem;
                orphans: 1;
                widows: 1
            }

            .card-columns .card {
                display: inline-block;
                width: 100%
            }
        }

        .accordion {
            overflow-anchor: none
        }

        .accordion>.card {
            overflow: hidden
        }

        .accordion>.card:not(:last-of-type) {
            border-bottom: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0
        }

        .accordion>.card:not(:first-of-type) {
            border-top-left-radius: 0;
            border-top-right-radius: 0
        }

        .accordion>.card>.card-header {
            border-radius: 0;
            margin-bottom: -1px
        }

        .breadcrumb {
            display: flex;
            flex-wrap: wrap;
            padding: .75rem 1rem;
            margin-bottom: 1rem;
            list-style: none;
            background-color: #e9ecef;
            border-radius: .25rem
        }

        .breadcrumb-item+.breadcrumb-item {
            padding-left: .5rem
        }

        .breadcrumb-item+.breadcrumb-item:before {
            float: left;
            padding-right: .5rem;
            color: #6c757d;
            content: "/"
        }

        .breadcrumb-item+.breadcrumb-item:hover:before {
            text-decoration: underline;
            text-decoration: none
        }

        .breadcrumb-item.active {
            color: #6c757d
        }

        .pagination {
            display: flex;
            padding-left: 0;
            list-style: none;
            border-radius: .25rem
        }

        .page-link {
            position: relative;
            display: block;
            padding: .5rem .75rem;
            margin-left: -1px;
            line-height: 1.25;
            color: #007bff;
            background-color: #fff;
            border: 1px solid #dee2e6
        }

        .page-link:hover {
            z-index: 2;
            color: #0056b3;
            text-decoration: none;
            background-color: #e9ecef;
            border-color: #dee2e6
        }

        .page-link:focus {
            z-index: 3;
            outline: 0;
            box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
        }

        .page-item:first-child .page-link {
            margin-left: 0;
            border-top-left-radius: .25rem;
            border-bottom-left-radius: .25rem
        }

        .page-item:last-child .page-link {
            border-top-right-radius: .25rem;
            border-bottom-right-radius: .25rem
        }

        .page-item.active .page-link {
            z-index: 3;
            color: #fff;
            background-color: #007bff;
            border-color: #007bff
        }

        .page-item.disabled .page-link {
            color: #6c757d;
            pointer-events: none;
            cursor: auto;
            background-color: #fff;
            border-color: #dee2e6
        }

        .pagination-lg .page-link {
            padding: .75rem 1.5rem;
            font-size: 1.25rem;
            line-height: 1.5
        }

        .pagination-lg .page-item:first-child .page-link {
            border-top-left-radius: .3rem;
            border-bottom-left-radius: .3rem
        }

        .pagination-lg .page-item:last-child .page-link {
            border-top-right-radius: .3rem;
            border-bottom-right-radius: .3rem
        }

        .pagination-sm .page-link {
            padding: .25rem .5rem;
            font-size: .875rem;
            line-height: 1.5
        }

        .pagination-sm .page-item:first-child .page-link {
            border-top-left-radius: .2rem;
            border-bottom-left-radius: .2rem
        }

        .pagination-sm .page-item:last-child .page-link {
            border-top-right-radius: .2rem;
            border-bottom-right-radius: .2rem
        }

        .badge {
            display: inline-block;
            padding: .25em .4em;
            font-size: 75%;
            font-weight: 700;
            line-height: 1;
            text-align: center;
            white-space: nowrap;
            vertical-align: baseline;
            border-radius: .25rem;
            transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
        }

        @media (prefers-reduced-motion:reduce) {
            .badge {
                transition: none
            }
        }

        a.badge:focus,
        a.badge:hover {
            text-decoration: none
        }

        .badge:empty {
            display: none
        }

        .btn .badge {
            position: relative;
            top: -1px
        }

        .badge-pill {
            padding-right: .6em;
            padding-left: .6em;
            border-radius: 10rem
        }

        .badge-primary {
            color: #fff;
            background-color: #007bff
        }

        a.badge-primary:focus,
        a.badge-primary:hover {
            color: #fff;
            background-color: #0062cc
        }

        a.badge-primary.focus,
        a.badge-primary:focus {
            outline: 0;
            box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
        }

        .badge-secondary {
            color: #fff;
            background-color: #6c757d
        }

        a.badge-secondary:focus,
        a.badge-secondary:hover {
            color: #fff;
            background-color: #545b62
        }

        a.badge-secondary.focus,
        a.badge-secondary:focus {
            outline: 0;
            box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
        }

        .badge-success {
            color: #fff;
            background-color: #28a745
        }

        a.badge-success:focus,
        a.badge-success:hover {
            color: #fff;
            background-color: #1e7e34
        }

        a.badge-success.focus,
        a.badge-success:focus {
            outline: 0;
            box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
        }

        .badge-info {
            color: #fff;
            background-color: #17a2b8
        }

        a.badge-info:focus,
        a.badge-info:hover {
            color: #fff;
            background-color: #117a8b
        }

        a.badge-info.focus,
        a.badge-info:focus {
            outline: 0;
            box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
        }

        .badge-warning {
            color: #212529;
            background-color: #ffc107
        }

        a.badge-warning:focus,
        a.badge-warning:hover {
            color: #212529;
            background-color: #d39e00
        }

        a.badge-warning.focus,
        a.badge-warning:focus {
            outline: 0;
            box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
        }

        .badge-danger {
            color: #fff;
            background-color: #dc3545
        }

        a.badge-danger:focus,
        a.badge-danger:hover {
            color: #fff;
            background-color: #bd2130
        }

        a.badge-danger.focus,
        a.badge-danger:focus {
            outline: 0;
            box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
        }

        .badge-light {
            color: #212529;
            background-color: #f8f9fa
        }

        a.badge-light:focus,
        a.badge-light:hover {
            color: #212529;
            background-color: #dae0e5
        }

        a.badge-light.focus,
        a.badge-light:focus {
            outline: 0;
            box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
        }

        .badge-dark {
            color: #fff;
            background-color: #343a40
        }

        a.badge-dark:focus,
        a.badge-dark:hover {
            color: #fff;
            background-color: #1d2124
        }

        a.badge-dark.focus,
        a.badge-dark:focus {
            outline: 0;
            box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
        }

        .jumbotron {
            padding: 2rem 1rem;
            margin-bottom: 2rem;
            background-color: #e9ecef;
            border-radius: .3rem
        }

        @media (min-width:576px) {
            .jumbotron {
                padding: 4rem 2rem
            }
        }

        .jumbotron-fluid {
            padding-right: 0;
            padding-left: 0;
            border-radius: 0
        }

        .alert {
            position: relative;
            padding: .75rem 1.25rem;
            margin-bottom: 1rem;
            border: 1px solid transparent;
            border-radius: .25rem
        }

        .alert-heading {
            color: inherit
        }

        .alert-link {
            font-weight: 700
        }

        .alert-dismissible {
            padding-right: 4rem
        }

        .alert-dismissible .close {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
            padding: .75rem 1.25rem;
            color: inherit
        }

        .alert-primary {
            color: #004085;
            background-color: #cce5ff;
            border-color: #b8daff
        }

        .alert-primary hr {
            border-top-color: #9fcdff
        }

        .alert-primary .alert-link {
            color: #002752
        }

        .alert-secondary {
            color: #383d41;
            background-color: #e2e3e5;
            border-color: #d6d8db
        }

        .alert-secondary hr {
            border-top-color: #c8cbcf
        }

        .alert-secondary .alert-link {
            color: #202326
        }

        .alert-success {
            color: #155724;
            background-color: #d4edda;
            border-color: #c3e6cb
        }

        .alert-success hr {
            border-top-color: #b1dfbb
        }

        .alert-success .alert-link {
            color: #0b2e13
        }

        .alert-info {
            color: #0c5460;
            background-color: #d1ecf1;
            border-color: #bee5eb
        }

        .alert-info hr {
            border-top-color: #abdde5
        }

        .alert-info .alert-link {
            color: #062c33
        }

        .alert-warning {
            color: #856404;
            background-color: #fff3cd;
            border-color: #ffeeba
        }

        .alert-warning hr {
            border-top-color: #ffe8a1
        }

        .alert-warning .alert-link {
            color: #533f03
        }

        .alert-danger {
            color: #721c24;
            background-color: #f8d7da;
            border-color: #f5c6cb
        }

        .alert-danger hr {
            border-top-color: #f1b0b7
        }

        .alert-danger .alert-link {
            color: #491217
        }

        .alert-light {
            color: #818182;
            background-color: #fefefe;
            border-color: #fdfdfe
        }

        .alert-light hr {
            border-top-color: #ececf6
        }

        .alert-light .alert-link {
            color: #686868
        }

        .alert-dark {
            color: #1b1e21;
            background-color: #d6d8d9;
            border-color: #c6c8ca
        }

        .alert-dark hr {
            border-top-color: #b9bbbe
        }

        .alert-dark .alert-link {
            color: #040505
        }

        @-webkit-keyframes progress-bar-stripes {
            0% {
                background-position: 1rem 0
            }

            to {
                background-position: 0 0
            }
        }

        @keyframes progress-bar-stripes {
            0% {
                background-position: 1rem 0
            }

            to {
                background-position: 0 0
            }
        }

        .progress {
            height: 1rem;
            line-height: 0;
            font-size: .75rem;
            background-color: #e9ecef;
            border-radius: .25rem
        }

        .progress,
        .progress-bar {
            display: flex;
            overflow: hidden
        }

        .progress-bar {
            flex-direction: column;
            justify-content: center;
            color: #fff;
            text-align: center;
            white-space: nowrap;
            background-color: #007bff;
            transition: width .6s ease
        }

        @media (prefers-reduced-motion:reduce) {
            .progress-bar {
                transition: none
            }
        }

        .progress-bar-striped {
            background-image: linear-gradient(45deg, hsla(0, 0%, 100%, .15) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .15) 0, hsla(0, 0%, 100%, .15) 75%, transparent 0, transparent);
            background-size: 1rem 1rem
        }

        .progress-bar-animated {
            -webkit-animation: progress-bar-stripes 1s linear infinite;
            animation: progress-bar-stripes 1s linear infinite
        }

        @media (prefers-reduced-motion:reduce) {
            .progress-bar-animated {
                -webkit-animation: none;
                animation: none
            }
        }

        .media {
            display: flex;
            align-items: flex-start
        }

        .media-body {
            flex: 1
        }

        .list-group {
            display: flex;
            flex-direction: column;
            padding-left: 0;
            margin-bottom: 0;
            border-radius: .25rem
        }

        .list-group-item-action {
            width: 100%;
            color: #495057;
            text-align: inherit
        }

        .list-group-item-action:focus,
        .list-group-item-action:hover {
            z-index: 1;
            color: #495057;
            text-decoration: none;
            background-color: #f8f9fa
        }

        .list-group-item-action:active {
            color: #212529;
            background-color: #e9ecef
        }

        .list-group-item {
            position: relative;
            display: block;
            padding: .75rem 1.25rem;
            background-color: #fff;
            border: 1px solid rgba(0, 0, 0, .125)
        }

        .list-group-item:first-child {
            border-top-left-radius: inherit;
            border-top-right-radius: inherit
        }

        .list-group-item:last-child {
            border-bottom-right-radius: inherit;
            border-bottom-left-radius: inherit
        }

        .list-group-item.disabled,
        .list-group-item:disabled {
            color: #6c757d;
            pointer-events: none;
            background-color: #fff
        }

        .list-group-item.active {
            z-index: 2;
            color: #fff;
            background-color: #007bff;
            border-color: #007bff
        }

        .list-group-item+.list-group-item {
            border-top-width: 0
        }

        .list-group-item+.list-group-item.active {
            margin-top: -1px;
            border-top-width: 1px
        }

        .list-group-horizontal {
            flex-direction: row
        }

        .list-group-horizontal>.list-group-item:first-child {
            border-bottom-left-radius: .25rem;
            border-top-right-radius: 0
        }

        .list-group-horizontal>.list-group-item:last-child {
            border-top-right-radius: .25rem;
            border-bottom-left-radius: 0
        }

        .list-group-horizontal>.list-group-item.active {
            margin-top: 0
        }

        .list-group-horizontal>.list-group-item+.list-group-item {
            border-top-width: 1px;
            border-left-width: 0
        }

        .list-group-horizontal>.list-group-item+.list-group-item.active {
            margin-left: -1px;
            border-left-width: 1px
        }

        @media (min-width:576px) {
            .list-group-horizontal-sm {
                flex-direction: row
            }

            .list-group-horizontal-sm>.list-group-item:first-child {
                border-bottom-left-radius: .25rem;
                border-top-right-radius: 0
            }

            .list-group-horizontal-sm>.list-group-item:last-child {
                border-top-right-radius: .25rem;
                border-bottom-left-radius: 0
            }

            .list-group-horizontal-sm>.list-group-item.active {
                margin-top: 0
            }

            .list-group-horizontal-sm>.list-group-item+.list-group-item {
                border-top-width: 1px;
                border-left-width: 0
            }

            .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
                margin-left: -1px;
                border-left-width: 1px
            }
        }

        @media (min-width:768px) {
            .list-group-horizontal-md {
                flex-direction: row
            }

            .list-group-horizontal-md>.list-group-item:first-child {
                border-bottom-left-radius: .25rem;
                border-top-right-radius: 0
            }

            .list-group-horizontal-md>.list-group-item:last-child {
                border-top-right-radius: .25rem;
                border-bottom-left-radius: 0
            }

            .list-group-horizontal-md>.list-group-item.active {
                margin-top: 0
            }

            .list-group-horizontal-md>.list-group-item+.list-group-item {
                border-top-width: 1px;
                border-left-width: 0
            }

            .list-group-horizontal-md>.list-group-item+.list-group-item.active {
                margin-left: -1px;
                border-left-width: 1px
            }
        }

        @media (min-width:992px) {
            .list-group-horizontal-lg {
                flex-direction: row
            }

            .list-group-horizontal-lg>.list-group-item:first-child {
                border-bottom-left-radius: .25rem;
                border-top-right-radius: 0
            }

            .list-group-horizontal-lg>.list-group-item:last-child {
                border-top-right-radius: .25rem;
                border-bottom-left-radius: 0
            }

            .list-group-horizontal-lg>.list-group-item.active {
                margin-top: 0
            }

            .list-group-horizontal-lg>.list-group-item+.list-group-item {
                border-top-width: 1px;
                border-left-width: 0
            }

            .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
                margin-left: -1px;
                border-left-width: 1px
            }
        }

        @media (min-width:1200px) {
            .list-group-horizontal-xl {
                flex-direction: row
            }

            .list-group-horizontal-xl>.list-group-item:first-child {
                border-bottom-left-radius: .25rem;
                border-top-right-radius: 0
            }

            .list-group-horizontal-xl>.list-group-item:last-child {
                border-top-right-radius: .25rem;
                border-bottom-left-radius: 0
            }

            .list-group-horizontal-xl>.list-group-item.active {
                margin-top: 0
            }

            .list-group-horizontal-xl>.list-group-item+.list-group-item {
                border-top-width: 1px;
                border-left-width: 0
            }

            .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
                margin-left: -1px;
                border-left-width: 1px
            }
        }

        .list-group-flush {
            border-radius: 0
        }

        .list-group-flush>.list-group-item {
            border-width: 0 0 1px
        }

        .list-group-flush>.list-group-item:last-child {
            border-bottom-width: 0
        }

        .list-group-item-primary {
            color: #004085;
            background-color: #b8daff
        }

        .list-group-item-primary.list-group-item-action:focus,
        .list-group-item-primary.list-group-item-action:hover {
            color: #004085;
            background-color: #9fcdff
        }

        .list-group-item-primary.list-group-item-action.active {
            color: #fff;
            background-color: #004085;
            border-color: #004085
        }

        .list-group-item-secondary {
            color: #383d41;
            background-color: #d6d8db
        }

        .list-group-item-secondary.list-group-item-action:focus,
        .list-group-item-secondary.list-group-item-action:hover {
            color: #383d41;
            background-color: #c8cbcf
        }

        .list-group-item-secondary.list-group-item-action.active {
            color: #fff;
            background-color: #383d41;
            border-color: #383d41
        }

        .list-group-item-success {
            color: #155724;
            background-color: #c3e6cb
        }

        .list-group-item-success.list-group-item-action:focus,
        .list-group-item-success.list-group-item-action:hover {
            color: #155724;
            background-color: #b1dfbb
        }

        .list-group-item-success.list-group-item-action.active {
            color: #fff;
            background-color: #155724;
            border-color: #155724
        }

        .list-group-item-info {
            color: #0c5460;
            background-color: #bee5eb
        }

        .list-group-item-info.list-group-item-action:focus,
        .list-group-item-info.list-group-item-action:hover {
            color: #0c5460;
            background-color: #abdde5
        }

        .list-group-item-info.list-group-item-action.active {
            color: #fff;
            background-color: #0c5460;
            border-color: #0c5460
        }

        .list-group-item-warning {
            color: #856404;
            background-color: #ffeeba
        }

        .list-group-item-warning.list-group-item-action:focus,
        .list-group-item-warning.list-group-item-action:hover {
            color: #856404;
            background-color: #ffe8a1
        }

        .list-group-item-warning.list-group-item-action.active {
            color: #fff;
            background-color: #856404;
            border-color: #856404
        }

        .list-group-item-danger {
            color: #721c24;
            background-color: #f5c6cb
        }

        .list-group-item-danger.list-group-item-action:focus,
        .list-group-item-danger.list-group-item-action:hover {
            color: #721c24;
            background-color: #f1b0b7
        }

        .list-group-item-danger.list-group-item-action.active {
            color: #fff;
            background-color: #721c24;
            border-color: #721c24
        }

        .list-group-item-light {
            color: #818182;
            background-color: #fdfdfe
        }

        .list-group-item-light.list-group-item-action:focus,
        .list-group-item-light.list-group-item-action:hover {
            color: #818182;
            background-color: #ececf6
        }

        .list-group-item-light.list-group-item-action.active {
            color: #fff;
            background-color: #818182;
            border-color: #818182
        }

        .list-group-item-dark {
            color: #1b1e21;
            background-color: #c6c8ca
        }

        .list-group-item-dark.list-group-item-action:focus,
        .list-group-item-dark.list-group-item-action:hover {
            color: #1b1e21;
            background-color: #b9bbbe
        }

        .list-group-item-dark.list-group-item-action.active {
            color: #fff;
            background-color: #1b1e21;
            border-color: #1b1e21
        }

        .close {
            float: right;
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 1;
            color: #000;
            text-shadow: 0 1px 0 #fff;
            opacity: .5
        }

        .close:hover {
            color: #000;
            text-decoration: none
        }

        .close:not(:disabled):not(.disabled):focus,
        .close:not(:disabled):not(.disabled):hover {
            opacity: .75
        }

        button.close {
            padding: 0;
            background-color: transparent;
            border: 0
        }

        a.close.disabled {
            pointer-events: none
        }

        .toast {
            flex-basis: 350px;
            max-width: 350px;
            font-size: .875rem;
            background-color: hsla(0, 0%, 100%, .85);
            background-clip: padding-box;
            border: 1px solid rgba(0, 0, 0, .1);
            box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .1);
            opacity: 0;
            border-radius: .25rem
        }

        .toast:not(:last-child) {
            margin-bottom: .75rem
        }

        .toast.showing {
            opacity: 1
        }

        .toast.show {
            display: block;
            opacity: 1
        }

        .toast.hide {
            display: none
        }

        .toast-header {
            display: flex;
            align-items: center;
            padding: .25rem .75rem;
            color: #6c757d;
            background-color: hsla(0, 0%, 100%, .85);
            background-clip: padding-box;
            border-bottom: 1px solid rgba(0, 0, 0, .05);
            border-top-left-radius: calc(.25rem - 1px);
            border-top-right-radius: calc(.25rem - 1px)
        }

        .toast-body {
            padding: .75rem
        }

        .modal-open {
            overflow: hidden
        }

        .modal-open .modal {
            overflow-x: hidden;
            overflow-y: auto
        }

        .modal {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1050;
            display: none;
            width: 100%;
            height: 100%;
            overflow: hidden;
            outline: 0
        }

        .modal-dialog {
            position: relative;
            width: auto;
            margin: .5rem;
            pointer-events: none
        }

        .modal.fade .modal-dialog {
            transition: transform .3s ease-out;
            transform: translateY(-50px)
        }

        @media (prefers-reduced-motion:reduce) {
            .modal.fade .modal-dialog {
                transition: none
            }
        }

        .modal.show .modal-dialog {
            transform: none
        }

        .modal.modal-static .modal-dialog {
            transform: scale(1.02)
        }

        .modal-dialog-scrollable {
            display: flex;
            max-height: calc(100% - 1rem)
        }

        .modal-dialog-scrollable .modal-content {
            max-height: calc(100vh - 1rem);
            overflow: hidden
        }

        .modal-dialog-scrollable .modal-footer,
        .modal-dialog-scrollable .modal-header {
            flex-shrink: 0
        }

        .modal-dialog-scrollable .modal-body {
            overflow-y: auto
        }

        .modal-dialog-centered {
            display: flex;
            align-items: center;
            min-height: calc(100% - 1rem)
        }

        .modal-dialog-centered:before {
            display: block;
            height: calc(100vh - 1rem);
            height: -webkit-min-content;
            height: -moz-min-content;
            height: min-content;
            content: ""
        }

        .modal-dialog-centered.modal-dialog-scrollable {
            flex-direction: column;
            justify-content: center;
            height: 100%
        }

        .modal-dialog-centered.modal-dialog-scrollable .modal-content {
            max-height: none
        }

        .modal-dialog-centered.modal-dialog-scrollable:before {
            content: none
        }

        .modal-content {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            pointer-events: auto;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid rgba(0, 0, 0, .2);
            border-radius: .3rem;
            outline: 0
        }

        .modal-backdrop {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1040;
            width: 100vw;
            height: 100vh;
            background-color: #000
        }

        .modal-backdrop.fade {
            opacity: 0
        }

        .modal-backdrop.show {
            opacity: .5
        }

        .modal-header {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding: 1rem;
            border-bottom: 1px solid #dee2e6;
            border-top-left-radius: calc(.3rem - 1px);
            border-top-right-radius: calc(.3rem - 1px)
        }

        .modal-header .close {
            padding: 1rem;
            margin: -1rem -1rem -1rem auto
        }

        .modal-title {
            margin-bottom: 0;
            line-height: 1.5
        }

        .modal-body {
            position: relative;
            flex: 1 1 auto;
            padding: 1rem
        }

        .modal-footer {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-end;
            padding: .75rem;
            border-top: 1px solid #dee2e6;
            border-bottom-right-radius: calc(.3rem - 1px);
            border-bottom-left-radius: calc(.3rem - 1px)
        }

        .modal-footer>* {
            margin: .25rem
        }

        .modal-scrollbar-measure {
            position: absolute;
            top: -9999px;
            width: 50px;
            height: 50px;
            overflow: scroll
        }

        @media (min-width:576px) {
            .modal-dialog {
                max-width: 500px;
                margin: 1.75rem auto
            }

            .modal-dialog-scrollable {
                max-height: calc(100% - 3.5rem)
            }

            .modal-dialog-scrollable .modal-content {
                max-height: calc(100vh - 3.5rem)
            }

            .modal-dialog-centered {
                min-height: calc(100% - 3.5rem)
            }

            .modal-dialog-centered:before {
                height: calc(100vh - 3.5rem);
                height: -webkit-min-content;
                height: -moz-min-content;
                height: min-content
            }

            .modal-sm {
                max-width: 300px
            }
        }

        @media (min-width:992px) {

            .modal-lg,
            .modal-xl {
                max-width: 800px
            }
        }

        @media (min-width:1200px) {
            .modal-xl {
                max-width: 1140px
            }
        }

        [dir=ltr] .tooltip {
            text-align: left
        }

        [dir=rtl] .tooltip {
            text-align: right
        }

        .tooltip {
            position: absolute;
            z-index: 1070;
            display: block;
            margin: 0;
            font-family: monospace;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5;
            text-align: left;
            text-decoration: none;
            text-shadow: none;
            text-transform: none;
            letter-spacing: normal;
            word-break: normal;
            word-spacing: normal;
            white-space: normal;
            line-break: auto;
            font-size: .875rem;
            word-wrap: break-word;
            opacity: 0
        }

        .tooltip.show {
            opacity: .9
        }

        .tooltip .arrow {
            position: absolute;
            display: block;
            width: .8rem;
            height: .4rem
        }

        .tooltip .arrow:before {
            position: absolute;
            content: "";
            border-color: transparent;
            border-style: solid
        }

        .bs-tooltip-auto[x-placement^=top],
        .bs-tooltip-top {
            padding: .4rem 0
        }

        .bs-tooltip-auto[x-placement^=top] .arrow,
        .bs-tooltip-top .arrow {
            bottom: 0
        }

        .bs-tooltip-auto[x-placement^=top] .arrow:before,
        .bs-tooltip-top .arrow:before {
            top: 0;
            border-width: .4rem .4rem 0;
            border-top-color: #000
        }

        .bs-tooltip-auto[x-placement^=right],
        .bs-tooltip-right {
            padding: 0 .4rem
        }

        .bs-tooltip-auto[x-placement^=right] .arrow,
        .bs-tooltip-right .arrow {
            left: 0;
            width: .4rem;
            height: .8rem
        }

        .bs-tooltip-auto[x-placement^=right] .arrow:before,
        .bs-tooltip-right .arrow:before {
            right: 0;
            border-width: .4rem .4rem .4rem 0;
            border-right-color: #000
        }

        .bs-tooltip-auto[x-placement^=bottom],
        .bs-tooltip-bottom {
            padding: .4rem 0
        }

        .bs-tooltip-auto[x-placement^=bottom] .arrow,
        .bs-tooltip-bottom .arrow {
            top: 0
        }

        .bs-tooltip-auto[x-placement^=bottom] .arrow:before,
        .bs-tooltip-bottom .arrow:before {
            bottom: 0;
            border-width: 0 .4rem .4rem;
            border-bottom-color: #000
        }

        .bs-tooltip-auto[x-placement^=left],
        .bs-tooltip-left {
            padding: 0 .4rem
        }

        .bs-tooltip-auto[x-placement^=left] .arrow,
        .bs-tooltip-left .arrow {
            right: 0;
            width: .4rem;
            height: .8rem
        }

        .bs-tooltip-auto[x-placement^=left] .arrow:before,
        .bs-tooltip-left .arrow:before {
            left: 0;
            border-width: .4rem 0 .4rem .4rem;
            border-left-color: #000
        }

        .tooltip-inner {
            max-width: 200px;
            padding: .25rem .5rem;
            color: #fff;
            text-align: center;
            background-color: #000;
            border-radius: .25rem
        }

        [dir=ltr] .popover {
            text-align: left
        }

        [dir=rtl] .popover {
            text-align: right
        }

        .popover {
            top: 0;
            left: 0;
            z-index: 1060;
            max-width: 276px;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
            font-style: normal;
            font-weight: 400;
            line-height: 1.5;
            text-align: left;
            text-decoration: none;
            text-shadow: none;
            text-transform: none;
            letter-spacing: normal;
            word-break: normal;
            word-spacing: normal;
            white-space: normal;
            line-break: auto;
            font-size: .875rem;
            word-wrap: break-word;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid rgba(0, 0, 0, .2);
            border-radius: .3rem
        }

        .popover,
        .popover .arrow {
            position: absolute;
            display: block
        }

        .popover .arrow {
            width: 1rem;
            height: .5rem;
            margin: 0 .3rem
        }

        .popover .arrow:after,
        .popover .arrow:before {
            position: absolute;
            display: block;
            content: "";
            border-color: transparent;
            border-style: solid
        }

        .bs-popover-auto[x-placement^=top],
        .bs-popover-top {
            margin-bottom: .5rem
        }

        .bs-popover-auto[x-placement^=top]>.arrow,
        .bs-popover-top>.arrow {
            bottom: calc(-.5rem - 1px)
        }

        .bs-popover-auto[x-placement^=top]>.arrow:before,
        .bs-popover-top>.arrow:before {
            bottom: 0;
            border-width: .5rem .5rem 0;
            border-top-color: rgba(0, 0, 0, .25)
        }

        .bs-popover-auto[x-placement^=top]>.arrow:after,
        .bs-popover-top>.arrow:after {
            bottom: 1px;
            border-width: .5rem .5rem 0;
            border-top-color: #fff
        }

        .bs-popover-auto[x-placement^=right],
        .bs-popover-right {
            margin-left: .5rem
        }

        .bs-popover-auto[x-placement^=right]>.arrow,
        .bs-popover-right>.arrow {
            left: calc(-.5rem - 1px);
            width: .5rem;
            height: 1rem;
            margin: .3rem 0
        }

        .bs-popover-auto[x-placement^=right]>.arrow:before,
        .bs-popover-right>.arrow:before {
            left: 0;
            border-width: .5rem .5rem .5rem 0;
            border-right-color: rgba(0, 0, 0, .25)
        }

        .bs-popover-auto[x-placement^=right]>.arrow:after,
        .bs-popover-right>.arrow:after {
            left: 1px;
            border-width: .5rem .5rem .5rem 0;
            border-right-color: #fff
        }

        .bs-popover-auto[x-placement^=bottom],
        .bs-popover-bottom {
            margin-top: .5rem
        }

        .bs-popover-auto[x-placement^=bottom]>.arrow,
        .bs-popover-bottom>.arrow {
            top: calc(-.5rem - 1px)
        }

        .bs-popover-auto[x-placement^=bottom]>.arrow:before,
        .bs-popover-bottom>.arrow:before {
            top: 0;
            border-width: 0 .5rem .5rem;
            border-bottom-color: rgba(0, 0, 0, .25)
        }

        .bs-popover-auto[x-placement^=bottom]>.arrow:after,
        .bs-popover-bottom>.arrow:after {
            top: 1px;
            border-width: 0 .5rem .5rem;
            border-bottom-color: #fff
        }

        .bs-popover-auto[x-placement^=bottom] .popover-header:before,
        .bs-popover-bottom .popover-header:before {
            position: absolute;
            top: 0;
            left: 50%;
            display: block;
            width: 1rem;
            margin-left: -.5rem;
            content: "";
            border-bottom: 1px solid #f7f7f7
        }

        .bs-popover-auto[x-placement^=left],
        .bs-popover-left {
            margin-right: .5rem
        }

        .bs-popover-auto[x-placement^=left]>.arrow,
        .bs-popover-left>.arrow {
            right: calc(-.5rem - 1px);
            width: .5rem;
            height: 1rem;
            margin: .3rem 0
        }

        .bs-popover-auto[x-placement^=left]>.arrow:before,
        .bs-popover-left>.arrow:before {
            right: 0;
            border-width: .5rem 0 .5rem .5rem;
            border-left-color: rgba(0, 0, 0, .25)
        }

        .bs-popover-auto[x-placement^=left]>.arrow:after,
        .bs-popover-left>.arrow:after {
            right: 1px;
            border-width: .5rem 0 .5rem .5rem;
            border-left-color: #fff
        }

        .popover-header {
            padding: .5rem .75rem;
            margin-bottom: 0;
            font-size: 1rem;
            background-color: #f7f7f7;
            border-bottom: 1px solid #ebebeb;
            border-top-left-radius: calc(.3rem - 1px);
            border-top-right-radius: calc(.3rem - 1px)
        }

        .popover-header:empty {
            display: none
        }

        .popover-body {
            padding: .5rem .75rem;
            color: #212529
        }

        .carousel {
            position: relative
        }

        .carousel.pointer-event {
            touch-action: pan-y
        }

        .carousel-inner {
            position: relative;
            width: 100%;
            overflow: hidden
        }

        .carousel-inner:after {
            display: block;
            clear: both;
            content: ""
        }

        .carousel-item {
            position: relative;
            display: none;
            float: left;
            width: 100%;
            margin-right: -100%;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            transition: transform .6s ease-in-out
        }

        @media (prefers-reduced-motion:reduce) {
            .carousel-item {
                transition: none
            }
        }

        .carousel-item-next,
        .carousel-item-prev,
        .carousel-item.active {
            display: block
        }

        .active.carousel-item-right,
        .carousel-item-next:not(.carousel-item-left) {
            transform: translateX(100%)
        }

        .active.carousel-item-left,
        .carousel-item-prev:not(.carousel-item-right) {
            transform: translateX(-100%)
        }

        .carousel-fade .carousel-item {
            opacity: 0;
            transition-property: opacity;
            transform: none
        }

        .carousel-fade .carousel-item-next.carousel-item-left,
        .carousel-fade .carousel-item-prev.carousel-item-right,
        .carousel-fade .carousel-item.active {
            z-index: 1;
            opacity: 1
        }

        .carousel-fade .active.carousel-item-left,
        .carousel-fade .active.carousel-item-right {
            z-index: 0;
            opacity: 0;
            transition: opacity 0s .6s
        }

        @media (prefers-reduced-motion:reduce) {

            .carousel-fade .active.carousel-item-left,
            .carousel-fade .active.carousel-item-right {
                transition: none
            }
        }

        .carousel-control-next,
        .carousel-control-prev {
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 15%;
            color: #fff;
            text-align: center;
            opacity: .5;
            transition: opacity .15s ease
        }

        @media (prefers-reduced-motion:reduce) {

            .carousel-control-next,
            .carousel-control-prev {
                transition: none
            }
        }

        .carousel-control-next:focus,
        .carousel-control-next:hover,
        .carousel-control-prev:focus,
        .carousel-control-prev:hover {
            color: #fff;
            text-decoration: none;
            outline: 0;
            opacity: .9
        }

        .carousel-control-prev {
            left: 0
        }

        .carousel-control-next {
            right: 0
        }

        .carousel-control-next-icon,
        .carousel-control-prev-icon {
            display: inline-block;
            width: 20px;
            height: 20px;
            background: 50%/100% 100% no-repeat
        }

        .carousel-control-prev-icon {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3E%3C/svg%3E")
        }

        .carousel-control-next-icon {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8'%3E%3Cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3E%3C/svg%3E")
        }

        .carousel-indicators {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 15;
            display: flex;
            justify-content: center;
            padding-left: 0;
            margin-right: 15%;
            margin-left: 15%;
            list-style: none
        }

        .carousel-indicators li {
            box-sizing: content-box;
            flex: 0 1 auto;
            width: 30px;
            height: 3px;
            margin-right: 3px;
            margin-left: 3px;
            text-indent: -999px;
            cursor: pointer;
            background-color: #fff;
            background-clip: padding-box;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            opacity: .5;
            transition: opacity .6s ease
        }

        @media (prefers-reduced-motion:reduce) {
            .carousel-indicators li {
                transition: none
            }
        }

        .carousel-indicators .active {
            opacity: 1
        }

        .carousel-caption {
            position: absolute;
            right: 15%;
            bottom: 20px;
            left: 15%;
            z-index: 10;
            padding-top: 20px;
            padding-bottom: 20px;
            color: #fff;
            text-align: center
        }

        @-webkit-keyframes spinner-border {
            to {
                transform: rotate(1turn)
            }
        }

        @keyframes spinner-border {
            to {
                transform: rotate(1turn)
            }
        }

        .spinner-border {
            display: inline-block;
            width: 2rem;
            height: 2rem;
            vertical-align: text-bottom;
            border: .25em solid;
            border-right: .25em solid transparent;
            border-radius: 50%;
            -webkit-animation: spinner-border .75s linear infinite;
            animation: spinner-border .75s linear infinite
        }

        .spinner-border-sm {
            width: 1rem;
            height: 1rem;
            border-width: .2em
        }

        @-webkit-keyframes spinner-grow {
            0% {
                transform: scale(0)
            }

            50% {
                opacity: 1;
                transform: none
            }
        }

        @keyframes spinner-grow {
            0% {
                transform: scale(0)
            }

            50% {
                opacity: 1;
                transform: none
            }
        }

        .spinner-grow {
            display: inline-block;
            width: 2rem;
            height: 2rem;
            vertical-align: text-bottom;
            background-color: currentColor;
            border-radius: 50%;
            opacity: 0;
            -webkit-animation: spinner-grow .75s linear infinite;
            animation: spinner-grow .75s linear infinite
        }

        .spinner-grow-sm {
            width: 1rem;
            height: 1rem
        }

        @media (prefers-reduced-motion:reduce) {

            .spinner-border,
            .spinner-grow {
                -webkit-animation-duration: 1.5s;
                animation-duration: 1.5s
            }
        }

        .align-baseline {
            vertical-align: baseline !important
        }

        .align-top {
            vertical-align: top !important
        }

        .align-middle {
            vertical-align: middle !important
        }

        .align-bottom {
            vertical-align: bottom !important
        }

        .align-text-bottom {
            vertical-align: text-bottom !important
        }

        .align-text-top {
            vertical-align: text-top !important
        }

        .bg-primary {
            background-color: #007bff !important
        }

        a.bg-primary:focus,
        a.bg-primary:hover,
        button.bg-primary:focus,
        button.bg-primary:hover {
            background-color: #0062cc !important
        }

        .bg-secondary {
            background-color: #6c757d !important
        }

        a.bg-secondary:focus,
        a.bg-secondary:hover,
        button.bg-secondary:focus,
        button.bg-secondary:hover {
            background-color: #545b62 !important
        }

        .bg-success {
            background-color: #28a745 !important
        }

        a.bg-success:focus,
        a.bg-success:hover,
        button.bg-success:focus,
        button.bg-success:hover {
            background-color: #1e7e34 !important
        }

        .bg-info {
            background-color: #17a2b8 !important
        }

        a.bg-info:focus,
        a.bg-info:hover,
        button.bg-info:focus,
        button.bg-info:hover {
            background-color: #117a8b !important
        }

        .bg-warning {
            background-color: #ffc107 !important
        }

        a.bg-warning:focus,
        a.bg-warning:hover,
        button.bg-warning:focus,
        button.bg-warning:hover {
            background-color: #d39e00 !important
        }

        .bg-danger {
            background-color: #dc3545 !important
        }

        a.bg-danger:focus,
        a.bg-danger:hover,
        button.bg-danger:focus,
        button.bg-danger:hover {
            background-color: #bd2130 !important
        }

        .bg-light {
            background-color: #f8f9fa !important
        }

        a.bg-light:focus,
        a.bg-light:hover,
        button.bg-light:focus,
        button.bg-light:hover {
            background-color: #dae0e5 !important
        }

        .bg-dark {
            background-color: #343a40 !important
        }

        a.bg-dark:focus,
        a.bg-dark:hover,
        button.bg-dark:focus,
        button.bg-dark:hover {
            background-color: #1d2124 !important
        }

        .bg-white {
            background-color: #fff !important
        }

        .bg-transparent {
            background-color: transparent !important
        }

        .border {
            border: 1px solid #dee2e6 !important
        }

        .border-top {
            border-top: 1px solid #dee2e6 !important
        }

        .border-right {
            border-right: 1px solid #dee2e6 !important
        }

        .border-bottom {
            border-bottom: 1px solid #dee2e6 !important
        }

        .border-left {
            border-left: 1px solid #dee2e6 !important
        }

        .border-0 {
            border: 0 !important
        }

        .border-top-0 {
            border-top: 0 !important
        }

        .border-right-0 {
            border-right: 0 !important
        }

        .border-bottom-0 {
            border-bottom: 0 !important
        }

        .border-left-0 {
            border-left: 0 !important
        }

        .border-primary {
            border-color: #007bff !important
        }

        .border-secondary {
            border-color: #6c757d !important
        }

        .border-success {
            border-color: #28a745 !important
        }

        .border-info {
            border-color: #17a2b8 !important
        }

        .border-warning {
            border-color: #ffc107 !important
        }

        .border-danger {
            border-color: #dc3545 !important
        }

        .border-light {
            border-color: #f8f9fa !important
        }

        .border-dark {
            border-color: #343a40 !important
        }

        .border-white {
            border-color: #fff !important
        }

        .rounded-sm {
            border-radius: .2rem !important
        }

        .rounded {
            border-radius: .25rem !important
        }

        .rounded-top {
            border-top-left-radius: .25rem !important
        }

        .rounded-right,
        .rounded-top {
            border-top-right-radius: .25rem !important
        }

        .rounded-bottom,
        .rounded-right {
            border-bottom-right-radius: .25rem !important
        }

        .rounded-bottom,
        .rounded-left {
            border-bottom-left-radius: .25rem !important
        }

        .rounded-left {
            border-top-left-radius: .25rem !important
        }

        .rounded-lg {
            border-radius: .3rem !important
        }

        .rounded-circle {
            border-radius: 50% !important
        }

        .rounded-pill {
            border-radius: 50rem !important
        }

        .rounded-0 {
            border-radius: 0 !important
        }

        .clearfix:after {
            display: block;
            clear: both;
            content: ""
        }

        .d-none {
            display: none !important
        }

        .d-inline {
            display: inline !important
        }

        .d-inline-block {
            display: inline-block !important
        }

        .d-block {
            display: block !important
        }

        .d-table {
            display: table !important
        }

        .d-table-row {
            display: table-row !important
        }

        .d-table-cell {
            display: table-cell !important
        }

        .d-flex {
            display: flex !important
        }

        .d-inline-flex {
            display: inline-flex !important
        }

        @media (min-width:576px) {
            .d-sm-none {
                display: none !important
            }

            .d-sm-inline {
                display: inline !important
            }

            .d-sm-inline-block {
                display: inline-block !important
            }

            .d-sm-block {
                display: block !important
            }

            .d-sm-table {
                display: table !important
            }

            .d-sm-table-row {
                display: table-row !important
            }

            .d-sm-table-cell {
                display: table-cell !important
            }

            .d-sm-flex {
                display: flex !important
            }

            .d-sm-inline-flex {
                display: inline-flex !important
            }
        }

        @media (min-width:768px) {
            .d-md-none {
                display: none !important
            }

            .d-md-inline {
                display: inline !important
            }

            .d-md-inline-block {
                display: inline-block !important
            }

            .d-md-block {
                display: block !important
            }

            .d-md-table {
                display: table !important
            }

            .d-md-table-row {
                display: table-row !important
            }

            .d-md-table-cell {
                display: table-cell !important
            }

            .d-md-flex {
                display: flex !important
            }

            .d-md-inline-flex {
                display: inline-flex !important
            }
        }

        @media (min-width:992px) {
            .d-lg-none {
                display: none !important
            }

            .d-lg-inline {
                display: inline !important
            }

            .d-lg-inline-block {
                display: inline-block !important
            }

            .d-lg-block {
                display: block !important
            }

            .d-lg-table {
                display: table !important
            }

            .d-lg-table-row {
                display: table-row !important
            }

            .d-lg-table-cell {
                display: table-cell !important
            }

            .d-lg-flex {
                display: flex !important
            }

            .d-lg-inline-flex {
                display: inline-flex !important
            }
        }

        @media (min-width:1200px) {
            .d-xl-none {
                display: none !important
            }

            .d-xl-inline {
                display: inline !important
            }

            .d-xl-inline-block {
                display: inline-block !important
            }

            .d-xl-block {
                display: block !important
            }

            .d-xl-table {
                display: table !important
            }

            .d-xl-table-row {
                display: table-row !important
            }

            .d-xl-table-cell {
                display: table-cell !important
            }

            .d-xl-flex {
                display: flex !important
            }

            .d-xl-inline-flex {
                display: inline-flex !important
            }
        }

        @media print {
            .d-print-none {
                display: none !important
            }

            .d-print-inline {
                display: inline !important
            }

            .d-print-inline-block {
                display: inline-block !important
            }

            .d-print-block {
                display: block !important
            }

            .d-print-table {
                display: table !important
            }

            .d-print-table-row {
                display: table-row !important
            }

            .d-print-table-cell {
                display: table-cell !important
            }

            .d-print-flex {
                display: flex !important
            }

            .d-print-inline-flex {
                display: inline-flex !important
            }
        }

        .embed-responsive {
            position: relative;
            display: block;
            width: 100%;
            padding: 0;
            overflow: hidden
        }

        .embed-responsive:before {
            display: block;
            content: ""
        }

        .embed-responsive .embed-responsive-item,
        .embed-responsive embed,
        .embed-responsive iframe,
        .embed-responsive object,
        .embed-responsive video {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0
        }

        .embed-responsive-21by9:before {
            padding-top: 42.857143%
        }

        .embed-responsive-16by9:before {
            padding-top: 56.25%
        }

        .embed-responsive-4by3:before {
            padding-top: 75%
        }

        .embed-responsive-1by1:before {
            padding-top: 100%
        }

        .flex-row {
            flex-direction: row !important
        }

        .flex-column {
            flex-direction: column !important
        }

        .flex-row-reverse {
            flex-direction: row-reverse !important
        }

        .flex-column-reverse {
            flex-direction: column-reverse !important
        }

        .flex-wrap {
            flex-wrap: wrap !important
        }

        .flex-nowrap {
            flex-wrap: nowrap !important
        }

        .flex-wrap-reverse {
            flex-wrap: wrap-reverse !important
        }

        .flex-fill {
            flex: 1 1 auto !important
        }

        .flex-grow-0 {
            flex-grow: 0 !important
        }

        .flex-grow-1 {
            flex-grow: 1 !important
        }

        .flex-shrink-0 {
            flex-shrink: 0 !important
        }

        .flex-shrink-1 {
            flex-shrink: 1 !important
        }

        .justify-content-start {
            justify-content: flex-start !important
        }

        .justify-content-end {
            justify-content: flex-end !important
        }

        .justify-content-center {
            justify-content: center !important
        }

        .justify-content-between {
            justify-content: space-between !important
        }

        .justify-content-around {
            justify-content: space-around !important
        }

        .align-items-start {
            align-items: flex-start !important
        }

        .align-items-end {
            align-items: flex-end !important
        }

        .align-items-center {
            align-items: center !important
        }

        .align-items-baseline {
            align-items: baseline !important
        }

        .align-items-stretch {
            align-items: stretch !important
        }

        .align-content-start {
            align-content: flex-start !important
        }

        .align-content-end {
            align-content: flex-end !important
        }

        .align-content-center {
            align-content: center !important
        }

        .align-content-between {
            align-content: space-between !important
        }

        .align-content-around {
            align-content: space-around !important
        }

        .align-content-stretch {
            align-content: stretch !important
        }

        .align-self-auto {
            align-self: auto !important
        }

        .align-self-start {
            align-self: flex-start !important
        }

        .align-self-end {
            align-self: flex-end !important
        }

        .align-self-center {
            align-self: center !important
        }

        .align-self-baseline {
            align-self: baseline !important
        }

        .align-self-stretch {
            align-self: stretch !important
        }

        @media (min-width:576px) {
            .flex-sm-row {
                flex-direction: row !important
            }

            .flex-sm-column {
                flex-direction: column !important
            }

            .flex-sm-row-reverse {
                flex-direction: row-reverse !important
            }

            .flex-sm-column-reverse {
                flex-direction: column-reverse !important
            }

            .flex-sm-wrap {
                flex-wrap: wrap !important
            }

            .flex-sm-nowrap {
                flex-wrap: nowrap !important
            }

            .flex-sm-wrap-reverse {
                flex-wrap: wrap-reverse !important
            }

            .flex-sm-fill {
                flex: 1 1 auto !important
            }

            .flex-sm-grow-0 {
                flex-grow: 0 !important
            }

            .flex-sm-grow-1 {
                flex-grow: 1 !important
            }

            .flex-sm-shrink-0 {
                flex-shrink: 0 !important
            }

            .flex-sm-shrink-1 {
                flex-shrink: 1 !important
            }

            .justify-content-sm-start {
                justify-content: flex-start !important
            }

            .justify-content-sm-end {
                justify-content: flex-end !important
            }

            .justify-content-sm-center {
                justify-content: center !important
            }

            .justify-content-sm-between {
                justify-content: space-between !important
            }

            .justify-content-sm-around {
                justify-content: space-around !important
            }

            .align-items-sm-start {
                align-items: flex-start !important
            }

            .align-items-sm-end {
                align-items: flex-end !important
            }

            .align-items-sm-center {
                align-items: center !important
            }

            .align-items-sm-baseline {
                align-items: baseline !important
            }

            .align-items-sm-stretch {
                align-items: stretch !important
            }

            .align-content-sm-start {
                align-content: flex-start !important
            }

            .align-content-sm-end {
                align-content: flex-end !important
            }

            .align-content-sm-center {
                align-content: center !important
            }

            .align-content-sm-between {
                align-content: space-between !important
            }

            .align-content-sm-around {
                align-content: space-around !important
            }

            .align-content-sm-stretch {
                align-content: stretch !important
            }

            .align-self-sm-auto {
                align-self: auto !important
            }

            .align-self-sm-start {
                align-self: flex-start !important
            }

            .align-self-sm-end {
                align-self: flex-end !important
            }

            .align-self-sm-center {
                align-self: center !important
            }

            .align-self-sm-baseline {
                align-self: baseline !important
            }

            .align-self-sm-stretch {
                align-self: stretch !important
            }
        }

        @media (min-width:768px) {
            .flex-md-row {
                flex-direction: row !important
            }

            .flex-md-column {
                flex-direction: column !important
            }

            .flex-md-row-reverse {
                flex-direction: row-reverse !important
            }

            .flex-md-column-reverse {
                flex-direction: column-reverse !important
            }

            .flex-md-wrap {
                flex-wrap: wrap !important
            }

            .flex-md-nowrap {
                flex-wrap: nowrap !important
            }

            .flex-md-wrap-reverse {
                flex-wrap: wrap-reverse !important
            }

            .flex-md-fill {
                flex: 1 1 auto !important
            }

            .flex-md-grow-0 {
                flex-grow: 0 !important
            }

            .flex-md-grow-1 {
                flex-grow: 1 !important
            }

            .flex-md-shrink-0 {
                flex-shrink: 0 !important
            }

            .flex-md-shrink-1 {
                flex-shrink: 1 !important
            }

            .justify-content-md-start {
                justify-content: flex-start !important
            }

            .justify-content-md-end {
                justify-content: flex-end !important
            }

            .justify-content-md-center {
                justify-content: center !important
            }

            .justify-content-md-between {
                justify-content: space-between !important
            }

            .justify-content-md-around {
                justify-content: space-around !important
            }

            .align-items-md-start {
                align-items: flex-start !important
            }

            .align-items-md-end {
                align-items: flex-end !important
            }

            .align-items-md-center {
                align-items: center !important
            }

            .align-items-md-baseline {
                align-items: baseline !important
            }

            .align-items-md-stretch {
                align-items: stretch !important
            }

            .align-content-md-start {
                align-content: flex-start !important
            }

            .align-content-md-end {
                align-content: flex-end !important
            }

            .align-content-md-center {
                align-content: center !important
            }

            .align-content-md-between {
                align-content: space-between !important
            }

            .align-content-md-around {
                align-content: space-around !important
            }

            .align-content-md-stretch {
                align-content: stretch !important
            }

            .align-self-md-auto {
                align-self: auto !important
            }

            .align-self-md-start {
                align-self: flex-start !important
            }

            .align-self-md-end {
                align-self: flex-end !important
            }

            .align-self-md-center {
                align-self: center !important
            }

            .align-self-md-baseline {
                align-self: baseline !important
            }

            .align-self-md-stretch {
                align-self: stretch !important
            }
        }

        @media (min-width:992px) {
            .flex-lg-row {
                flex-direction: row !important
            }

            .flex-lg-column {
                flex-direction: column !important
            }

            .flex-lg-row-reverse {
                flex-direction: row-reverse !important
            }

            .flex-lg-column-reverse {
                flex-direction: column-reverse !important
            }

            .flex-lg-wrap {
                flex-wrap: wrap !important
            }

            .flex-lg-nowrap {
                flex-wrap: nowrap !important
            }

            .flex-lg-wrap-reverse {
                flex-wrap: wrap-reverse !important
            }

            .flex-lg-fill {
                flex: 1 1 auto !important
            }

            .flex-lg-grow-0 {
                flex-grow: 0 !important
            }

            .flex-lg-grow-1 {
                flex-grow: 1 !important
            }

            .flex-lg-shrink-0 {
                flex-shrink: 0 !important
            }

            .flex-lg-shrink-1 {
                flex-shrink: 1 !important
            }

            .justify-content-lg-start {
                justify-content: flex-start !important
            }

            .justify-content-lg-end {
                justify-content: flex-end !important
            }

            .justify-content-lg-center {
                justify-content: center !important
            }

            .justify-content-lg-between {
                justify-content: space-between !important
            }

            .justify-content-lg-around {
                justify-content: space-around !important
            }

            .align-items-lg-start {
                align-items: flex-start !important
            }

            .align-items-lg-end {
                align-items: flex-end !important
            }

            .align-items-lg-center {
                align-items: center !important
            }

            .align-items-lg-baseline {
                align-items: baseline !important
            }

            .align-items-lg-stretch {
                align-items: stretch !important
            }

            .align-content-lg-start {
                align-content: flex-start !important
            }

            .align-content-lg-end {
                align-content: flex-end !important
            }

            .align-content-lg-center {
                align-content: center !important
            }

            .align-content-lg-between {
                align-content: space-between !important
            }

            .align-content-lg-around {
                align-content: space-around !important
            }

            .align-content-lg-stretch {
                align-content: stretch !important
            }

            .align-self-lg-auto {
                align-self: auto !important
            }

            .align-self-lg-start {
                align-self: flex-start !important
            }

            .align-self-lg-end {
                align-self: flex-end !important
            }

            .align-self-lg-center {
                align-self: center !important
            }

            .align-self-lg-baseline {
                align-self: baseline !important
            }

            .align-self-lg-stretch {
                align-self: stretch !important
            }
        }

        @media (min-width:1200px) {
            .flex-xl-row {
                flex-direction: row !important
            }

            .flex-xl-column {
                flex-direction: column !important
            }

            .flex-xl-row-reverse {
                flex-direction: row-reverse !important
            }

            .flex-xl-column-reverse {
                flex-direction: column-reverse !important
            }

            .flex-xl-wrap {
                flex-wrap: wrap !important
            }

            .flex-xl-nowrap {
                flex-wrap: nowrap !important
            }

            .flex-xl-wrap-reverse {
                flex-wrap: wrap-reverse !important
            }

            .flex-xl-fill {
                flex: 1 1 auto !important
            }

            .flex-xl-grow-0 {
                flex-grow: 0 !important
            }

            .flex-xl-grow-1 {
                flex-grow: 1 !important
            }

            .flex-xl-shrink-0 {
                flex-shrink: 0 !important
            }

            .flex-xl-shrink-1 {
                flex-shrink: 1 !important
            }

            .justify-content-xl-start {
                justify-content: flex-start !important
            }

            .justify-content-xl-end {
                justify-content: flex-end !important
            }

            .justify-content-xl-center {
                justify-content: center !important
            }

            .justify-content-xl-between {
                justify-content: space-between !important
            }

            .justify-content-xl-around {
                justify-content: space-around !important
            }

            .align-items-xl-start {
                align-items: flex-start !important
            }

            .align-items-xl-end {
                align-items: flex-end !important
            }

            .align-items-xl-center {
                align-items: center !important
            }

            .align-items-xl-baseline {
                align-items: baseline !important
            }

            .align-items-xl-stretch {
                align-items: stretch !important
            }

            .align-content-xl-start {
                align-content: flex-start !important
            }

            .align-content-xl-end {
                align-content: flex-end !important
            }

            .align-content-xl-center {
                align-content: center !important
            }

            .align-content-xl-between {
                align-content: space-between !important
            }

            .align-content-xl-around {
                align-content: space-around !important
            }

            .align-content-xl-stretch {
                align-content: stretch !important
            }

            .align-self-xl-auto {
                align-self: auto !important
            }

            .align-self-xl-start {
                align-self: flex-start !important
            }

            .align-self-xl-end {
                align-self: flex-end !important
            }

            .align-self-xl-center {
                align-self: center !important
            }

            .align-self-xl-baseline {
                align-self: baseline !important
            }

            .align-self-xl-stretch {
                align-self: stretch !important
            }
        }

        .float-left {
            float: left !important
        }

        .float-right {
            float: right !important
        }

        .float-none {
            float: none !important
        }

        @media (min-width:576px) {
            .float-sm-left {
                float: left !important
            }

            .float-sm-right {
                float: right !important
            }

            .float-sm-none {
                float: none !important
            }
        }

        @media (min-width:768px) {
            .float-md-left {
                float: left !important
            }

            .float-md-right {
                float: right !important
            }

            .float-md-none {
                float: none !important
            }
        }

        @media (min-width:992px) {
            .float-lg-left {
                float: left !important
            }

            .float-lg-right {
                float: right !important
            }

            .float-lg-none {
                float: none !important
            }
        }

        @media (min-width:1200px) {
            .float-xl-left {
                float: left !important
            }

            .float-xl-right {
                float: right !important
            }

            .float-xl-none {
                float: none !important
            }
        }

        .user-select-all {
            -webkit-user-select: all !important;
            -moz-user-select: all !important;
            -ms-user-select: all !important;
            user-select: all !important
        }

        .user-select-auto {
            -webkit-user-select: auto !important;
            -moz-user-select: auto !important;
            -ms-user-select: auto !important;
            user-select: auto !important
        }

        .user-select-none {
            -webkit-user-select: none !important;
            -moz-user-select: none !important;
            -ms-user-select: none !important;
            user-select: none !important
        }

        .overflow-auto {
            overflow: auto !important
        }

        .overflow-hidden {
            overflow: hidden !important
        }

        .position-static {
            position: static !important
        }

        .position-relative {
            position: relative !important
        }

        .position-absolute {
            position: absolute !important
        }

        .position-fixed {
            position: fixed !important
        }

        .position-sticky {
            position: sticky !important
        }

        .fixed-top {
            top: 0
        }

        .fixed-bottom,
        .fixed-top {
            position: fixed;
            right: 0;
            left: 0;
            z-index: 1030
        }

        .fixed-bottom {
            bottom: 0
        }

        @supports (position:sticky) {
            .sticky-top {
                position: sticky;
                top: 0;
                z-index: 1020
            }
        }

        .sr-only {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            white-space: nowrap;
            border: 0
        }

        .sr-only-focusable:active,
        .sr-only-focusable:focus {
            position: static;
            width: auto;
            height: auto;
            overflow: visible;
            clip: auto;
            white-space: normal
        }

        .shadow-sm {
            box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important
        }

        .shadow {
            box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
        }

        .shadow-lg {
            box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important
        }

        .shadow-none {
            box-shadow: none !important
        }

        .w-25 {
            width: 25% !important
        }

        .w-50 {
            width: 50% !important
        }

        .w-75 {
            width: 75% !important
        }

        .w-100 {
            width: 100% !important
        }

        .w-auto {
            width: auto !important
        }

        .h-25 {
            height: 25% !important
        }

        .h-50 {
            height: 50% !important
        }

        .h-75 {
            height: 75% !important
        }

        .h-100 {
            height: 100% !important
        }

        .h-auto {
            height: auto !important
        }

        .mw-100 {
            max-width: 100% !important
        }

        .mh-100 {
            max-height: 100% !important
        }

        .min-vw-100 {
            min-width: 100vw !important
        }

        .min-vh-100 {
            min-height: 100vh !important
        }

        .vw-100 {
            width: 100vw !important
        }

        .vh-100 {
            height: 100vh !important
        }

        .m-0 {
            margin: 0 !important
        }

        .mt-0,
        .my-0 {
            margin-top: 0 !important
        }

        .mr-0,
        .mx-0 {
            margin-right: 0 !important
        }

        .mb-0,
        .my-0 {
            margin-bottom: 0 !important
        }

        .ml-0,
        .mx-0 {
            margin-left: 0 !important
        }

        .m-1 {
            margin: .25rem !important
        }

        .mt-1,
        .my-1 {
            margin-top: .25rem !important
        }

        .mr-1,
        .mx-1 {
            margin-right: .25rem !important
        }

        .mb-1,
        .my-1 {
            margin-bottom: .25rem !important
        }

        .ml-1,
        .mx-1 {
            margin-left: .25rem !important
        }

        .m-2 {
            margin: .5rem !important
        }

        .mt-2,
        .my-2 {
            margin-top: .5rem !important
        }

        .mr-2,
        .mx-2 {
            margin-right: .5rem !important
        }

        .mb-2,
        .my-2 {
            margin-bottom: .5rem !important
        }

        .ml-2,
        .mx-2 {
            margin-left: .5rem !important
        }

        .m-3 {
            margin: 1rem !important
        }

        .mt-3,
        .my-3 {
            margin-top: 1rem !important
        }

        .mr-3,
        .mx-3 {
            margin-right: 1rem !important
        }

        .mb-3,
        .my-3 {
            margin-bottom: 1rem !important
        }

        .ml-3,
        .mx-3 {
            margin-left: 1rem !important
        }

        .m-4 {
            margin: 1.5rem !important
        }

        .mt-4,
        .my-4 {
            margin-top: 1.5rem !important
        }

        .mr-4,
        .mx-4 {
            margin-right: 1.5rem !important
        }

        .mb-4,
        .my-4 {
            margin-bottom: 1.5rem !important
        }

        .ml-4,
        .mx-4 {
            margin-left: 1.5rem !important
        }

        .m-5 {
            margin: 3rem !important
        }

        .mt-5,
        .my-5 {
            margin-top: 3rem !important
        }

        .mr-5,
        .mx-5 {
            margin-right: 3rem !important
        }

        .mb-5,
        .my-5 {
            margin-bottom: 3rem !important
        }

        .ml-5,
        .mx-5 {
            margin-left: 3rem !important
        }

        .p-0 {
            padding: 0 !important
        }

        .pt-0,
        .py-0 {
            padding-top: 0 !important
        }

        .pr-0,
        .px-0 {
            padding-right: 0 !important
        }

        .pb-0,
        .py-0 {
            padding-bottom: 0 !important
        }

        .pl-0,
        .px-0 {
            padding-left: 0 !important
        }

        .p-1 {
            padding: .25rem !important
        }

        .pt-1,
        .py-1 {
            padding-top: .25rem !important
        }

        .pr-1,
        .px-1 {
            padding-right: .25rem !important
        }

        .pb-1,
        .py-1 {
            padding-bottom: .25rem !important
        }

        .pl-1,
        .px-1 {
            padding-left: .25rem !important
        }

        .p-2 {
            padding: .5rem !important
        }

        .pt-2,
        .py-2 {
            padding-top: .5rem !important
        }

        .pr-2,
        .px-2 {
            padding-right: .5rem !important
        }

        .pb-2,
        .py-2 {
            padding-bottom: .5rem !important
        }

        .pl-2,
        .px-2 {
            padding-left: .5rem !important
        }

        .p-3 {
            padding: 1rem !important
        }

        .pt-3,
        .py-3 {
            padding-top: 1rem !important
        }

        .pr-3,
        .px-3 {
            padding-right: 1rem !important
        }

        .pb-3,
        .py-3 {
            padding-bottom: 1rem !important
        }

        .pl-3,
        .px-3 {
            padding-left: 1rem !important
        }

        .p-4 {
            padding: 1.5rem !important
        }

        .pt-4,
        .py-4 {
            padding-top: 1.5rem !important
        }

        .pr-4,
        .px-4 {
            padding-right: 1.5rem !important
        }

        .pb-4,
        .py-4 {
            padding-bottom: 1.5rem !important
        }

        .pl-4,
        .px-4 {
            padding-left: 1.5rem !important
        }

        .p-5 {
            padding: 3rem !important
        }

        .pt-5,
        .py-5 {
            padding-top: 3rem !important
        }

        .pr-5,
        .px-5 {
            padding-right: 3rem !important
        }

        .pb-5,
        .py-5 {
            padding-bottom: 3rem !important
        }

        .pl-5,
        .px-5 {
            padding-left: 3rem !important
        }

        .m-n1 {
            margin: -.25rem !important
        }

        .mt-n1,
        .my-n1 {
            margin-top: -.25rem !important
        }

        .mr-n1,
        .mx-n1 {
            margin-right: -.25rem !important
        }

        .mb-n1,
        .my-n1 {
            margin-bottom: -.25rem !important
        }

        .ml-n1,
        .mx-n1 {
            margin-left: -.25rem !important
        }

        .m-n2 {
            margin: -.5rem !important
        }

        .mt-n2,
        .my-n2 {
            margin-top: -.5rem !important
        }

        .mr-n2,
        .mx-n2 {
            margin-right: -.5rem !important
        }

        .mb-n2,
        .my-n2 {
            margin-bottom: -.5rem !important
        }

        .ml-n2,
        .mx-n2 {
            margin-left: -.5rem !important
        }

        .m-n3 {
            margin: -1rem !important
        }

        .mt-n3,
        .my-n3 {
            margin-top: -1rem !important
        }

        .mr-n3,
        .mx-n3 {
            margin-right: -1rem !important
        }

        .mb-n3,
        .my-n3 {
            margin-bottom: -1rem !important
        }

        .ml-n3,
        .mx-n3 {
            margin-left: -1rem !important
        }

        .m-n4 {
            margin: -1.5rem !important
        }

        .mt-n4,
        .my-n4 {
            margin-top: -1.5rem !important
        }

        .mr-n4,
        .mx-n4 {
            margin-right: -1.5rem !important
        }

        .mb-n4,
        .my-n4 {
            margin-bottom: -1.5rem !important
        }

        .ml-n4,
        .mx-n4 {
            margin-left: -1.5rem !important
        }

        .m-n5 {
            margin: -3rem !important
        }

        .mt-n5,
        .my-n5 {
            margin-top: -3rem !important
        }

        .mr-n5,
        .mx-n5 {
            margin-right: -3rem !important
        }

        .mb-n5,
        .my-n5 {
            margin-bottom: -3rem !important
        }

        .ml-n5,
        .mx-n5 {
            margin-left: -3rem !important
        }

        .m-auto {
            margin: auto !important
        }

        .mt-auto,
        .my-auto {
            margin-top: auto !important
        }

        .mr-auto,
        .mx-auto {
            margin-right: auto !important
        }

        .mb-auto,
        .my-auto {
            margin-bottom: auto !important
        }

        .ml-auto,
        .mx-auto {
            margin-left: auto !important
        }

        @media (min-width:576px) {
            .m-sm-0 {
                margin: 0 !important
            }

            .mt-sm-0,
            .my-sm-0 {
                margin-top: 0 !important
            }

            .mr-sm-0,
            .mx-sm-0 {
                margin-right: 0 !important
            }

            .mb-sm-0,
            .my-sm-0 {
                margin-bottom: 0 !important
            }

            .ml-sm-0,
            .mx-sm-0 {
                margin-left: 0 !important
            }

            .m-sm-1 {
                margin: .25rem !important
            }

            .mt-sm-1,
            .my-sm-1 {
                margin-top: .25rem !important
            }

            .mr-sm-1,
            .mx-sm-1 {
                margin-right: .25rem !important
            }

            .mb-sm-1,
            .my-sm-1 {
                margin-bottom: .25rem !important
            }

            .ml-sm-1,
            .mx-sm-1 {
                margin-left: .25rem !important
            }

            .m-sm-2 {
                margin: .5rem !important
            }

            .mt-sm-2,
            .my-sm-2 {
                margin-top: .5rem !important
            }

            .mr-sm-2,
            .mx-sm-2 {
                margin-right: .5rem !important
            }

            .mb-sm-2,
            .my-sm-2 {
                margin-bottom: .5rem !important
            }

            .ml-sm-2,
            .mx-sm-2 {
                margin-left: .5rem !important
            }

            .m-sm-3 {
                margin: 1rem !important
            }

            .mt-sm-3,
            .my-sm-3 {
                margin-top: 1rem !important
            }

            .mr-sm-3,
            .mx-sm-3 {
                margin-right: 1rem !important
            }

            .mb-sm-3,
            .my-sm-3 {
                margin-bottom: 1rem !important
            }

            .ml-sm-3,
            .mx-sm-3 {
                margin-left: 1rem !important
            }

            .m-sm-4 {
                margin: 1.5rem !important
            }

            .mt-sm-4,
            .my-sm-4 {
                margin-top: 1.5rem !important
            }

            .mr-sm-4,
            .mx-sm-4 {
                margin-right: 1.5rem !important
            }

            .mb-sm-4,
            .my-sm-4 {
                margin-bottom: 1.5rem !important
            }

            .ml-sm-4,
            .mx-sm-4 {
                margin-left: 1.5rem !important
            }

            .m-sm-5 {
                margin: 3rem !important
            }

            .mt-sm-5,
            .my-sm-5 {
                margin-top: 3rem !important
            }

            .mr-sm-5,
            .mx-sm-5 {
                margin-right: 3rem !important
            }

            .mb-sm-5,
            .my-sm-5 {
                margin-bottom: 3rem !important
            }

            .ml-sm-5,
            .mx-sm-5 {
                margin-left: 3rem !important
            }

            .p-sm-0 {
                padding: 0 !important
            }

            .pt-sm-0,
            .py-sm-0 {
                padding-top: 0 !important
            }

            .pr-sm-0,
            .px-sm-0 {
                padding-right: 0 !important
            }

            .pb-sm-0,
            .py-sm-0 {
                padding-bottom: 0 !important
            }

            .pl-sm-0,
            .px-sm-0 {
                padding-left: 0 !important
            }

            .p-sm-1 {
                padding: .25rem !important
            }

            .pt-sm-1,
            .py-sm-1 {
                padding-top: .25rem !important
            }

            .pr-sm-1,
            .px-sm-1 {
                padding-right: .25rem !important
            }

            .pb-sm-1,
            .py-sm-1 {
                padding-bottom: .25rem !important
            }

            .pl-sm-1,
            .px-sm-1 {
                padding-left: .25rem !important
            }

            .p-sm-2 {
                padding: .5rem !important
            }

            .pt-sm-2,
            .py-sm-2 {
                padding-top: .5rem !important
            }

            .pr-sm-2,
            .px-sm-2 {
                padding-right: .5rem !important
            }

            .pb-sm-2,
            .py-sm-2 {
                padding-bottom: .5rem !important
            }

            .pl-sm-2,
            .px-sm-2 {
                padding-left: .5rem !important
            }

            .p-sm-3 {
                padding: 1rem !important
            }

            .pt-sm-3,
            .py-sm-3 {
                padding-top: 1rem !important
            }

            .pr-sm-3,
            .px-sm-3 {
                padding-right: 1rem !important
            }

            .pb-sm-3,
            .py-sm-3 {
                padding-bottom: 1rem !important
            }

            .pl-sm-3,
            .px-sm-3 {
                padding-left: 1rem !important
            }

            .p-sm-4 {
                padding: 1.5rem !important
            }

            .pt-sm-4,
            .py-sm-4 {
                padding-top: 1.5rem !important
            }

            .pr-sm-4,
            .px-sm-4 {
                padding-right: 1.5rem !important
            }

            .pb-sm-4,
            .py-sm-4 {
                padding-bottom: 1.5rem !important
            }

            .pl-sm-4,
            .px-sm-4 {
                padding-left: 1.5rem !important
            }

            .p-sm-5 {
                padding: 3rem !important
            }

            .pt-sm-5,
            .py-sm-5 {
                padding-top: 3rem !important
            }

            .pr-sm-5,
            .px-sm-5 {
                padding-right: 3rem !important
            }

            .pb-sm-5,
            .py-sm-5 {
                padding-bottom: 3rem !important
            }

            .pl-sm-5,
            .px-sm-5 {
                padding-left: 3rem !important
            }

            .m-sm-n1 {
                margin: -.25rem !important
            }

            .mt-sm-n1,
            .my-sm-n1 {
                margin-top: -.25rem !important
            }

            .mr-sm-n1,
            .mx-sm-n1 {
                margin-right: -.25rem !important
            }

            .mb-sm-n1,
            .my-sm-n1 {
                margin-bottom: -.25rem !important
            }

            .ml-sm-n1,
            .mx-sm-n1 {
                margin-left: -.25rem !important
            }

            .m-sm-n2 {
                margin: -.5rem !important
            }

            .mt-sm-n2,
            .my-sm-n2 {
                margin-top: -.5rem !important
            }

            .mr-sm-n2,
            .mx-sm-n2 {
                margin-right: -.5rem !important
            }

            .mb-sm-n2,
            .my-sm-n2 {
                margin-bottom: -.5rem !important
            }

            .ml-sm-n2,
            .mx-sm-n2 {
                margin-left: -.5rem !important
            }

            .m-sm-n3 {
                margin: -1rem !important
            }

            .mt-sm-n3,
            .my-sm-n3 {
                margin-top: -1rem !important
            }

            .mr-sm-n3,
            .mx-sm-n3 {
                margin-right: -1rem !important
            }

            .mb-sm-n3,
            .my-sm-n3 {
                margin-bottom: -1rem !important
            }

            .ml-sm-n3,
            .mx-sm-n3 {
                margin-left: -1rem !important
            }

            .m-sm-n4 {
                margin: -1.5rem !important
            }

            .mt-sm-n4,
            .my-sm-n4 {
                margin-top: -1.5rem !important
            }

            .mr-sm-n4,
            .mx-sm-n4 {
                margin-right: -1.5rem !important
            }

            .mb-sm-n4,
            .my-sm-n4 {
                margin-bottom: -1.5rem !important
            }

            .ml-sm-n4,
            .mx-sm-n4 {
                margin-left: -1.5rem !important
            }

            .m-sm-n5 {
                margin: -3rem !important
            }

            .mt-sm-n5,
            .my-sm-n5 {
                margin-top: -3rem !important
            }

            .mr-sm-n5,
            .mx-sm-n5 {
                margin-right: -3rem !important
            }

            .mb-sm-n5,
            .my-sm-n5 {
                margin-bottom: -3rem !important
            }

            .ml-sm-n5,
            .mx-sm-n5 {
                margin-left: -3rem !important
            }

            .m-sm-auto {
                margin: auto !important
            }

            .mt-sm-auto,
            .my-sm-auto {
                margin-top: auto !important
            }

            .mr-sm-auto,
            .mx-sm-auto {
                margin-right: auto !important
            }

            .mb-sm-auto,
            .my-sm-auto {
                margin-bottom: auto !important
            }

            .ml-sm-auto,
            .mx-sm-auto {
                margin-left: auto !important
            }
        }

        @media (min-width:768px) {
            .m-md-0 {
                margin: 0 !important
            }

            .mt-md-0,
            .my-md-0 {
                margin-top: 0 !important
            }

            .mr-md-0,
            .mx-md-0 {
                margin-right: 0 !important
            }

            .mb-md-0,
            .my-md-0 {
                margin-bottom: 0 !important
            }

            .ml-md-0,
            .mx-md-0 {
                margin-left: 0 !important
            }

            .m-md-1 {
                margin: .25rem !important
            }

            .mt-md-1,
            .my-md-1 {
                margin-top: .25rem !important
            }

            .mr-md-1,
            .mx-md-1 {
                margin-right: .25rem !important
            }

            .mb-md-1,
            .my-md-1 {
                margin-bottom: .25rem !important
            }

            .ml-md-1,
            .mx-md-1 {
                margin-left: .25rem !important
            }

            .m-md-2 {
                margin: .5rem !important
            }

            .mt-md-2,
            .my-md-2 {
                margin-top: .5rem !important
            }

            .mr-md-2,
            .mx-md-2 {
                margin-right: .5rem !important
            }

            .mb-md-2,
            .my-md-2 {
                margin-bottom: .5rem !important
            }

            .ml-md-2,
            .mx-md-2 {
                margin-left: .5rem !important
            }

            .m-md-3 {
                margin: 1rem !important
            }

            .mt-md-3,
            .my-md-3 {
                margin-top: 1rem !important
            }

            .mr-md-3,
            .mx-md-3 {
                margin-right: 1rem !important
            }

            .mb-md-3,
            .my-md-3 {
                margin-bottom: 1rem !important
            }

            .ml-md-3,
            .mx-md-3 {
                margin-left: 1rem !important
            }

            .m-md-4 {
                margin: 1.5rem !important
            }

            .mt-md-4,
            .my-md-4 {
                margin-top: 1.5rem !important
            }

            .mr-md-4,
            .mx-md-4 {
                margin-right: 1.5rem !important
            }

            .mb-md-4,
            .my-md-4 {
                margin-bottom: 1.5rem !important
            }

            .ml-md-4,
            .mx-md-4 {
                margin-left: 1.5rem !important
            }

            .m-md-5 {
                margin: 3rem !important
            }

            .mt-md-5,
            .my-md-5 {
                margin-top: 3rem !important
            }

            .mr-md-5,
            .mx-md-5 {
                margin-right: 3rem !important
            }

            .mb-md-5,
            .my-md-5 {
                margin-bottom: 3rem !important
            }

            .ml-md-5,
            .mx-md-5 {
                margin-left: 3rem !important
            }

            .p-md-0 {
                padding: 0 !important
            }

            .pt-md-0,
            .py-md-0 {
                padding-top: 0 !important
            }

            .pr-md-0,
            .px-md-0 {
                padding-right: 0 !important
            }

            .pb-md-0,
            .py-md-0 {
                padding-bottom: 0 !important
            }

            .pl-md-0,
            .px-md-0 {
                padding-left: 0 !important
            }

            .p-md-1 {
                padding: .25rem !important
            }

            .pt-md-1,
            .py-md-1 {
                padding-top: .25rem !important
            }

            .pr-md-1,
            .px-md-1 {
                padding-right: .25rem !important
            }

            .pb-md-1,
            .py-md-1 {
                padding-bottom: .25rem !important
            }

            .pl-md-1,
            .px-md-1 {
                padding-left: .25rem !important
            }

            .p-md-2 {
                padding: .5rem !important
            }

            .pt-md-2,
            .py-md-2 {
                padding-top: .5rem !important
            }

            .pr-md-2,
            .px-md-2 {
                padding-right: .5rem !important
            }

            .pb-md-2,
            .py-md-2 {
                padding-bottom: .5rem !important
            }

            .pl-md-2,
            .px-md-2 {
                padding-left: .5rem !important
            }

            .p-md-3 {
                padding: 1rem !important
            }

            .pt-md-3,
            .py-md-3 {
                padding-top: 1rem !important
            }

            .pr-md-3,
            .px-md-3 {
                padding-right: 1rem !important
            }

            .pb-md-3,
            .py-md-3 {
                padding-bottom: 1rem !important
            }

            .pl-md-3,
            .px-md-3 {
                padding-left: 1rem !important
            }

            .p-md-4 {
                padding: 1.5rem !important
            }

            .pt-md-4,
            .py-md-4 {
                padding-top: 1.5rem !important
            }

            .pr-md-4,
            .px-md-4 {
                padding-right: 1.5rem !important
            }

            .pb-md-4,
            .py-md-4 {
                padding-bottom: 1.5rem !important
            }

            .pl-md-4,
            .px-md-4 {
                padding-left: 1.5rem !important
            }

            .p-md-5 {
                padding: 3rem !important
            }

            .pt-md-5,
            .py-md-5 {
                padding-top: 3rem !important
            }

            .pr-md-5,
            .px-md-5 {
                padding-right: 3rem !important
            }

            .pb-md-5,
            .py-md-5 {
                padding-bottom: 3rem !important
            }

            .pl-md-5,
            .px-md-5 {
                padding-left: 3rem !important
            }

            .m-md-n1 {
                margin: -.25rem !important
            }

            .mt-md-n1,
            .my-md-n1 {
                margin-top: -.25rem !important
            }

            .mr-md-n1,
            .mx-md-n1 {
                margin-right: -.25rem !important
            }

            .mb-md-n1,
            .my-md-n1 {
                margin-bottom: -.25rem !important
            }

            .ml-md-n1,
            .mx-md-n1 {
                margin-left: -.25rem !important
            }

            .m-md-n2 {
                margin: -.5rem !important
            }

            .mt-md-n2,
            .my-md-n2 {
                margin-top: -.5rem !important
            }

            .mr-md-n2,
            .mx-md-n2 {
                margin-right: -.5rem !important
            }

            .mb-md-n2,
            .my-md-n2 {
                margin-bottom: -.5rem !important
            }

            .ml-md-n2,
            .mx-md-n2 {
                margin-left: -.5rem !important
            }

            .m-md-n3 {
                margin: -1rem !important
            }

            .mt-md-n3,
            .my-md-n3 {
                margin-top: -1rem !important
            }

            .mr-md-n3,
            .mx-md-n3 {
                margin-right: -1rem !important
            }

            .mb-md-n3,
            .my-md-n3 {
                margin-bottom: -1rem !important
            }

            .ml-md-n3,
            .mx-md-n3 {
                margin-left: -1rem !important
            }

            .m-md-n4 {
                margin: -1.5rem !important
            }

            .mt-md-n4,
            .my-md-n4 {
                margin-top: -1.5rem !important
            }

            .mr-md-n4,
            .mx-md-n4 {
                margin-right: -1.5rem !important
            }

            .mb-md-n4,
            .my-md-n4 {
                margin-bottom: -1.5rem !important
            }

            .ml-md-n4,
            .mx-md-n4 {
                margin-left: -1.5rem !important
            }

            .m-md-n5 {
                margin: -3rem !important
            }

            .mt-md-n5,
            .my-md-n5 {
                margin-top: -3rem !important
            }

            .mr-md-n5,
            .mx-md-n5 {
                margin-right: -3rem !important
            }

            .mb-md-n5,
            .my-md-n5 {
                margin-bottom: -3rem !important
            }

            .ml-md-n5,
            .mx-md-n5 {
                margin-left: -3rem !important
            }

            .m-md-auto {
                margin: auto !important
            }

            .mt-md-auto,
            .my-md-auto {
                margin-top: auto !important
            }

            .mr-md-auto,
            .mx-md-auto {
                margin-right: auto !important
            }

            .mb-md-auto,
            .my-md-auto {
                margin-bottom: auto !important
            }

            .ml-md-auto,
            .mx-md-auto {
                margin-left: auto !important
            }
        }

        @media (min-width:992px) {
            .m-lg-0 {
                margin: 0 !important
            }

            .mt-lg-0,
            .my-lg-0 {
                margin-top: 0 !important
            }

            .mr-lg-0,
            .mx-lg-0 {
                margin-right: 0 !important
            }

            .mb-lg-0,
            .my-lg-0 {
                margin-bottom: 0 !important
            }

            .ml-lg-0,
            .mx-lg-0 {
                margin-left: 0 !important
            }

            .m-lg-1 {
                margin: .25rem !important
            }

            .mt-lg-1,
            .my-lg-1 {
                margin-top: .25rem !important
            }

            .mr-lg-1,
            .mx-lg-1 {
                margin-right: .25rem !important
            }

            .mb-lg-1,
            .my-lg-1 {
                margin-bottom: .25rem !important
            }

            .ml-lg-1,
            .mx-lg-1 {
                margin-left: .25rem !important
            }

            .m-lg-2 {
                margin: .5rem !important
            }

            .mt-lg-2,
            .my-lg-2 {
                margin-top: .5rem !important
            }

            .mr-lg-2,
            .mx-lg-2 {
                margin-right: .5rem !important
            }

            .mb-lg-2,
            .my-lg-2 {
                margin-bottom: .5rem !important
            }

            .ml-lg-2,
            .mx-lg-2 {
                margin-left: .5rem !important
            }

            .m-lg-3 {
                margin: 1rem !important
            }

            .mt-lg-3,
            .my-lg-3 {
                margin-top: 1rem !important
            }

            .mr-lg-3,
            .mx-lg-3 {
                margin-right: 1rem !important
            }

            .mb-lg-3,
            .my-lg-3 {
                margin-bottom: 1rem !important
            }

            .ml-lg-3,
            .mx-lg-3 {
                margin-left: 1rem !important
            }

            .m-lg-4 {
                margin: 1.5rem !important
            }

            .mt-lg-4,
            .my-lg-4 {
                margin-top: 1.5rem !important
            }

            .mr-lg-4,
            .mx-lg-4 {
                margin-right: 1.5rem !important
            }

            .mb-lg-4,
            .my-lg-4 {
                margin-bottom: 1.5rem !important
            }

            .ml-lg-4,
            .mx-lg-4 {
                margin-left: 1.5rem !important
            }

            .m-lg-5 {
                margin: 3rem !important
            }

            .mt-lg-5,
            .my-lg-5 {
                margin-top: 3rem !important
            }

            .mr-lg-5,
            .mx-lg-5 {
                margin-right: 3rem !important
            }

            .mb-lg-5,
            .my-lg-5 {
                margin-bottom: 3rem !important
            }

            .ml-lg-5,
            .mx-lg-5 {
                margin-left: 3rem !important
            }

            .p-lg-0 {
                padding: 0 !important
            }

            .pt-lg-0,
            .py-lg-0 {
                padding-top: 0 !important
            }

            .pr-lg-0,
            .px-lg-0 {
                padding-right: 0 !important
            }

            .pb-lg-0,
            .py-lg-0 {
                padding-bottom: 0 !important
            }

            .pl-lg-0,
            .px-lg-0 {
                padding-left: 0 !important
            }

            .p-lg-1 {
                padding: .25rem !important
            }

            .pt-lg-1,
            .py-lg-1 {
                padding-top: .25rem !important
            }

            .pr-lg-1,
            .px-lg-1 {
                padding-right: .25rem !important
            }

            .pb-lg-1,
            .py-lg-1 {
                padding-bottom: .25rem !important
            }

            .pl-lg-1,
            .px-lg-1 {
                padding-left: .25rem !important
            }

            .p-lg-2 {
                padding: .5rem !important
            }

            .pt-lg-2,
            .py-lg-2 {
                padding-top: .5rem !important
            }

            .pr-lg-2,
            .px-lg-2 {
                padding-right: .5rem !important
            }

            .pb-lg-2,
            .py-lg-2 {
                padding-bottom: .5rem !important
            }

            .pl-lg-2,
            .px-lg-2 {
                padding-left: .5rem !important
            }

            .p-lg-3 {
                padding: 1rem !important
            }

            .pt-lg-3,
            .py-lg-3 {
                padding-top: 1rem !important
            }

            .pr-lg-3,
            .px-lg-3 {
                padding-right: 1rem !important
            }

            .pb-lg-3,
            .py-lg-3 {
                padding-bottom: 1rem !important
            }

            .pl-lg-3,
            .px-lg-3 {
                padding-left: 1rem !important
            }

            .p-lg-4 {
                padding: 1.5rem !important
            }

            .pt-lg-4,
            .py-lg-4 {
                padding-top: 1.5rem !important
            }

            .pr-lg-4,
            .px-lg-4 {
                padding-right: 1.5rem !important
            }

            .pb-lg-4,
            .py-lg-4 {
                padding-bottom: 1.5rem !important
            }

            .pl-lg-4,
            .px-lg-4 {
                padding-left: 1.5rem !important
            }

            .p-lg-5 {
                padding: 3rem !important
            }

            .pt-lg-5,
            .py-lg-5 {
                padding-top: 3rem !important
            }

            .pr-lg-5,
            .px-lg-5 {
                padding-right: 3rem !important
            }

            .pb-lg-5,
            .py-lg-5 {
                padding-bottom: 3rem !important
            }

            .pl-lg-5,
            .px-lg-5 {
                padding-left: 3rem !important
            }

            .m-lg-n1 {
                margin: -.25rem !important
            }

            .mt-lg-n1,
            .my-lg-n1 {
                margin-top: -.25rem !important
            }

            .mr-lg-n1,
            .mx-lg-n1 {
                margin-right: -.25rem !important
            }

            .mb-lg-n1,
            .my-lg-n1 {
                margin-bottom: -.25rem !important
            }

            .ml-lg-n1,
            .mx-lg-n1 {
                margin-left: -.25rem !important
            }

            .m-lg-n2 {
                margin: -.5rem !important
            }

            .mt-lg-n2,
            .my-lg-n2 {
                margin-top: -.5rem !important
            }

            .mr-lg-n2,
            .mx-lg-n2 {
                margin-right: -.5rem !important
            }

            .mb-lg-n2,
            .my-lg-n2 {
                margin-bottom: -.5rem !important
            }

            .ml-lg-n2,
            .mx-lg-n2 {
                margin-left: -.5rem !important
            }

            .m-lg-n3 {
                margin: -1rem !important
            }

            .mt-lg-n3,
            .my-lg-n3 {
                margin-top: -1rem !important
            }

            .mr-lg-n3,
            .mx-lg-n3 {
                margin-right: -1rem !important
            }

            .mb-lg-n3,
            .my-lg-n3 {
                margin-bottom: -1rem !important
            }

            .ml-lg-n3,
            .mx-lg-n3 {
                margin-left: -1rem !important
            }

            .m-lg-n4 {
                margin: -1.5rem !important
            }

            .mt-lg-n4,
            .my-lg-n4 {
                margin-top: -1.5rem !important
            }

            .mr-lg-n4,
            .mx-lg-n4 {
                margin-right: -1.5rem !important
            }

            .mb-lg-n4,
            .my-lg-n4 {
                margin-bottom: -1.5rem !important
            }

            .ml-lg-n4,
            .mx-lg-n4 {
                margin-left: -1.5rem !important
            }

            .m-lg-n5 {
                margin: -3rem !important
            }

            .mt-lg-n5,
            .my-lg-n5 {
                margin-top: -3rem !important
            }

            .mr-lg-n5,
            .mx-lg-n5 {
                margin-right: -3rem !important
            }

            .mb-lg-n5,
            .my-lg-n5 {
                margin-bottom: -3rem !important
            }

            .ml-lg-n5,
            .mx-lg-n5 {
                margin-left: -3rem !important
            }

            .m-lg-auto {
                margin: auto !important
            }

            .mt-lg-auto,
            .my-lg-auto {
                margin-top: auto !important
            }

            .mr-lg-auto,
            .mx-lg-auto {
                margin-right: auto !important
            }

            .mb-lg-auto,
            .my-lg-auto {
                margin-bottom: auto !important
            }

            .ml-lg-auto,
            .mx-lg-auto {
                margin-left: auto !important
            }
        }

        @media (min-width:1200px) {
            .m-xl-0 {
                margin: 0 !important
            }

            .mt-xl-0,
            .my-xl-0 {
                margin-top: 0 !important
            }

            .mr-xl-0,
            .mx-xl-0 {
                margin-right: 0 !important
            }

            .mb-xl-0,
            .my-xl-0 {
                margin-bottom: 0 !important
            }

            .ml-xl-0,
            .mx-xl-0 {
                margin-left: 0 !important
            }

            .m-xl-1 {
                margin: .25rem !important
            }

            .mt-xl-1,
            .my-xl-1 {
                margin-top: .25rem !important
            }

            .mr-xl-1,
            .mx-xl-1 {
                margin-right: .25rem !important
            }

            .mb-xl-1,
            .my-xl-1 {
                margin-bottom: .25rem !important
            }

            .ml-xl-1,
            .mx-xl-1 {
                margin-left: .25rem !important
            }

            .m-xl-2 {
                margin: .5rem !important
            }

            .mt-xl-2,
            .my-xl-2 {
                margin-top: .5rem !important
            }

            .mr-xl-2,
            .mx-xl-2 {
                margin-right: .5rem !important
            }

            .mb-xl-2,
            .my-xl-2 {
                margin-bottom: .5rem !important
            }

            .ml-xl-2,
            .mx-xl-2 {
                margin-left: .5rem !important
            }

            .m-xl-3 {
                margin: 1rem !important
            }

            .mt-xl-3,
            .my-xl-3 {
                margin-top: 1rem !important
            }

            .mr-xl-3,
            .mx-xl-3 {
                margin-right: 1rem !important
            }

            .mb-xl-3,
            .my-xl-3 {
                margin-bottom: 1rem !important
            }

            .ml-xl-3,
            .mx-xl-3 {
                margin-left: 1rem !important
            }

            .m-xl-4 {
                margin: 1.5rem !important
            }

            .mt-xl-4,
            .my-xl-4 {
                margin-top: 1.5rem !important
            }

            .mr-xl-4,
            .mx-xl-4 {
                margin-right: 1.5rem !important
            }

            .mb-xl-4,
            .my-xl-4 {
                margin-bottom: 1.5rem !important
            }

            .ml-xl-4,
            .mx-xl-4 {
                margin-left: 1.5rem !important
            }

            .m-xl-5 {
                margin: 3rem !important
            }

            .mt-xl-5,
            .my-xl-5 {
                margin-top: 3rem !important
            }

            .mr-xl-5,
            .mx-xl-5 {
                margin-right: 3rem !important
            }

            .mb-xl-5,
            .my-xl-5 {
                margin-bottom: 3rem !important
            }

            .ml-xl-5,
            .mx-xl-5 {
                margin-left: 3rem !important
            }

            .p-xl-0 {
                padding: 0 !important
            }

            .pt-xl-0,
            .py-xl-0 {
                padding-top: 0 !important
            }

            .pr-xl-0,
            .px-xl-0 {
                padding-right: 0 !important
            }

            .pb-xl-0,
            .py-xl-0 {
                padding-bottom: 0 !important
            }

            .pl-xl-0,
            .px-xl-0 {
                padding-left: 0 !important
            }

            .p-xl-1 {
                padding: .25rem !important
            }

            .pt-xl-1,
            .py-xl-1 {
                padding-top: .25rem !important
            }

            .pr-xl-1,
            .px-xl-1 {
                padding-right: .25rem !important
            }

            .pb-xl-1,
            .py-xl-1 {
                padding-bottom: .25rem !important
            }

            .pl-xl-1,
            .px-xl-1 {
                padding-left: .25rem !important
            }

            .p-xl-2 {
                padding: .5rem !important
            }

            .pt-xl-2,
            .py-xl-2 {
                padding-top: .5rem !important
            }

            .pr-xl-2,
            .px-xl-2 {
                padding-right: .5rem !important
            }

            .pb-xl-2,
            .py-xl-2 {
                padding-bottom: .5rem !important
            }

            .pl-xl-2,
            .px-xl-2 {
                padding-left: .5rem !important
            }

            .p-xl-3 {
                padding: 1rem !important
            }

            .pt-xl-3,
            .py-xl-3 {
                padding-top: 1rem !important
            }

            .pr-xl-3,
            .px-xl-3 {
                padding-right: 1rem !important
            }

            .pb-xl-3,
            .py-xl-3 {
                padding-bottom: 1rem !important
            }

            .pl-xl-3,
            .px-xl-3 {
                padding-left: 1rem !important
            }

            .p-xl-4 {
                padding: 1.5rem !important
            }

            .pt-xl-4,
            .py-xl-4 {
                padding-top: 1.5rem !important
            }

            .pr-xl-4,
            .px-xl-4 {
                padding-right: 1.5rem !important
            }

            .pb-xl-4,
            .py-xl-4 {
                padding-bottom: 1.5rem !important
            }

            .pl-xl-4,
            .px-xl-4 {
                padding-left: 1.5rem !important
            }

            .p-xl-5 {
                padding: 3rem !important
            }

            .pt-xl-5,
            .py-xl-5 {
                padding-top: 3rem !important
            }

            .pr-xl-5,
            .px-xl-5 {
                padding-right: 3rem !important
            }

            .pb-xl-5,
            .py-xl-5 {
                padding-bottom: 3rem !important
            }

            .pl-xl-5,
            .px-xl-5 {
                padding-left: 3rem !important
            }

            .m-xl-n1 {
                margin: -.25rem !important
            }

            .mt-xl-n1,
            .my-xl-n1 {
                margin-top: -.25rem !important
            }

            .mr-xl-n1,
            .mx-xl-n1 {
                margin-right: -.25rem !important
            }

            .mb-xl-n1,
            .my-xl-n1 {
                margin-bottom: -.25rem !important
            }

            .ml-xl-n1,
            .mx-xl-n1 {
                margin-left: -.25rem !important
            }

            .m-xl-n2 {
                margin: -.5rem !important
            }

            .mt-xl-n2,
            .my-xl-n2 {
                margin-top: -.5rem !important
            }

            .mr-xl-n2,
            .mx-xl-n2 {
                margin-right: -.5rem !important
            }

            .mb-xl-n2,
            .my-xl-n2 {
                margin-bottom: -.5rem !important
            }

            .ml-xl-n2,
            .mx-xl-n2 {
                margin-left: -.5rem !important
            }

            .m-xl-n3 {
                margin: -1rem !important
            }

            .mt-xl-n3,
            .my-xl-n3 {
                margin-top: -1rem !important
            }

            .mr-xl-n3,
            .mx-xl-n3 {
                margin-right: -1rem !important
            }

            .mb-xl-n3,
            .my-xl-n3 {
                margin-bottom: -1rem !important
            }

            .ml-xl-n3,
            .mx-xl-n3 {
                margin-left: -1rem !important
            }

            .m-xl-n4 {
                margin: -1.5rem !important
            }

            .mt-xl-n4,
            .my-xl-n4 {
                margin-top: -1.5rem !important
            }

            .mr-xl-n4,
            .mx-xl-n4 {
                margin-right: -1.5rem !important
            }

            .mb-xl-n4,
            .my-xl-n4 {
                margin-bottom: -1.5rem !important
            }

            .ml-xl-n4,
            .mx-xl-n4 {
                margin-left: -1.5rem !important
            }

            .m-xl-n5 {
                margin: -3rem !important
            }

            .mt-xl-n5,
            .my-xl-n5 {
                margin-top: -3rem !important
            }

            .mr-xl-n5,
            .mx-xl-n5 {
                margin-right: -3rem !important
            }

            .mb-xl-n5,
            .my-xl-n5 {
                margin-bottom: -3rem !important
            }

            .ml-xl-n5,
            .mx-xl-n5 {
                margin-left: -3rem !important
            }

            .m-xl-auto {
                margin: auto !important
            }

            .mt-xl-auto,
            .my-xl-auto {
                margin-top: auto !important
            }

            .mr-xl-auto,
            .mx-xl-auto {
                margin-right: auto !important
            }

            .mb-xl-auto,
            .my-xl-auto {
                margin-bottom: auto !important
            }

            .ml-xl-auto,
            .mx-xl-auto {
                margin-left: auto !important
            }
        }

        .stretched-link:after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            pointer-events: auto;
            content: "";
            background-color: transparent
        }

        .text-monospace {
            font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important
        }

        .text-justify {
            text-align: justify !important
        }

        .text-wrap {
            white-space: normal !important
        }

        .text-nowrap {
            white-space: nowrap !important
        }

        .text-truncate {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap
        }

        .text-left {
            text-align: left !important
        }

        .text-right {
            text-align: right !important
        }

        .text-center {
            text-align: center !important
        }

        @media (min-width:576px) {
            .text-sm-left {
                text-align: left !important
            }

            .text-sm-right {
                text-align: right !important
            }

            .text-sm-center {
                text-align: center !important
            }
        }

        @media (min-width:768px) {
            .text-md-left {
                text-align: left !important
            }

            .text-md-right {
                text-align: right !important
            }

            .text-md-center {
                text-align: center !important
            }
        }

        @media (min-width:992px) {
            .text-lg-left {
                text-align: left !important
            }

            .text-lg-right {
                text-align: right !important
            }

            .text-lg-center {
                text-align: center !important
            }
        }

        @media (min-width:1200px) {
            .text-xl-left {
                text-align: left !important
            }

            .text-xl-right {
                text-align: right !important
            }

            .text-xl-center {
                text-align: center !important
            }
        }

        .text-lowercase {
            text-transform: lowercase !important
        }

        .text-uppercase {
            text-transform: uppercase !important
        }

        .text-capitalize {
            text-transform: capitalize !important
        }

        .font-weight-light {
            font-weight: 300 !important
        }

        .font-weight-lighter {
            font-weight: lighter !important
        }

        .font-weight-normal {
            font-weight: 400 !important
        }

        .font-weight-bold {
            font-weight: 700 !important
        }

        .font-weight-bolder {
            font-weight: bolder !important
        }

        .font-italic {
            font-style: italic !important
        }

        .text-white {
            color: #fff !important
        }

        .text-primary {
            color: #007bff !important
        }

        a.text-primary:focus,
        a.text-primary:hover {
            color: #0056b3 !important
        }

        .text-secondary {
            color: #6c757d !important
        }

        a.text-secondary:focus,
        a.text-secondary:hover {
            color: #494f54 !important
        }

        .text-success {
            color: #28a745 !important
        }

        a.text-success:focus,
        a.text-success:hover {
            color: #19692c !important
        }

        .text-info {
            color: #17a2b8 !important
        }

        a.text-info:focus,
        a.text-info:hover {
            color: #0f6674 !important
        }

        .text-warning {
            color: #ffc107 !important
        }

        a.text-warning:focus,
        a.text-warning:hover {
            color: #ba8b00 !important
        }

        .text-danger {
            color: #dc3545 !important
        }

        a.text-danger:focus,
        a.text-danger:hover {
            color: #a71d2a !important
        }

        .text-light {
            color: #f8f9fa !important
        }

        a.text-light:focus,
        a.text-light:hover {
            color: #cbd3da !important
        }

        .text-dark {
            color: #343a40 !important
        }

        a.text-dark:focus,
        a.text-dark:hover {
            color: #121416 !important
        }

        .text-body {
            color: #212529 !important
        }

        .text-muted {
            color: #6c757d !important
        }

        .text-black-50 {
            color: rgba(0, 0, 0, .5) !important
        }

        .text-white-50 {
            color: hsla(0, 0%, 100%, .5) !important
        }

        .text-hide {
            font: 0/0 a;
            color: transparent;
            text-shadow: none;
            background-color: transparent;
            border: 0
        }

        .text-decoration-none {
            text-decoration: none !important
        }

        .text-break {
            word-break: break-word !important;
            word-wrap: break-word !important
        }

        .text-reset {
            color: inherit !important
        }

        .visible {
            visibility: visible !important
        }

        .invisible {
            visibility: hidden !important
        }

        @media print {

            *,
            :after,
            :before {
                text-shadow: none !important;
                box-shadow: none !important
            }

            a:not(.btn) {
                text-decoration: underline
            }

            abbr[title]:after {
                content: " ("attr(title) ")"
            }

            pre {
                white-space: pre-wrap !important
            }

            blockquote,
            pre {
                border: 1px solid #adb5bd;
                page-break-inside: avoid
            }

            thead {
                display: table-header-group
            }

            img,
            tr {
                page-break-inside: avoid
            }

            h2,
            h3,
            p {
                orphans: 3;
                widows: 3
            }

            h2,
            h3 {
                page-break-after: avoid
            }

            @page {
                size: a3
            }

            .container,
            body {
                min-width: 992px !important
            }

            .navbar {
                display: none
            }

            .badge {
                border: 1px solid #000
            }

            .table {
                border-collapse: collapse !important
            }

            .table td,
            .table th {
                background-color: #fff !important
            }

            .table-bordered td,
            .table-bordered th {
                border: 1px solid #dee2e6 !important
            }

            .table-dark {
                color: inherit
            }

            .table-dark tbody+tbody,
            .table-dark td,
            .table-dark th,
            .table-dark thead th {
                border-color: #dee2e6
            }

            .table .thead-dark th {
                color: inherit;
                border-color: #dee2e6
            }
        }

        /*!
 * BootstrapVue Custom CSS (https://bootstrap-vue.org)
 */
        .bv-no-focus-ring:focus {
            outline: 0
        }

        @media (max-width:575.98px) {
            .bv-d-xs-down-none {
                display: none !important
            }
        }

        @media (max-width:767.98px) {
            .bv-d-sm-down-none {
                display: none !important
            }
        }

        @media (max-width:991.98px) {
            .bv-d-md-down-none {
                display: none !important
            }
        }

        @media (max-width:1199.98px) {
            .bv-d-lg-down-none {
                display: none !important
            }
        }

        .bv-d-xl-down-none {
            display: none !important
        }

        .form-control.focus {
            color: #495057;
            background-color: #fff;
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
        }

        .form-control.focus.is-valid {
            border-color: #28a745;
            box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
        }

        .form-control.focus.is-invalid {
            border-color: #dc3545;
            box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
        }

        .b-avatar {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            vertical-align: middle;
            flex-shrink: 0;
            width: 2.5rem;
            height: 2.5rem;
            font-size: inherit;
            font-weight: 400;
            line-height: 1;
            max-width: 100%;
            max-height: auto;
            text-align: center;
            overflow: visible;
            position: relative;
            transition: color .15s ease-in-out, background-color .15s ease-in-out, box-shadow .15s ease-in-out
        }

        .b-avatar:focus {
            outline: 0
        }

        .b-avatar.btn,
        .b-avatar[href] {
            padding: 0;
            border: 0
        }

        .b-avatar.btn .b-avatar-img img,
        .b-avatar[href] .b-avatar-img img {
            transition: transform .15s ease-in-out
        }

        .b-avatar.btn:not(:disabled):not(.disabled),
        .b-avatar[href]:not(:disabled):not(.disabled) {
            cursor: pointer
        }

        .b-avatar.btn:not(:disabled):not(.disabled):hover .b-avatar-img img,
        .b-avatar[href]:not(:disabled):not(.disabled):hover .b-avatar-img img {
            transform: scale(1.15)
        }

        .b-avatar.disabled,
        .b-avatar:disabled,
        .b-avatar[disabled] {
            opacity: .65;
            pointer-events: none
        }

        .b-avatar .b-avatar-custom,
        .b-avatar .b-avatar-img,
        .b-avatar .b-avatar-text {
            border-radius: inherit;
            width: 100%;
            height: 100%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            -webkit-mask-image: radial-gradient(#fff, #000);
            mask-image: radial-gradient(#fff, #000)
        }

        .b-avatar .b-avatar-text {
            text-transform: uppercase;
            white-space: nowrap
        }

        .b-avatar[href] {
            text-decoration: none
        }

        .b-avatar>.b-icon {
            width: 60%;
            height: auto;
            max-width: 100%
        }

        .b-avatar .b-avatar-img img {
            width: 100%;
            height: 100%;
            max-height: auto;
            border-radius: inherit;
            -o-object-fit: cover;
            object-fit: cover
        }

        .b-avatar .b-avatar-badge {
            position: absolute;
            min-height: 1.5em;
            min-width: 1.5em;
            padding: .25em;
            line-height: 1;
            border-radius: 10em;
            font-size: 70%;
            font-weight: 700;
            z-index: 1
        }

        .b-avatar-sm {
            width: 1.5rem;
            height: 1.5rem
        }

        .b-avatar-sm .b-avatar-text {
            font-size: .6rem
        }

        .b-avatar-sm .b-avatar-badge {
            font-size: .42rem
        }

        .b-avatar-lg {
            width: 3.5rem;
            height: 3.5rem
        }

        .b-avatar-lg .b-avatar-text {
            font-size: 1.4rem
        }

        .b-avatar-lg .b-avatar-badge {
            font-size: .98rem
        }

        .b-avatar-group .b-avatar-group-inner {
            display: flex;
            flex-wrap: wrap
        }

        .b-avatar-group .b-avatar {
            border: 1px solid #dee2e6
        }

        .b-avatar-group .btn.b-avatar:hover:not(.disabled):not(disabled),
        .b-avatar-group a.b-avatar:hover:not(.disabled):not(disabled) {
            z-index: 1
        }

        .b-calendar {
            display: inline-flex
        }

        .b-calendar .b-calendar-inner {
            min-width: 250px
        }

        .b-calendar .b-calendar-header,
        .b-calendar .b-calendar-nav {
            margin-bottom: .25rem
        }

        .b-calendar .b-calendar-nav .btn {
            padding: .25rem
        }

        .b-calendar output {
            padding: .25rem;
            font-size: 80%
        }

        .b-calendar output.readonly {
            background-color: #e9ecef;
            opacity: 1
        }

        .b-calendar .b-calendar-footer {
            margin-top: .5rem
        }

        .b-calendar .b-calendar-grid {
            padding: 0;
            margin: 0;
            overflow: hidden
        }

        .b-calendar .b-calendar-grid .row {
            flex-wrap: nowrap
        }

        .b-calendar .b-calendar-grid-caption {
            padding: .25rem
        }

        .b-calendar .b-calendar-grid-body .col[data-date] .btn {
            width: 32px;
            height: 32px;
            font-size: 14px;
            line-height: 1;
            margin: 3px auto;
            padding: 9px 0
        }

        .b-calendar .btn.disabled,
        .b-calendar .btn:disabled,
        .b-calendar .btn[aria-disabled=true] {
            cursor: default;
            pointer-events: none
        }

        .card-img-left {
            border-top-left-radius: calc(.25rem - 1px);
            border-bottom-left-radius: calc(.25rem - 1px)
        }

        .card-img-right {
            border-top-right-radius: calc(.25rem - 1px);
            border-bottom-right-radius: calc(.25rem - 1px)
        }

        .dropdown.dropleft .dropdown-toggle.dropdown-toggle-no-caret:before,
        .dropdown:not(.dropleft) .dropdown-toggle.dropdown-toggle-no-caret:after {
            display: none !important
        }

        .dropdown .dropdown-menu:focus {
            outline: 0
        }

        .b-dropdown-form {
            display: inline-block;
            padding: .25rem 1.5rem;
            width: 100%;
            clear: both;
            font-weight: 400
        }

        .b-dropdown-form:focus {
            outline: 1px dotted !important;
            outline: 5px auto -webkit-focus-ring-color !important
        }

        .b-dropdown-form.disabled,
        .b-dropdown-form:disabled {
            outline: 0 !important;
            color: #6c757d;
            pointer-events: none
        }

        .b-dropdown-text {
            display: inline-block;
            padding: .25rem 1.5rem;
            margin-bottom: 0;
            width: 100%;
            clear: both;
            font-weight: lighter
        }

        .custom-checkbox.b-custom-control-lg,
        .input-group-lg .custom-checkbox {
            font-size: 1.25rem;
            line-height: 1.5;
            padding-left: 1.875rem
        }

        .custom-checkbox.b-custom-control-lg .custom-control-label:before,
        .input-group-lg .custom-checkbox .custom-control-label:before {
            top: .3125rem;
            left: -1.875rem;
            width: 1.25rem;
            height: 1.25rem;
            border-radius: .3rem
        }

        .custom-checkbox.b-custom-control-lg .custom-control-label:after,
        .input-group-lg .custom-checkbox .custom-control-label:after {
            top: .3125rem;
            left: -1.875rem;
            width: 1.25rem;
            height: 1.25rem;
            background-size: 50% 50%
        }

        .custom-checkbox.b-custom-control-sm,
        .input-group-sm .custom-checkbox {
            font-size: .875rem;
            line-height: 1.5;
            padding-left: 1.3125rem
        }

        .custom-checkbox.b-custom-control-sm .custom-control-label:before,
        .input-group-sm .custom-checkbox .custom-control-label:before {
            top: .21875rem;
            left: -1.3125rem;
            width: .875rem;
            height: .875rem;
            border-radius: .2rem
        }

        .custom-checkbox.b-custom-control-sm .custom-control-label:after,
        .input-group-sm .custom-checkbox .custom-control-label:after {
            top: .21875rem;
            left: -1.3125rem;
            width: .875rem;
            height: .875rem;
            background-size: 50% 50%
        }

        .custom-switch.b-custom-control-lg,
        .input-group-lg .custom-switch {
            padding-left: 2.8125rem
        }

        .custom-switch.b-custom-control-lg .custom-control-label,
        .input-group-lg .custom-switch .custom-control-label {
            font-size: 1.25rem;
            line-height: 1.5
        }

        .custom-switch.b-custom-control-lg .custom-control-label:before,
        .input-group-lg .custom-switch .custom-control-label:before {
            top: .3125rem;
            height: 1.25rem;
            left: -2.8125rem;
            width: 2.1875rem;
            border-radius: .625rem
        }

        .custom-switch.b-custom-control-lg .custom-control-label:after,
        .input-group-lg .custom-switch .custom-control-label:after {
            top: calc(.3125rem + 2px);
            left: calc(-2.8125rem + 2px);
            width: calc(1.25rem - 4px);
            height: calc(1.25rem - 4px);
            border-radius: .625rem;
            background-size: 50% 50%
        }

        .custom-switch.b-custom-control-lg .custom-control-input:checked~.custom-control-label:after,
        .input-group-lg .custom-switch .custom-control-input:checked~.custom-control-label:after {
            transform: translateX(.9375rem)
        }

        .custom-switch.b-custom-control-sm,
        .input-group-sm .custom-switch {
            padding-left: 1.96875rem
        }

        .custom-switch.b-custom-control-sm .custom-control-label,
        .input-group-sm .custom-switch .custom-control-label {
            font-size: .875rem;
            line-height: 1.5
        }

        .custom-switch.b-custom-control-sm .custom-control-label:before,
        .input-group-sm .custom-switch .custom-control-label:before {
            top: .21875rem;
            left: -1.96875rem;
            width: 1.53125rem;
            height: .875rem;
            border-radius: .4375rem
        }

        .custom-switch.b-custom-control-sm .custom-control-label:after,
        .input-group-sm .custom-switch .custom-control-label:after {
            top: calc(.21875rem + 2px);
            left: calc(-1.96875rem + 2px);
            width: calc(.875rem - 4px);
            height: calc(.875rem - 4px);
            border-radius: .4375rem;
            background-size: 50% 50%
        }

        .custom-switch.b-custom-control-sm .custom-control-input:checked~.custom-control-label:after,
        .input-group-sm .custom-switch .custom-control-input:checked~.custom-control-label:after {
            transform: translateX(.65625rem)
        }

        .input-group>.input-group-append:last-child>.btn-group:not(:last-child):not(.dropdown-toggle)>.btn,
        .input-group>.input-group-append:not(:last-child)>.btn-group>.btn,
        .input-group>.input-group-prepend>.btn-group>.btn {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0
        }

        .input-group>.input-group-append>.btn-group>.btn,
        .input-group>.input-group-prepend:first-child>.btn-group:not(:first-child)>.btn,
        .input-group>.input-group-prepend:not(:first-child)>.btn-group>.btn {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0
        }

        .b-form-btn-label-control.form-control {
            display: flex;
            align-items: stretch;
            height: auto;
            padding: 0;
            background-image: none
        }

        .input-group .b-form-btn-label-control.form-control {
            padding: 0
        }

        .b-form-btn-label-control.form-control[dir=rtl],
        [dir=rtl] .b-form-btn-label-control.form-control {
            flex-direction: row-reverse
        }

        .b-form-btn-label-control.form-control[dir=rtl]>label,
        [dir=rtl] .b-form-btn-label-control.form-control>label {
            text-align: right
        }

        .b-form-btn-label-control.form-control>.btn {
            line-height: 1;
            font-size: inherit;
            box-shadow: none !important;
            border: 0
        }

        .b-form-btn-label-control.form-control>.btn:disabled {
            pointer-events: none
        }

        .b-form-btn-label-control.form-control.is-valid>.btn {
            color: #28a745
        }

        .b-form-btn-label-control.form-control.is-invalid>.btn {
            color: #dc3545
        }

        .b-form-btn-label-control.form-control>.dropdown-menu {
            padding: .5rem
        }

        .b-form-btn-label-control.form-control>.form-control {
            height: auto;
            min-height: calc(1.5em + .75rem);
            padding-left: .25rem;
            margin: 0;
            border: 0;
            outline: 0;
            background: 0 0;
            word-break: break-word;
            font-size: inherit;
            white-space: normal;
            cursor: pointer
        }

        .b-form-btn-label-control.form-control>.form-control.form-control-sm {
            min-height: calc(1.5em + .5rem)
        }

        .b-form-btn-label-control.form-control>.form-control.form-control-lg {
            min-height: calc(1.5em + 1rem)
        }

        .input-group.input-group-sm .b-form-btn-label-control.form-control>.form-control {
            min-height: calc(1.5em + .5rem);
            padding-top: .25rem;
            padding-bottom: .25rem
        }

        .input-group.input-group-lg .b-form-btn-label-control.form-control>.form-control {
            min-height: calc(1.5em + 1rem);
            padding-top: .5rem;
            padding-bottom: .5rem
        }

        .b-form-btn-label-control.form-control[aria-disabled=true],
        .b-form-btn-label-control.form-control[aria-readonly=true] {
            background-color: #e9ecef;
            opacity: 1
        }

        .b-form-btn-label-control.form-control[aria-disabled=true] {
            pointer-events: none
        }

        .b-form-btn-label-control.form-control[aria-disabled=true]>label {
            cursor: default
        }

        .b-form-btn-label-control.btn-group>.dropdown-menu {
            padding: .5rem
        }

        .custom-file-label {
            white-space: nowrap;
            overflow-x: hidden
        }

        .b-custom-control-lg .custom-file-input,
        .b-custom-control-lg .custom-file-label,
        .b-custom-control-lg.custom-file,
        .input-group-lg .custom-file-input,
        .input-group-lg .custom-file-label,
        .input-group-lg.custom-file {
            font-size: 1.25rem;
            height: calc(1.5em + 1rem + 2px)
        }

        .b-custom-control-lg .custom-file-label,
        .b-custom-control-lg .custom-file-label:after,
        .input-group-lg .custom-file-label,
        .input-group-lg .custom-file-label:after {
            padding: .5rem 1rem;
            line-height: 1.5
        }

        .b-custom-control-lg .custom-file-label,
        .input-group-lg .custom-file-label {
            border-radius: .3rem
        }

        .b-custom-control-lg .custom-file-label:after,
        .input-group-lg .custom-file-label:after {
            font-size: inherit;
            height: calc(1.5em + 1rem);
            border-radius: 0 .3rem .3rem 0
        }

        .b-custom-control-sm .custom-file-input,
        .b-custom-control-sm .custom-file-label,
        .b-custom-control-sm.custom-file,
        .input-group-sm .custom-file-input,
        .input-group-sm .custom-file-label,
        .input-group-sm.custom-file {
            font-size: .875rem;
            height: calc(1.5em + .5rem + 2px)
        }

        .b-custom-control-sm .custom-file-label,
        .b-custom-control-sm .custom-file-label:after,
        .input-group-sm .custom-file-label,
        .input-group-sm .custom-file-label:after {
            padding: .25rem .5rem;
            line-height: 1.5
        }

        .b-custom-control-sm .custom-file-label,
        .input-group-sm .custom-file-label {
            border-radius: .2rem
        }

        .b-custom-control-sm .custom-file-label:after,
        .input-group-sm .custom-file-label:after {
            font-size: inherit;
            height: calc(1.5em + .5rem);
            border-radius: 0 .2rem .2rem 0
        }

        .form-control.is-invalid,
        .form-control.is-valid,
        .was-validated .form-control:invalid,
        .was-validated .form-control:valid {
            background-position: right calc(.375em + .1875rem) center
        }

        input[type=color].form-control {
            height: calc(1.5em + .75rem + 2px);
            padding: .125rem .25rem
        }

        .input-group-sm input[type=color].form-control,
        input[type=color].form-control.form-control-sm {
            height: calc(1.5em + .5rem + 2px);
            padding: .125rem .25rem
        }

        .input-group-lg input[type=color].form-control,
        input[type=color].form-control.form-control-lg {
            height: calc(1.5em + 1rem + 2px);
            padding: .125rem .25rem
        }

        input[type=color].form-control:disabled {
            background-color: #adb5bd;
            opacity: .65
        }

        .input-group>.custom-range {
            position: relative;
            flex: 1 1 auto;
            width: 1%;
            margin-bottom: 0
        }

        .input-group>.custom-file+.custom-range,
        .input-group>.custom-range+.custom-file,
        .input-group>.custom-range+.custom-range,
        .input-group>.custom-range+.custom-select,
        .input-group>.custom-range+.form-control,
        .input-group>.custom-range+.form-control-plaintext,
        .input-group>.custom-select+.custom-range,
        .input-group>.form-control+.custom-range,
        .input-group>.form-control-plaintext+.custom-range {
            margin-left: -1px
        }

        .input-group>.custom-range:focus {
            z-index: 3
        }

        .input-group>.custom-range:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0
        }

        .input-group>.custom-range:not(:first-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0
        }

        .input-group>.custom-range {
            padding: 0 .75rem;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            height: calc(1.5em + .75rem + 2px);
            border-radius: .25rem;
            transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
        }

        @media (prefers-reduced-motion:reduce) {
            .input-group>.custom-range {
                transition: none
            }
        }

        .input-group>.custom-range:focus {
            color: #495057;
            background-color: #fff;
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
        }

        .input-group>.custom-range:disabled,
        .input-group>.custom-range[readonly] {
            background-color: #e9ecef
        }

        .input-group-lg>.custom-range {
            height: calc(1.5em + 1rem + 2px);
            padding: 0 1rem;
            border-radius: .3rem
        }

        .input-group-sm>.custom-range {
            height: calc(1.5em + .5rem + 2px);
            padding: 0 .5rem;
            border-radius: .2rem
        }

        .input-group .custom-range.is-valid,
        .was-validated .input-group .custom-range:valid {
            border-color: #28a745
        }

        .input-group .custom-range.is-valid:focus,
        .was-validated .input-group .custom-range:valid:focus {
            border-color: #28a745;
            box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
        }

        .custom-range.is-valid:focus::-webkit-slider-thumb,
        .was-validated .custom-range:valid:focus::-webkit-slider-thumb {
            box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #9be7ac
        }

        .custom-range.is-valid:focus::-moz-range-thumb,
        .was-validated .custom-range:valid:focus::-moz-range-thumb {
            box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #9be7ac
        }

        .custom-range.is-valid:focus::-ms-thumb,
        .was-validated .custom-range:valid:focus::-ms-thumb {
            box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #9be7ac
        }

        .custom-range.is-valid::-webkit-slider-thumb,
        .was-validated .custom-range:valid::-webkit-slider-thumb {
            background-color: #28a745;
            background-image: none
        }

        .custom-range.is-valid::-webkit-slider-thumb:active,
        .was-validated .custom-range:valid::-webkit-slider-thumb:active {
            background-color: #9be7ac;
            background-image: none
        }

        .custom-range.is-valid::-webkit-slider-runnable-track,
        .was-validated .custom-range:valid::-webkit-slider-runnable-track {
            background-color: rgba(40, 167, 69, .35)
        }

        .custom-range.is-valid::-moz-range-thumb,
        .was-validated .custom-range:valid::-moz-range-thumb {
            background-color: #28a745;
            background-image: none
        }

        .custom-range.is-valid::-moz-range-thumb:active,
        .was-validated .custom-range:valid::-moz-range-thumb:active {
            background-color: #9be7ac;
            background-image: none
        }

        .custom-range.is-valid::-moz-range-track,
        .was-validated .custom-range:valid::-moz-range-track {
            background: rgba(40, 167, 69, .35)
        }

        .custom-range.is-valid~.valid-feedback,
        .custom-range.is-valid~.valid-tooltip,
        .was-validated .custom-range:valid~.valid-feedback,
        .was-validated .custom-range:valid~.valid-tooltip {
            display: block
        }

        .custom-range.is-valid::-ms-thumb,
        .was-validated .custom-range:valid::-ms-thumb {
            background-color: #28a745;
            background-image: none
        }

        .custom-range.is-valid::-ms-thumb:active,
        .was-validated .custom-range:valid::-ms-thumb:active {
            background-color: #9be7ac;
            background-image: none
        }

        .custom-range.is-valid::-ms-track-lower,
        .custom-range.is-valid::-ms-track-upper,
        .was-validated .custom-range:valid::-ms-track-lower,
        .was-validated .custom-range:valid::-ms-track-upper {
            background: rgba(40, 167, 69, .35)
        }

        .input-group .custom-range.is-invalid,
        .was-validated .input-group .custom-range:invalid {
            border-color: #dc3545
        }

        .input-group .custom-range.is-invalid:focus,
        .was-validated .input-group .custom-range:invalid:focus {
            border-color: #dc3545;
            box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
        }

        .custom-range.is-invalid:focus::-webkit-slider-thumb,
        .was-validated .custom-range:invalid:focus::-webkit-slider-thumb {
            box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #f6cdd1
        }

        .custom-range.is-invalid:focus::-moz-range-thumb,
        .was-validated .custom-range:invalid:focus::-moz-range-thumb {
            box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #f6cdd1
        }

        .custom-range.is-invalid:focus::-ms-thumb,
        .was-validated .custom-range:invalid:focus::-ms-thumb {
            box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #f6cdd1
        }

        .custom-range.is-invalid::-webkit-slider-thumb,
        .was-validated .custom-range:invalid::-webkit-slider-thumb {
            background-color: #dc3545;
            background-image: none
        }

        .custom-range.is-invalid::-webkit-slider-thumb:active,
        .was-validated .custom-range:invalid::-webkit-slider-thumb:active {
            background-color: #f6cdd1;
            background-image: none
        }

        .custom-range.is-invalid::-webkit-slider-runnable-track,
        .was-validated .custom-range:invalid::-webkit-slider-runnable-track {
            background-color: rgba(220, 53, 69, .35)
        }

        .custom-range.is-invalid::-moz-range-thumb,
        .was-validated .custom-range:invalid::-moz-range-thumb {
            background-color: #dc3545;
            background-image: none
        }

        .custom-range.is-invalid::-moz-range-thumb:active,
        .was-validated .custom-range:invalid::-moz-range-thumb:active {
            background-color: #f6cdd1;
            background-image: none
        }

        .custom-range.is-invalid::-moz-range-track,
        .was-validated .custom-range:invalid::-moz-range-track {
            background: rgba(220, 53, 69, .35)
        }

        .custom-range.is-invalid~.invalid-feedback,
        .custom-range.is-invalid~.invalid-tooltip,
        .was-validated .custom-range:invalid~.invalid-feedback,
        .was-validated .custom-range:invalid~.invalid-tooltip {
            display: block
        }

        .custom-range.is-invalid::-ms-thumb,
        .was-validated .custom-range:invalid::-ms-thumb {
            background-color: #dc3545;
            background-image: none
        }

        .custom-range.is-invalid::-ms-thumb:active,
        .was-validated .custom-range:invalid::-ms-thumb:active {
            background-color: #f6cdd1;
            background-image: none
        }

        .custom-range.is-invalid::-ms-track-lower,
        .custom-range.is-invalid::-ms-track-upper,
        .was-validated .custom-range:invalid::-ms-track-lower,
        .was-validated .custom-range:invalid::-ms-track-upper {
            background: rgba(220, 53, 69, .35)
        }

        .custom-radio.b-custom-control-lg,
        .input-group-lg .custom-radio {
            font-size: 1.25rem;
            line-height: 1.5;
            padding-left: 1.875rem
        }

        .custom-radio.b-custom-control-lg .custom-control-label:before,
        .input-group-lg .custom-radio .custom-control-label:before {
            top: .3125rem;
            left: -1.875rem;
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 50%
        }

        .custom-radio.b-custom-control-lg .custom-control-label:after,
        .input-group-lg .custom-radio .custom-control-label:after {
            top: .3125rem;
            left: -1.875rem;
            width: 1.25rem;
            height: 1.25rem;
            background: no-repeat 50%/50% 50%
        }

        .custom-radio.b-custom-control-sm,
        .input-group-sm .custom-radio {
            font-size: .875rem;
            line-height: 1.5;
            padding-left: 1.3125rem
        }

        .custom-radio.b-custom-control-sm .custom-control-label:before,
        .input-group-sm .custom-radio .custom-control-label:before {
            top: .21875rem;
            left: -1.3125rem;
            width: .875rem;
            height: .875rem;
            border-radius: 50%
        }

        .custom-radio.b-custom-control-sm .custom-control-label:after,
        .input-group-sm .custom-radio .custom-control-label:after {
            top: .21875rem;
            left: -1.3125rem;
            width: .875rem;
            height: .875rem;
            background: no-repeat 50%/50% 50%
        }

        .b-rating {
            text-align: center
        }

        .b-rating.d-inline-flex {
            width: auto
        }

        .b-rating .b-rating-star,
        .b-rating .b-rating-value {
            padding: 0 .25em
        }

        .b-rating .b-rating-value {
            min-width: 2.5em
        }

        .b-rating .b-rating-star {
            display: inline-flex;
            justify-content: center;
            outline: 0
        }

        .b-rating .b-rating-star .b-rating-icon {
            display: inline-flex;
            transition: all .15s ease-in-out
        }

        .b-rating.disabled,
        .b-rating:disabled {
            background-color: #e9ecef;
            color: #6c757d
        }

        .b-rating:not(.disabled):not(.readonly) .b-rating-star {
            cursor: pointer
        }

        .b-rating:not(.disabled):not(.readonly) .b-rating-star:hover .b-rating-icon,
        .b-rating:not(.disabled):not(.readonly):focus:not(:hover) .b-rating-star.focused .b-rating-icon {
            transform: scale(1.5)
        }

        .b-rating[dir=rtl] .b-rating-star-half {
            transform: scaleX(-1)
        }

        .b-form-spinbutton {
            text-align: center;
            overflow: hidden;
            background-image: none;
            padding: 0
        }

        .b-form-spinbutton[dir=rtl]:not(.flex-column),
        [dir=rtl] .b-form-spinbutton:not(.flex-column) {
            flex-direction: row-reverse
        }

        .b-form-spinbutton output {
            font-size: inherit;
            outline: 0;
            border: 0;
            background-color: transparent;
            width: auto;
            margin: 0;
            padding: 0 .25rem
        }

        .b-form-spinbutton output>bdi,
        .b-form-spinbutton output>div {
            display: block;
            min-width: 2.25em;
            height: 1.5em
        }

        .b-form-spinbutton.flex-column {
            height: auto;
            width: auto
        }

        .b-form-spinbutton.flex-column output {
            margin: 0 .25rem;
            padding: .25rem 0
        }

        .b-form-spinbutton:not(.d-inline-flex):not(.flex-column) {
            output-width: 100%
        }

        .b-form-spinbutton.d-inline-flex:not(.flex-column) {
            width: auto
        }

        .b-form-spinbutton .btn {
            line-height: 1;
            box-shadow: none !important
        }

        .b-form-spinbutton .btn:disabled {
            pointer-events: none
        }

        .b-form-spinbutton .btn:hover:not(:disabled)>div>.b-icon {
            transform: scale(1.25)
        }

        .b-form-spinbutton.disabled,
        .b-form-spinbutton.readonly {
            background-color: #e9ecef
        }

        .b-form-spinbutton.disabled {
            pointer-events: none
        }

        .b-form-tags .b-form-tags-list {
            margin-top: -.25rem
        }

        .b-form-tags .b-form-tags-list .b-form-tag,
        .b-form-tags .b-form-tags-list .b-from-tags-field {
            margin-top: .25rem
        }

        .b-form-tags.focus {
            color: #495057;
            background-color: #fff;
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
        }

        .b-form-tags.focus.is-valid {
            border-color: #28a745;
            box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
        }

        .b-form-tags.focus.is-invalid {
            border-color: #dc3545;
            box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
        }

        .b-form-tags.disabled {
            background-color: #e9ecef
        }

        .b-form-tag {
            font-size: 75%;
            font-weight: 400;
            line-height: 1.5;
            margin-right: .25rem
        }

        .b-form-tag.disabled {
            opacity: .75
        }

        .b-form-tag>button.b-form-tag-remove {
            color: inherit;
            font-size: 125%;
            line-height: 1;
            float: none;
            margin-left: .25rem
        }

        .form-control-lg .b-form-tag,
        .form-control-sm .b-form-tag {
            line-height: 1.5
        }

        .media-aside {
            display: flex;
            margin-right: 1rem
        }

        .media-aside-right {
            margin-right: 0;
            margin-left: 1rem
        }

        .modal-backdrop {
            opacity: .5
        }

        .b-pagination-pills .page-item .page-link {
            border-radius: 50rem !important;
            margin-left: .25rem;
            line-height: 1
        }

        .b-pagination-pills .page-item:first-child .page-link {
            margin-left: 0
        }

        .popover.b-popover {
            display: block;
            opacity: 1;
            outline: 0
        }

        .popover.b-popover.fade:not(.show) {
            opacity: 0
        }

        .popover.b-popover.show {
            opacity: 1
        }

        .b-popover-primary.popover {
            background-color: #cce5ff;
            border-color: #b8daff
        }

        .b-popover-primary.bs-popover-auto[x-placement^=top]>.arrow:before,
        .b-popover-primary.bs-popover-top>.arrow:before {
            border-top-color: #b8daff
        }

        .b-popover-primary.bs-popover-auto[x-placement^=top]>.arrow:after,
        .b-popover-primary.bs-popover-top>.arrow:after {
            border-top-color: #cce5ff
        }

        .b-popover-primary.bs-popover-auto[x-placement^=right]>.arrow:before,
        .b-popover-primary.bs-popover-right>.arrow:before {
            border-right-color: #b8daff
        }

        .b-popover-primary.bs-popover-auto[x-placement^=right]>.arrow:after,
        .b-popover-primary.bs-popover-right>.arrow:after {
            border-right-color: #cce5ff
        }

        .b-popover-primary.bs-popover-auto[x-placement^=bottom]>.arrow:before,
        .b-popover-primary.bs-popover-bottom>.arrow:before {
            border-bottom-color: #b8daff
        }

        .b-popover-primary.bs-popover-auto[x-placement^=bottom] .popover-header:before,
        .b-popover-primary.bs-popover-auto[x-placement^=bottom]>.arrow:after,
        .b-popover-primary.bs-popover-bottom .popover-header:before,
        .b-popover-primary.bs-popover-bottom>.arrow:after {
            border-bottom-color: #bdddff
        }

        .b-popover-primary.bs-popover-auto[x-placement^=left]>.arrow:before,
        .b-popover-primary.bs-popover-left>.arrow:before {
            border-left-color: #b8daff
        }

        .b-popover-primary.bs-popover-auto[x-placement^=left]>.arrow:after,
        .b-popover-primary.bs-popover-left>.arrow:after {
            border-left-color: #cce5ff
        }

        .b-popover-primary .popover-header {
            color: #212529;
            background-color: #bdddff;
            border-bottom-color: #a3d0ff
        }

        .b-popover-primary .popover-body {
            color: #004085
        }

        .b-popover-secondary.popover {
            background-color: #e2e3e5;
            border-color: #d6d8db
        }

        .b-popover-secondary.bs-popover-auto[x-placement^=top]>.arrow:before,
        .b-popover-secondary.bs-popover-top>.arrow:before {
            border-top-color: #d6d8db
        }

        .b-popover-secondary.bs-popover-auto[x-placement^=top]>.arrow:after,
        .b-popover-secondary.bs-popover-top>.arrow:after {
            border-top-color: #e2e3e5
        }

        .b-popover-secondary.bs-popover-auto[x-placement^=right]>.arrow:before,
        .b-popover-secondary.bs-popover-right>.arrow:before {
            border-right-color: #d6d8db
        }

        .b-popover-secondary.bs-popover-auto[x-placement^=right]>.arrow:after,
        .b-popover-secondary.bs-popover-right>.arrow:after {
            border-right-color: #e2e3e5
        }

        .b-popover-secondary.bs-popover-auto[x-placement^=bottom]>.arrow:before,
        .b-popover-secondary.bs-popover-bottom>.arrow:before {
            border-bottom-color: #d6d8db
        }

        .b-popover-secondary.bs-popover-auto[x-placement^=bottom] .popover-header:before,
        .b-popover-secondary.bs-popover-auto[x-placement^=bottom]>.arrow:after,
        .b-popover-secondary.bs-popover-bottom .popover-header:before,
        .b-popover-secondary.bs-popover-bottom>.arrow:after {
            border-bottom-color: #dadbde
        }

        .b-popover-secondary.bs-popover-auto[x-placement^=left]>.arrow:before,
        .b-popover-secondary.bs-popover-left>.arrow:before {
            border-left-color: #d6d8db
        }

        .b-popover-secondary.bs-popover-auto[x-placement^=left]>.arrow:after,
        .b-popover-secondary.bs-popover-left>.arrow:after {
            border-left-color: #e2e3e5
        }

        .b-popover-secondary .popover-header {
            color: #212529;
            background-color: #dadbde;
            border-bottom-color: #ccced2
        }

        .b-popover-secondary .popover-body {
            color: #383d41
        }

        .b-popover-success.popover {
            background-color: #d4edda;
            border-color: #c3e6cb
        }

        .b-popover-success.bs-popover-auto[x-placement^=top]>.arrow:before,
        .b-popover-success.bs-popover-top>.arrow:before {
            border-top-color: #c3e6cb
        }

        .b-popover-success.bs-popover-auto[x-placement^=top]>.arrow:after,
        .b-popover-success.bs-popover-top>.arrow:after {
            border-top-color: #d4edda
        }

        .b-popover-success.bs-popover-auto[x-placement^=right]>.arrow:before,
        .b-popover-success.bs-popover-right>.arrow:before {
            border-right-color: #c3e6cb
        }

        .b-popover-success.bs-popover-auto[x-placement^=right]>.arrow:after,
        .b-popover-success.bs-popover-right>.arrow:after {
            border-right-color: #d4edda
        }

        .b-popover-success.bs-popover-auto[x-placement^=bottom]>.arrow:before,
        .b-popover-success.bs-popover-bottom>.arrow:before {
            border-bottom-color: #c3e6cb
        }

        .b-popover-success.bs-popover-auto[x-placement^=bottom] .popover-header:before,
        .b-popover-success.bs-popover-auto[x-placement^=bottom]>.arrow:after,
        .b-popover-success.bs-popover-bottom .popover-header:before,
        .b-popover-success.bs-popover-bottom>.arrow:after {
            border-bottom-color: #c9e8d1
        }

        .b-popover-success.bs-popover-auto[x-placement^=left]>.arrow:before,
        .b-popover-success.bs-popover-left>.arrow:before {
            border-left-color: #c3e6cb
        }

        .b-popover-success.bs-popover-auto[x-placement^=left]>.arrow:after,
        .b-popover-success.bs-popover-left>.arrow:after {
            border-left-color: #d4edda
        }

        .b-popover-success .popover-header {
            color: #212529;
            background-color: #c9e8d1;
            border-bottom-color: #b7e1c1
        }

        .b-popover-success .popover-body {
            color: #155724
        }

        .b-popover-info.popover {
            background-color: #d1ecf1;
            border-color: #bee5eb
        }

        .b-popover-info.bs-popover-auto[x-placement^=top]>.arrow:before,
        .b-popover-info.bs-popover-top>.arrow:before {
            border-top-color: #bee5eb
        }

        .b-popover-info.bs-popover-auto[x-placement^=top]>.arrow:after,
        .b-popover-info.bs-popover-top>.arrow:after {
            border-top-color: #d1ecf1
        }

        .b-popover-info.bs-popover-auto[x-placement^=right]>.arrow:before,
        .b-popover-info.bs-popover-right>.arrow:before {
            border-right-color: #bee5eb
        }

        .b-popover-info.bs-popover-auto[x-placement^=right]>.arrow:after,
        .b-popover-info.bs-popover-right>.arrow:after {
            border-right-color: #d1ecf1
        }

        .b-popover-info.bs-popover-auto[x-placement^=bottom]>.arrow:before,
        .b-popover-info.bs-popover-bottom>.arrow:before {
            border-bottom-color: #bee5eb
        }

        .b-popover-info.bs-popover-auto[x-placement^=bottom] .popover-header:before,
        .b-popover-info.bs-popover-auto[x-placement^=bottom]>.arrow:after,
        .b-popover-info.bs-popover-bottom .popover-header:before,
        .b-popover-info.bs-popover-bottom>.arrow:after {
            border-bottom-color: #c5e7ed
        }

        .b-popover-info.bs-popover-auto[x-placement^=left]>.arrow:before,
        .b-popover-info.bs-popover-left>.arrow:before {
            border-left-color: #bee5eb
        }

        .b-popover-info.bs-popover-auto[x-placement^=left]>.arrow:after,
        .b-popover-info.bs-popover-left>.arrow:after {
            border-left-color: #d1ecf1
        }

        .b-popover-info .popover-header {
            color: #212529;
            background-color: #c5e7ed;
            border-bottom-color: #b2dfe7
        }

        .b-popover-info .popover-body {
            color: #0c5460
        }

        .b-popover-warning.popover {
            background-color: #fff3cd;
            border-color: #ffeeba
        }

        .b-popover-warning.bs-popover-auto[x-placement^=top]>.arrow:before,
        .b-popover-warning.bs-popover-top>.arrow:before {
            border-top-color: #ffeeba
        }

        .b-popover-warning.bs-popover-auto[x-placement^=top]>.arrow:after,
        .b-popover-warning.bs-popover-top>.arrow:after {
            border-top-color: #fff3cd
        }

        .b-popover-warning.bs-popover-auto[x-placement^=right]>.arrow:before,
        .b-popover-warning.bs-popover-right>.arrow:before {
            border-right-color: #ffeeba
        }

        .b-popover-warning.bs-popover-auto[x-placement^=right]>.arrow:after,
        .b-popover-warning.bs-popover-right>.arrow:after {
            border-right-color: #fff3cd
        }

        .b-popover-warning.bs-popover-auto[x-placement^=bottom]>.arrow:before,
        .b-popover-warning.bs-popover-bottom>.arrow:before {
            border-bottom-color: #ffeeba
        }

        .b-popover-warning.bs-popover-auto[x-placement^=bottom] .popover-header:before,
        .b-popover-warning.bs-popover-auto[x-placement^=bottom]>.arrow:after,
        .b-popover-warning.bs-popover-bottom .popover-header:before,
        .b-popover-warning.bs-popover-bottom>.arrow:after {
            border-bottom-color: #ffefbe
        }

        .b-popover-warning.bs-popover-auto[x-placement^=left]>.arrow:before,
        .b-popover-warning.bs-popover-left>.arrow:before {
            border-left-color: #ffeeba
        }

        .b-popover-warning.bs-popover-auto[x-placement^=left]>.arrow:after,
        .b-popover-warning.bs-popover-left>.arrow:after {
            border-left-color: #fff3cd
        }

        .b-popover-warning .popover-header {
            color: #212529;
            background-color: #ffefbe;
            border-bottom-color: #ffe9a4
        }

        .b-popover-warning .popover-body {
            color: #856404
        }

        .b-popover-danger.popover {
            background-color: #f8d7da;
            border-color: #f5c6cb
        }

        .b-popover-danger.bs-popover-auto[x-placement^=top]>.arrow:before,
        .b-popover-danger.bs-popover-top>.arrow:before {
            border-top-color: #f5c6cb
        }

        .b-popover-danger.bs-popover-auto[x-placement^=top]>.arrow:after,
        .b-popover-danger.bs-popover-top>.arrow:after {
            border-top-color: #f8d7da
        }

        .b-popover-danger.bs-popover-auto[x-placement^=right]>.arrow:before,
        .b-popover-danger.bs-popover-right>.arrow:before {
            border-right-color: #f5c6cb
        }

        .b-popover-danger.bs-popover-auto[x-placement^=right]>.arrow:after,
        .b-popover-danger.bs-popover-right>.arrow:after {
            border-right-color: #f8d7da
        }

        .b-popover-danger.bs-popover-auto[x-placement^=bottom]>.arrow:before,
        .b-popover-danger.bs-popover-bottom>.arrow:before {
            border-bottom-color: #f5c6cb
        }

        .b-popover-danger.bs-popover-auto[x-placement^=bottom] .popover-header:before,
        .b-popover-danger.bs-popover-auto[x-placement^=bottom]>.arrow:after,
        .b-popover-danger.bs-popover-bottom .popover-header:before,
        .b-popover-danger.bs-popover-bottom>.arrow:after {
            border-bottom-color: #f6cace
        }

        .b-popover-danger.bs-popover-auto[x-placement^=left]>.arrow:before,
        .b-popover-danger.bs-popover-left>.arrow:before {
            border-left-color: #f5c6cb
        }

        .b-popover-danger.bs-popover-auto[x-placement^=left]>.arrow:after,
        .b-popover-danger.bs-popover-left>.arrow:after {
            border-left-color: #f8d7da
        }

        .b-popover-danger .popover-header {
            color: #212529;
            background-color: #f6cace;
            border-bottom-color: #f2b4ba
        }

        .b-popover-danger .popover-body {
            color: #721c24
        }

        .b-popover-light.popover {
            background-color: #fefefe;
            border-color: #fdfdfe
        }

        .b-popover-light.bs-popover-auto[x-placement^=top]>.arrow:before,
        .b-popover-light.bs-popover-top>.arrow:before {
            border-top-color: #fdfdfe
        }

        .b-popover-light.bs-popover-auto[x-placement^=top]>.arrow:after,
        .b-popover-light.bs-popover-top>.arrow:after {
            border-top-color: #fefefe
        }

        .b-popover-light.bs-popover-auto[x-placement^=right]>.arrow:before,
        .b-popover-light.bs-popover-right>.arrow:before {
            border-right-color: #fdfdfe
        }

        .b-popover-light.bs-popover-auto[x-placement^=right]>.arrow:after,
        .b-popover-light.bs-popover-right>.arrow:after {
            border-right-color: #fefefe
        }

        .b-popover-light.bs-popover-auto[x-placement^=bottom]>.arrow:before,
        .b-popover-light.bs-popover-bottom>.arrow:before {
            border-bottom-color: #fdfdfe
        }

        .b-popover-light.bs-popover-auto[x-placement^=bottom] .popover-header:before,
        .b-popover-light.bs-popover-auto[x-placement^=bottom]>.arrow:after,
        .b-popover-light.bs-popover-bottom .popover-header:before,
        .b-popover-light.bs-popover-bottom>.arrow:after {
            border-bottom-color: #f6f6f6
        }

        .b-popover-light.bs-popover-auto[x-placement^=left]>.arrow:before,
        .b-popover-light.bs-popover-left>.arrow:before {
            border-left-color: #fdfdfe
        }

        .b-popover-light.bs-popover-auto[x-placement^=left]>.arrow:after,
        .b-popover-light.bs-popover-left>.arrow:after {
            border-left-color: #fefefe
        }

        .b-popover-light .popover-header {
            color: #212529;
            background-color: #f6f6f6;
            border-bottom-color: #eaeaea
        }

        .b-popover-light .popover-body {
            color: #818182
        }

        .b-popover-dark.popover {
            background-color: #d6d8d9;
            border-color: #c6c8ca
        }

        .b-popover-dark.bs-popover-auto[x-placement^=top]>.arrow:before,
        .b-popover-dark.bs-popover-top>.arrow:before {
            border-top-color: #c6c8ca
        }

        .b-popover-dark.bs-popover-auto[x-placement^=top]>.arrow:after,
        .b-popover-dark.bs-popover-top>.arrow:after {
            border-top-color: #d6d8d9
        }

        .b-popover-dark.bs-popover-auto[x-placement^=right]>.arrow:before,
        .b-popover-dark.bs-popover-right>.arrow:before {
            border-right-color: #c6c8ca
        }

        .b-popover-dark.bs-popover-auto[x-placement^=right]>.arrow:after,
        .b-popover-dark.bs-popover-right>.arrow:after {
            border-right-color: #d6d8d9
        }

        .b-popover-dark.bs-popover-auto[x-placement^=bottom]>.arrow:before,
        .b-popover-dark.bs-popover-bottom>.arrow:before {
            border-bottom-color: #c6c8ca
        }

        .b-popover-dark.bs-popover-auto[x-placement^=bottom] .popover-header:before,
        .b-popover-dark.bs-popover-auto[x-placement^=bottom]>.arrow:after,
        .b-popover-dark.bs-popover-bottom .popover-header:before,
        .b-popover-dark.bs-popover-bottom>.arrow:after {
            border-bottom-color: #ced0d2
        }

        .b-popover-dark.bs-popover-auto[x-placement^=left]>.arrow:before,
        .b-popover-dark.bs-popover-left>.arrow:before {
            border-left-color: #c6c8ca
        }

        .b-popover-dark.bs-popover-auto[x-placement^=left]>.arrow:after,
        .b-popover-dark.bs-popover-left>.arrow:after {
            border-left-color: #d6d8d9
        }

        .b-popover-dark .popover-header {
            color: #212529;
            background-color: #ced0d2;
            border-bottom-color: #c1c4c5
        }

        .b-popover-dark .popover-body {
            color: #1b1e21
        }

        .b-sidebar-outer {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            height: 0;
            overflow: visible;
            z-index: 1035
        }

        .b-sidebar-backdrop {
            left: 0;
            z-index: -1;
            width: 100vw;
            opacity: .6
        }

        .b-sidebar,
        .b-sidebar-backdrop {
            position: fixed;
            top: 0;
            height: 100vh
        }

        .b-sidebar {
            display: flex;
            flex-direction: column;
            width: 320px;
            max-width: 100%;
            max-height: 100%;
            margin: 0;
            outline: 0;
            transform: translateX(0)
        }

        .b-sidebar.slide {
            transition: transform .3s ease-in-out
        }

        @media (prefers-reduced-motion:reduce) {
            .b-sidebar.slide {
                transition: none
            }
        }

        .b-sidebar:not(.b-sidebar-right) {
            left: 0;
            right: auto
        }

        .b-sidebar:not(.b-sidebar-right).slide:not(.show) {
            transform: translateX(-100%)
        }

        .b-sidebar:not(.b-sidebar-right)>.b-sidebar-header .close {
            margin-left: auto
        }

        .b-sidebar.b-sidebar-right {
            left: auto;
            right: 0
        }

        .b-sidebar.b-sidebar-right.slide:not(.show) {
            transform: translateX(100%)
        }

        .b-sidebar.b-sidebar-right>.b-sidebar-header .close {
            margin-right: auto
        }

        .b-sidebar>.b-sidebar-header {
            font-size: 1.5rem;
            padding: .5rem 1rem;
            display: flex;
            flex-direction: row;
            flex-grow: 0;
            align-items: center
        }

        [dir=rtl] .b-sidebar>.b-sidebar-header {
            flex-direction: row-reverse
        }

        .b-sidebar>.b-sidebar-header .close {
            float: none;
            font-size: 1.5rem
        }

        .b-sidebar>.b-sidebar-body {
            flex-grow: 1;
            height: 100%;
            overflow-y: auto
        }

        .b-sidebar>.b-sidebar-footer {
            flex-grow: 0
        }

        .b-skeleton-wrapper {
            cursor: wait
        }

        .b-skeleton {
            position: relative;
            overflow: hidden;
            background-color: rgba(0, 0, 0, .12);
            cursor: wait;
            -webkit-mask-image: radial-gradient(#fff, #000);
            mask-image: radial-gradient(#fff, #000)
        }

        .b-skeleton:before {
            content: " "
        }

        .b-skeleton-text {
            height: 1rem;
            margin-bottom: .25rem;
            border-radius: .25rem
        }

        .b-skeleton-button {
            width: 75px;
            padding: .375rem .75rem;
            font-size: 1rem;
            line-height: 1.5;
            border-radius: .25rem
        }

        .b-skeleton-avatar {
            width: 2.5em;
            height: 2.5em;
            border-radius: 50%
        }

        .b-skeleton-input {
            height: calc(1.5em + .75rem + 2px);
            padding: .375rem .75rem;
            line-height: 1.5;
            border: 1px solid #ced4da;
            border-radius: .25rem
        }

        .b-skeleton-icon-wrapper svg {
            color: rgba(0, 0, 0, .12)
        }

        .b-skeleton-img {
            height: 100%;
            width: 100%
        }

        .b-skeleton-animate-wave:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 0;
            background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, .4), transparent);
            -webkit-animation: b-skeleton-animate-wave 1.75s linear infinite;
            animation: b-skeleton-animate-wave 1.75s linear infinite
        }

        @media (prefers-reduced-motion:reduce) {
            .b-skeleton-animate-wave:after {
                background: 0 0;
                -webkit-animation: none;
                animation: none
            }
        }

        @-webkit-keyframes b-skeleton-animate-wave {
            0% {
                transform: translateX(-100%)
            }

            to {
                transform: translateX(100%)
            }
        }

        @keyframes b-skeleton-animate-wave {
            0% {
                transform: translateX(-100%)
            }

            to {
                transform: translateX(100%)
            }
        }

        .b-skeleton-animate-fade {
            -webkit-animation: b-skeleton-animate-fade .875s ease-in-out infinite alternate;
            animation: b-skeleton-animate-fade .875s ease-in-out infinite alternate
        }

        @media (prefers-reduced-motion:reduce) {
            .b-skeleton-animate-fade {
                -webkit-animation: none;
                animation: none
            }
        }

        @-webkit-keyframes b-skeleton-animate-fade {
            0% {
                opacity: 1
            }

            to {
                opacity: .4
            }
        }

        @keyframes b-skeleton-animate-fade {
            0% {
                opacity: 1
            }

            to {
                opacity: .4
            }
        }

        .b-skeleton-animate-throb {
            -webkit-animation: b-skeleton-animate-throb .875s ease-in infinite alternate;
            animation: b-skeleton-animate-throb .875s ease-in infinite alternate
        }

        @media (prefers-reduced-motion:reduce) {
            .b-skeleton-animate-throb {
                -webkit-animation: none;
                animation: none
            }
        }

        @-webkit-keyframes b-skeleton-animate-throb {
            0% {
                transform: scale(1)
            }

            to {
                transform: scale(.975)
            }
        }

        @keyframes b-skeleton-animate-throb {
            0% {
                transform: scale(1)
            }

            to {
                transform: scale(.975)
            }
        }

        .table.b-table.b-table-fixed {
            table-layout: fixed
        }

        .table.b-table.b-table-no-border-collapse {
            border-collapse: separate;
            border-spacing: 0
        }

        .table.b-table[aria-busy=true] {
            opacity: .55
        }

        .table.b-table>tbody>tr.b-table-details>td {
            border-top: none !important
        }

        .table.b-table>caption {
            caption-side: bottom
        }

        .table.b-table.b-table-caption-top>caption {
            caption-side: top !important
        }

        .table.b-table>tbody>.table-active,
        .table.b-table>tbody>.table-active>td,
        .table.b-table>tbody>.table-active>th {
            background-color: rgba(0, 0, 0, .075)
        }

        .table.b-table.table-hover>tbody>tr.table-active:hover td,
        .table.b-table.table-hover>tbody>tr.table-active:hover th {
            color: #212529;
            background-image: linear-gradient(rgba(0, 0, 0, .075), rgba(0, 0, 0, .075));
            background-repeat: no-repeat
        }

        .table.b-table>tbody>.bg-active,
        .table.b-table>tbody>.bg-active>td,
        .table.b-table>tbody>.bg-active>th {
            background-color: hsla(0, 0%, 100%, .075) !important
        }

        .table.b-table.table-hover.table-dark>tbody>tr.bg-active:hover td,
        .table.b-table.table-hover.table-dark>tbody>tr.bg-active:hover th {
            color: #fff;
            background-image: linear-gradient(hsla(0, 0%, 100%, .075), hsla(0, 0%, 100%, .075));
            background-repeat: no-repeat
        }

        .b-table-sticky-header,
        .table-responsive,
        [class*=table-responsive-] {
            margin-bottom: 1rem
        }

        .b-table-sticky-header>.table,
        .table-responsive>.table,
        [class*=table-responsive-]>.table {
            margin-bottom: 0
        }

        .b-table-sticky-header {
            overflow-y: auto;
            max-height: 300px
        }

        @media print {
            .b-table-sticky-header {
                overflow-y: visible !important;
                max-height: none !important
            }
        }

        @supports (position:sticky) {
            .b-table-sticky-header>.table.b-table>thead>tr>th {
                position: sticky;
                top: 0;
                z-index: 2
            }

            .b-table-sticky-header>.table.b-table>tbody>tr>.b-table-sticky-column,
            .b-table-sticky-header>.table.b-table>tfoot>tr>.b-table-sticky-column,
            .b-table-sticky-header>.table.b-table>thead>tr>.b-table-sticky-column,
            .table-responsive>.table.b-table>tbody>tr>.b-table-sticky-column,
            .table-responsive>.table.b-table>tfoot>tr>.b-table-sticky-column,
            .table-responsive>.table.b-table>thead>tr>.b-table-sticky-column,
            [class*=table-responsive-]>.table.b-table>tbody>tr>.b-table-sticky-column,
            [class*=table-responsive-]>.table.b-table>tfoot>tr>.b-table-sticky-column,
            [class*=table-responsive-]>.table.b-table>thead>tr>.b-table-sticky-column {
                position: sticky;
                left: 0
            }

            .b-table-sticky-header>.table.b-table>thead>tr>.b-table-sticky-column,
            .table-responsive>.table.b-table>thead>tr>.b-table-sticky-column,
            [class*=table-responsive-]>.table.b-table>thead>tr>.b-table-sticky-column {
                z-index: 5
            }

            .b-table-sticky-header>.table.b-table>tbody>tr>.b-table-sticky-column,
            .b-table-sticky-header>.table.b-table>tfoot>tr>.b-table-sticky-column,
            .table-responsive>.table.b-table>tbody>tr>.b-table-sticky-column,
            .table-responsive>.table.b-table>tfoot>tr>.b-table-sticky-column,
            [class*=table-responsive-]>.table.b-table>tbody>tr>.b-table-sticky-column,
            [class*=table-responsive-]>.table.b-table>tfoot>tr>.b-table-sticky-column {
                z-index: 2
            }

            .table.b-table>tbody>tr>.table-b-table-default,
            .table.b-table>tfoot>tr>.table-b-table-default,
            .table.b-table>thead>tr>.table-b-table-default {
                color: #212529;
                background-color: #fff
            }

            .table.b-table.table-dark>tbody>tr>.bg-b-table-default,
            .table.b-table.table-dark>tfoot>tr>.bg-b-table-default,
            .table.b-table.table-dark>thead>tr>.bg-b-table-default {
                color: #fff;
                background-color: #343a40
            }

            .table.b-table.table-striped>tbody>tr:nth-of-type(odd)>.table-b-table-default {
                background-image: linear-gradient(rgba(0, 0, 0, .05), rgba(0, 0, 0, .05));
                background-repeat: no-repeat
            }

            .table.b-table.table-striped.table-dark>tbody>tr:nth-of-type(odd)>.bg-b-table-default {
                background-image: linear-gradient(hsla(0, 0%, 100%, .05), hsla(0, 0%, 100%, .05));
                background-repeat: no-repeat
            }

            .table.b-table.table-hover>tbody>tr:hover>.table-b-table-default {
                color: #212529;
                background-image: linear-gradient(rgba(0, 0, 0, .075), rgba(0, 0, 0, .075));
                background-repeat: no-repeat
            }

            .table.b-table.table-hover.table-dark>tbody>tr:hover>.bg-b-table-default {
                color: #fff;
                background-image: linear-gradient(hsla(0, 0%, 100%, .075), hsla(0, 0%, 100%, .075));
                background-repeat: no-repeat
            }
        }

        .table.b-table>tfoot>tr>[aria-sort],
        .table.b-table>thead>tr>[aria-sort] {
            cursor: pointer;
            background-image: none;
            background-repeat: no-repeat;
            background-size: .65em 1em
        }

        .table.b-table>tfoot>tr>[aria-sort]:not(.b-table-sort-icon-left),
        .table.b-table>thead>tr>[aria-sort]:not(.b-table-sort-icon-left) {
            background-position: right .375rem center;
            padding-right: calc(.75rem + .65em)
        }

        .table.b-table>tfoot>tr>[aria-sort].b-table-sort-icon-left,
        .table.b-table>thead>tr>[aria-sort].b-table-sort-icon-left {
            background-position: left .375rem center;
            padding-left: calc(.75rem + .65em)
        }

        .table.b-table>tfoot>tr>[aria-sort=none],
        .table.b-table>thead>tr>[aria-sort=none] {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath opacity='.3' d='M51 1l25 23 24 22H1l25-22zm0 100l25-23 24-22H1l25 22z'/%3E%3C/svg%3E")
        }

        .table.b-table>tfoot>tr>[aria-sort=ascending],
        .table.b-table>thead>tr>[aria-sort=ascending] {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath d='M51 1l25 23 24 22H1l25-22z'/%3E%3Cpath opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3E%3C/svg%3E")
        }

        .table.b-table>tfoot>tr>[aria-sort=descending],
        .table.b-table>thead>tr>[aria-sort=descending] {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3E%3Cpath d='M51 101l25-23 24-22H1l25 22z'/%3E%3C/svg%3E")
        }

        .table.b-table.table-dark>tfoot>tr>[aria-sort=none],
        .table.b-table.table-dark>thead>tr>[aria-sort=none],
        .table.b-table>.thead-dark>tr>[aria-sort=none] {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath fill='%23fff' opacity='.3' d='M51 1l25 23 24 22H1l25-22zm0 100l25-23 24-22H1l25 22z'/%3E%3C/svg%3E")
        }

        .table.b-table.table-dark>tfoot>tr>[aria-sort=ascending],
        .table.b-table.table-dark>thead>tr>[aria-sort=ascending],
        .table.b-table>.thead-dark>tr>[aria-sort=ascending] {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath fill='%23fff' d='M51 1l25 23 24 22H1l25-22z'/%3E%3Cpath fill='%23fff' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3E%3C/svg%3E")
        }

        .table.b-table.table-dark>tfoot>tr>[aria-sort=descending],
        .table.b-table.table-dark>thead>tr>[aria-sort=descending],
        .table.b-table>.thead-dark>tr>[aria-sort=descending] {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath fill='%23fff' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3E%3Cpath fill='%23fff' d='M51 101l25-23 24-22H1l25 22z'/%3E%3C/svg%3E")
        }

        .table.b-table>tfoot>tr>.table-dark[aria-sort=none],
        .table.b-table>thead>tr>.table-dark[aria-sort=none] {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath fill='%23fff' opacity='.3' d='M51 1l25 23 24 22H1l25-22zm0 100l25-23 24-22H1l25 22z'/%3E%3C/svg%3E")
        }

        .table.b-table>tfoot>tr>.table-dark[aria-sort=ascending],
        .table.b-table>thead>tr>.table-dark[aria-sort=ascending] {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath fill='%23fff' d='M51 1l25 23 24 22H1l25-22z'/%3E%3Cpath fill='%23fff' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3E%3C/svg%3E")
        }

        .table.b-table>tfoot>tr>.table-dark[aria-sort=descending],
        .table.b-table>thead>tr>.table-dark[aria-sort=descending] {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath fill='%23fff' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3E%3Cpath fill='%23fff' d='M51 101l25-23 24-22H1l25 22z'/%3E%3C/svg%3E")
        }

        .table.b-table.table-sm>tfoot>tr>[aria-sort]:not(.b-table-sort-icon-left),
        .table.b-table.table-sm>thead>tr>[aria-sort]:not(.b-table-sort-icon-left) {
            background-position: right .15rem center;
            padding-right: calc(.3rem + .65em)
        }

        .table.b-table.table-sm>tfoot>tr>[aria-sort].b-table-sort-icon-left,
        .table.b-table.table-sm>thead>tr>[aria-sort].b-table-sort-icon-left {
            background-position: left .15rem center;
            padding-left: calc(.3rem + .65em)
        }

        .table.b-table.b-table-selectable:not(.b-table-selectable-no-click)>tbody>tr {
            cursor: pointer
        }

        .table.b-table.b-table-selectable:not(.b-table-selectable-no-click).b-table-selecting.b-table-select-range>tbody>tr {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none
        }

        @media (max-width:575.98px) {
            .table.b-table.b-table-stacked-sm {
                display: block;
                width: 100%
            }

            .table.b-table.b-table-stacked-sm>caption,
            .table.b-table.b-table-stacked-sm>tbody,
            .table.b-table.b-table-stacked-sm>tbody>tr,
            .table.b-table.b-table-stacked-sm>tbody>tr>td,
            .table.b-table.b-table-stacked-sm>tbody>tr>th {
                display: block
            }

            .table.b-table.b-table-stacked-sm>tfoot,
            .table.b-table.b-table-stacked-sm>tfoot>tr.b-table-bottom-row,
            .table.b-table.b-table-stacked-sm>tfoot>tr.b-table-top-row,
            .table.b-table.b-table-stacked-sm>thead,
            .table.b-table.b-table-stacked-sm>thead>tr.b-table-bottom-row,
            .table.b-table.b-table-stacked-sm>thead>tr.b-table-top-row {
                display: none
            }

            .table.b-table.b-table-stacked-sm>caption {
                caption-side: top !important
            }

            .table.b-table.b-table-stacked-sm>tbody>tr>[data-label]:before {
                content: attr(data-label);
                width: 40%;
                float: left;
                text-align: right;
                word-wrap: break-word;
                font-weight: 700;
                font-style: normal;
                padding: 0 .5rem 0 0;
                margin: 0
            }

            .table.b-table.b-table-stacked-sm>tbody>tr>[data-label]:after {
                display: block;
                clear: both;
                content: ""
            }

            .table.b-table.b-table-stacked-sm>tbody>tr>[data-label]>div {
                display: inline-block;
                width: 60%;
                padding: 0 0 0 .5rem;
                margin: 0
            }

            .table.b-table.b-table-stacked-sm>tbody>tr.bottom-row,
            .table.b-table.b-table-stacked-sm>tbody>tr.top-row {
                display: none
            }

            .table.b-table.b-table-stacked-sm>tbody>tr>:first-child,
            .table.b-table.b-table-stacked-sm>tbody>tr>[rowspan]+td,
            .table.b-table.b-table-stacked-sm>tbody>tr>[rowspan]+th {
                border-top-width: 3px
            }
        }

        @media (max-width:767.98px) {
            .table.b-table.b-table-stacked-md {
                display: block;
                width: 100%
            }

            .table.b-table.b-table-stacked-md>caption,
            .table.b-table.b-table-stacked-md>tbody,
            .table.b-table.b-table-stacked-md>tbody>tr,
            .table.b-table.b-table-stacked-md>tbody>tr>td,
            .table.b-table.b-table-stacked-md>tbody>tr>th {
                display: block
            }

            .table.b-table.b-table-stacked-md>tfoot,
            .table.b-table.b-table-stacked-md>tfoot>tr.b-table-bottom-row,
            .table.b-table.b-table-stacked-md>tfoot>tr.b-table-top-row,
            .table.b-table.b-table-stacked-md>thead,
            .table.b-table.b-table-stacked-md>thead>tr.b-table-bottom-row,
            .table.b-table.b-table-stacked-md>thead>tr.b-table-top-row {
                display: none
            }

            .table.b-table.b-table-stacked-md>caption {
                caption-side: top !important
            }

            .table.b-table.b-table-stacked-md>tbody>tr>[data-label]:before {
                content: attr(data-label);
                width: 40%;
                float: left;
                text-align: right;
                word-wrap: break-word;
                font-weight: 700;
                font-style: normal;
                padding: 0 .5rem 0 0;
                margin: 0
            }

            .table.b-table.b-table-stacked-md>tbody>tr>[data-label]:after {
                display: block;
                clear: both;
                content: ""
            }

            .table.b-table.b-table-stacked-md>tbody>tr>[data-label]>div {
                display: inline-block;
                width: 60%;
                padding: 0 0 0 .5rem;
                margin: 0
            }

            .table.b-table.b-table-stacked-md>tbody>tr.bottom-row,
            .table.b-table.b-table-stacked-md>tbody>tr.top-row {
                display: none
            }

            .table.b-table.b-table-stacked-md>tbody>tr>:first-child,
            .table.b-table.b-table-stacked-md>tbody>tr>[rowspan]+td,
            .table.b-table.b-table-stacked-md>tbody>tr>[rowspan]+th {
                border-top-width: 3px
            }
        }

        @media (max-width:991.98px) {
            .table.b-table.b-table-stacked-lg {
                display: block;
                width: 100%
            }

            .table.b-table.b-table-stacked-lg>caption,
            .table.b-table.b-table-stacked-lg>tbody,
            .table.b-table.b-table-stacked-lg>tbody>tr,
            .table.b-table.b-table-stacked-lg>tbody>tr>td,
            .table.b-table.b-table-stacked-lg>tbody>tr>th {
                display: block
            }

            .table.b-table.b-table-stacked-lg>tfoot,
            .table.b-table.b-table-stacked-lg>tfoot>tr.b-table-bottom-row,
            .table.b-table.b-table-stacked-lg>tfoot>tr.b-table-top-row,
            .table.b-table.b-table-stacked-lg>thead,
            .table.b-table.b-table-stacked-lg>thead>tr.b-table-bottom-row,
            .table.b-table.b-table-stacked-lg>thead>tr.b-table-top-row {
                display: none
            }

            .table.b-table.b-table-stacked-lg>caption {
                caption-side: top !important
            }

            .table.b-table.b-table-stacked-lg>tbody>tr>[data-label]:before {
                content: attr(data-label);
                width: 40%;
                float: left;
                text-align: right;
                word-wrap: break-word;
                font-weight: 700;
                font-style: normal;
                padding: 0 .5rem 0 0;
                margin: 0
            }

            .table.b-table.b-table-stacked-lg>tbody>tr>[data-label]:after {
                display: block;
                clear: both;
                content: ""
            }

            .table.b-table.b-table-stacked-lg>tbody>tr>[data-label]>div {
                display: inline-block;
                width: 60%;
                padding: 0 0 0 .5rem;
                margin: 0
            }

            .table.b-table.b-table-stacked-lg>tbody>tr.bottom-row,
            .table.b-table.b-table-stacked-lg>tbody>tr.top-row {
                display: none
            }

            .table.b-table.b-table-stacked-lg>tbody>tr>:first-child,
            .table.b-table.b-table-stacked-lg>tbody>tr>[rowspan]+td,
            .table.b-table.b-table-stacked-lg>tbody>tr>[rowspan]+th {
                border-top-width: 3px
            }
        }

        @media (max-width:1199.98px) {
            .table.b-table.b-table-stacked-xl {
                display: block;
                width: 100%
            }

            .table.b-table.b-table-stacked-xl>caption,
            .table.b-table.b-table-stacked-xl>tbody,
            .table.b-table.b-table-stacked-xl>tbody>tr,
            .table.b-table.b-table-stacked-xl>tbody>tr>td,
            .table.b-table.b-table-stacked-xl>tbody>tr>th {
                display: block
            }

            .table.b-table.b-table-stacked-xl>tfoot,
            .table.b-table.b-table-stacked-xl>tfoot>tr.b-table-bottom-row,
            .table.b-table.b-table-stacked-xl>tfoot>tr.b-table-top-row,
            .table.b-table.b-table-stacked-xl>thead,
            .table.b-table.b-table-stacked-xl>thead>tr.b-table-bottom-row,
            .table.b-table.b-table-stacked-xl>thead>tr.b-table-top-row {
                display: none
            }

            .table.b-table.b-table-stacked-xl>caption {
                caption-side: top !important
            }

            .table.b-table.b-table-stacked-xl>tbody>tr>[data-label]:before {
                content: attr(data-label);
                width: 40%;
                float: left;
                text-align: right;
                word-wrap: break-word;
                font-weight: 700;
                font-style: normal;
                padding: 0 .5rem 0 0;
                margin: 0
            }

            .table.b-table.b-table-stacked-xl>tbody>tr>[data-label]:after {
                display: block;
                clear: both;
                content: ""
            }

            .table.b-table.b-table-stacked-xl>tbody>tr>[data-label]>div {
                display: inline-block;
                width: 60%;
                padding: 0 0 0 .5rem;
                margin: 0
            }

            .table.b-table.b-table-stacked-xl>tbody>tr.bottom-row,
            .table.b-table.b-table-stacked-xl>tbody>tr.top-row {
                display: none
            }

            .table.b-table.b-table-stacked-xl>tbody>tr>:first-child,
            .table.b-table.b-table-stacked-xl>tbody>tr>[rowspan]+td,
            .table.b-table.b-table-stacked-xl>tbody>tr>[rowspan]+th {
                border-top-width: 3px
            }
        }

        .table.b-table.b-table-stacked {
            display: block;
            width: 100%
        }

        .table.b-table.b-table-stacked>caption,
        .table.b-table.b-table-stacked>tbody,
        .table.b-table.b-table-stacked>tbody>tr,
        .table.b-table.b-table-stacked>tbody>tr>td,
        .table.b-table.b-table-stacked>tbody>tr>th {
            display: block
        }

        .table.b-table.b-table-stacked>tfoot,
        .table.b-table.b-table-stacked>tfoot>tr.b-table-bottom-row,
        .table.b-table.b-table-stacked>tfoot>tr.b-table-top-row,
        .table.b-table.b-table-stacked>thead,
        .table.b-table.b-table-stacked>thead>tr.b-table-bottom-row,
        .table.b-table.b-table-stacked>thead>tr.b-table-top-row {
            display: none
        }

        .table.b-table.b-table-stacked>caption {
            caption-side: top !important
        }

        .table.b-table.b-table-stacked>tbody>tr>[data-label]:before {
            content: attr(data-label);
            width: 40%;
            float: left;
            text-align: right;
            word-wrap: break-word;
            font-weight: 700;
            font-style: normal;
            padding: 0 .5rem 0 0;
            margin: 0
        }

        .table.b-table.b-table-stacked>tbody>tr>[data-label]:after {
            display: block;
            clear: both;
            content: ""
        }

        .table.b-table.b-table-stacked>tbody>tr>[data-label]>div {
            display: inline-block;
            width: 60%;
            padding: 0 0 0 .5rem;
            margin: 0
        }

        .table.b-table.b-table-stacked>tbody>tr.bottom-row,
        .table.b-table.b-table-stacked>tbody>tr.top-row {
            display: none
        }

        .table.b-table.b-table-stacked>tbody>tr>:first-child,
        .table.b-table.b-table-stacked>tbody>tr>[rowspan]+td,
        .table.b-table.b-table-stacked>tbody>tr>[rowspan]+th {
            border-top-width: 3px
        }

        .b-time {
            min-width: 150px
        }

        .b-time output.disabled,
        .b-time[aria-disabled=true] output,
        .b-time[aria-readonly=true] output {
            background-color: #e9ecef;
            opacity: 1
        }

        .b-time[aria-disabled=true] output {
            pointer-events: none
        }

        [dir=rtl] .b-time>.d-flex:not(.flex-column) {
            flex-direction: row-reverse
        }

        .b-time .b-time-header {
            margin-bottom: .5rem
        }

        .b-time .b-time-header output {
            padding: .25rem;
            font-size: 80%
        }

        .b-time .b-time-footer {
            margin-top: .5rem
        }

        .b-time .b-time-ampm {
            margin-left: .5rem
        }

        .b-toast {
            display: block;
            position: relative;
            max-width: 350px;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            background-clip: padding-box;
            z-index: 1;
            border-radius: .25rem
        }

        .b-toast .toast {
            background-color: hsla(0, 0%, 100%, .85)
        }

        .b-toast:not(:last-child) {
            margin-bottom: .75rem
        }

        .b-toast.b-toast-solid .toast {
            background-color: #fff
        }

        .b-toast .toast {
            opacity: 1
        }

        .b-toast .toast.fade:not(.show) {
            opacity: 0
        }

        .b-toast .toast .toast-body {
            display: block
        }

        .b-toast-primary .toast {
            background-color: rgba(230, 242, 255, .85);
            border-color: rgba(184, 218, 255, .85);
            color: #004085
        }

        .b-toast-primary .toast .toast-header {
            color: #004085;
            background-color: rgba(204, 229, 255, .85);
            border-bottom-color: rgba(184, 218, 255, .85)
        }

        .b-toast-primary.b-toast-solid .toast {
            background-color: #e6f2ff
        }

        .b-toast-secondary .toast {
            background-color: rgba(239, 240, 241, .85);
            border-color: rgba(214, 216, 219, .85);
            color: #383d41
        }

        .b-toast-secondary .toast .toast-header {
            color: #383d41;
            background-color: rgba(226, 227, 229, .85);
            border-bottom-color: rgba(214, 216, 219, .85)
        }

        .b-toast-secondary.b-toast-solid .toast {
            background-color: #eff0f1
        }

        .b-toast-success .toast {
            background-color: rgba(230, 245, 233, .85);
            border-color: rgba(195, 230, 203, .85);
            color: #155724
        }

        .b-toast-success .toast .toast-header {
            color: #155724;
            background-color: rgba(212, 237, 218, .85);
            border-bottom-color: rgba(195, 230, 203, .85)
        }

        .b-toast-success.b-toast-solid .toast {
            background-color: #e6f5e9
        }

        .b-toast-info .toast {
            background-color: rgba(229, 244, 247, .85);
            border-color: rgba(190, 229, 235, .85);
            color: #0c5460
        }

        .b-toast-info .toast .toast-header {
            color: #0c5460;
            background-color: rgba(209, 236, 241, .85);
            border-bottom-color: rgba(190, 229, 235, .85)
        }

        .b-toast-info.b-toast-solid .toast {
            background-color: #e5f4f7
        }

        .b-toast-warning .toast {
            background-color: rgba(255, 249, 231, .85);
            border-color: rgba(255, 238, 186, .85);
            color: #856404
        }

        .b-toast-warning .toast .toast-header {
            color: #856404;
            background-color: rgba(255, 243, 205, .85);
            border-bottom-color: rgba(255, 238, 186, .85)
        }

        .b-toast-warning.b-toast-solid .toast {
            background-color: #fff9e7
        }

        .b-toast-danger .toast {
            background-color: rgba(252, 237, 238, .85);
            border-color: rgba(245, 198, 203, .85);
            color: #721c24
        }

        .b-toast-danger .toast .toast-header {
            color: #721c24;
            background-color: rgba(248, 215, 218, .85);
            border-bottom-color: rgba(245, 198, 203, .85)
        }

        .b-toast-danger.b-toast-solid .toast {
            background-color: #fcedee
        }

        .b-toast-light .toast {
            background-color: hsla(0, 0%, 100%, .85);
            border-color: rgba(253, 253, 254, .85);
            color: #818182
        }

        .b-toast-light .toast .toast-header {
            color: #818182;
            background-color: hsla(0, 0%, 99.6%, .85);
            border-bottom-color: rgba(253, 253, 254, .85)
        }

        .b-toast-light.b-toast-solid .toast {
            background-color: #fff
        }

        .b-toast-dark .toast {
            background-color: rgba(227, 229, 229, .85);
            border-color: rgba(198, 200, 202, .85);
            color: #1b1e21
        }

        .b-toast-dark .toast .toast-header {
            color: #1b1e21;
            background-color: rgba(214, 216, 217, .85);
            border-bottom-color: rgba(198, 200, 202, .85)
        }

        .b-toast-dark.b-toast-solid .toast {
            background-color: #e3e5e5
        }

        .b-toaster {
            z-index: 1100
        }

        .b-toaster .b-toaster-slot {
            position: relative;
            display: block
        }

        .b-toaster .b-toaster-slot:empty {
            display: none !important
        }

        .b-toaster.b-toaster-bottom-center,
        .b-toaster.b-toaster-bottom-full,
        .b-toaster.b-toaster-bottom-left,
        .b-toaster.b-toaster-bottom-right,
        .b-toaster.b-toaster-top-center,
        .b-toaster.b-toaster-top-full,
        .b-toaster.b-toaster-top-left,
        .b-toaster.b-toaster-top-right {
            position: fixed;
            left: .5rem;
            right: .5rem;
            margin: 0;
            padding: 0;
            height: 0;
            overflow: visible
        }

        .b-toaster.b-toaster-bottom-center .b-toaster-slot,
        .b-toaster.b-toaster-bottom-full .b-toaster-slot,
        .b-toaster.b-toaster-bottom-left .b-toaster-slot,
        .b-toaster.b-toaster-bottom-right .b-toaster-slot,
        .b-toaster.b-toaster-top-center .b-toaster-slot,
        .b-toaster.b-toaster-top-full .b-toaster-slot,
        .b-toaster.b-toaster-top-left .b-toaster-slot,
        .b-toaster.b-toaster-top-right .b-toaster-slot {
            position: absolute;
            max-width: 350px;
            width: 100%;
            left: 0;
            right: 0;
            padding: 0;
            margin: 0
        }

        .b-toaster.b-toaster-bottom-full .b-toaster-slot,
        .b-toaster.b-toaster-bottom-full .b-toaster-slot .b-toast,
        .b-toaster.b-toaster-bottom-full .b-toaster-slot .toast,
        .b-toaster.b-toaster-top-full .b-toaster-slot,
        .b-toaster.b-toaster-top-full .b-toaster-slot .b-toast,
        .b-toaster.b-toaster-top-full .b-toaster-slot .toast {
            width: 100%;
            max-width: 100%
        }

        .b-toaster.b-toaster-top-center,
        .b-toaster.b-toaster-top-full,
        .b-toaster.b-toaster-top-left,
        .b-toaster.b-toaster-top-right {
            top: 0
        }

        .b-toaster.b-toaster-top-center .b-toaster-slot,
        .b-toaster.b-toaster-top-full .b-toaster-slot,
        .b-toaster.b-toaster-top-left .b-toaster-slot,
        .b-toaster.b-toaster-top-right .b-toaster-slot {
            top: .5rem
        }

        .b-toaster.b-toaster-bottom-center,
        .b-toaster.b-toaster-bottom-full,
        .b-toaster.b-toaster-bottom-left,
        .b-toaster.b-toaster-bottom-right {
            bottom: 0
        }

        .b-toaster.b-toaster-bottom-center .b-toaster-slot,
        .b-toaster.b-toaster-bottom-full .b-toaster-slot,
        .b-toaster.b-toaster-bottom-left .b-toaster-slot,
        .b-toaster.b-toaster-bottom-right .b-toaster-slot {
            bottom: .5rem
        }

        .b-toaster.b-toaster-bottom-center .b-toaster-slot,
        .b-toaster.b-toaster-bottom-right .b-toaster-slot,
        .b-toaster.b-toaster-top-center .b-toaster-slot,
        .b-toaster.b-toaster-top-right .b-toaster-slot {
            margin-left: auto
        }

        .b-toaster.b-toaster-bottom-center .b-toaster-slot,
        .b-toaster.b-toaster-bottom-left .b-toaster-slot,
        .b-toaster.b-toaster-top-center .b-toaster-slot,
        .b-toaster.b-toaster-top-left .b-toaster-slot {
            margin-right: auto
        }

        .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-active,
        .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active,
        .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-move,
        .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-active,
        .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active,
        .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-move,
        .b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-active,
        .b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active,
        .b-toaster.b-toaster-top-left .b-toast.b-toaster-move,
        .b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-active,
        .b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active,
        .b-toaster.b-toaster-top-right .b-toast.b-toaster-move {
            transition: transform .175s
        }

        .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-active .toast.fade,
        .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-to .toast.fade,
        .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-active .toast.fade,
        .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-to .toast.fade,
        .b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-active .toast.fade,
        .b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-to .toast.fade,
        .b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-active .toast.fade,
        .b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-to .toast.fade {
            transition-delay: .175s
        }

        .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active,
        .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active,
        .b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active,
        .b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active {
            position: absolute;
            transition-delay: .175s
        }

        .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active .toast.fade,
        .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active .toast.fade,
        .b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active .toast.fade,
        .b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active .toast.fade {
            transition-delay: 0s
        }

        .tooltip.b-tooltip {
            display: block;
            opacity: .9;
            outline: 0
        }

        .tooltip.b-tooltip.fade:not(.show) {
            opacity: 0
        }

        .tooltip.b-tooltip.show {
            opacity: .9
        }

        .tooltip.b-tooltip.noninteractive {
            pointer-events: none
        }

        .tooltip.b-tooltip .arrow {
            margin: 0 .25rem
        }

        .tooltip.b-tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow,
        .tooltip.b-tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow,
        .tooltip.b-tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow,
        .tooltip.b-tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow,
        .tooltip.b-tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow,
        .tooltip.b-tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow,
        .tooltip.b-tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow,
        .tooltip.b-tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow,
        .tooltip.b-tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow,
        .tooltip.b-tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow,
        .tooltip.b-tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow,
        .tooltip.b-tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow,
        .tooltip.b-tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow,
        .tooltip.b-tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow,
        .tooltip.b-tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow,
        .tooltip.b-tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow,
        .tooltip.b-tooltip.bs-tooltip-left .arrow,
        .tooltip.b-tooltip.bs-tooltip-right .arrow {
            margin: .25rem 0
        }

        .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top] .arrow:before,
        .tooltip.b-tooltip-primary.bs-tooltip-top .arrow:before {
            border-top-color: #007bff
        }

        .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow:before,
        .tooltip.b-tooltip-primary.bs-tooltip-right .arrow:before {
            border-right-color: #007bff
        }

        .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
        .tooltip.b-tooltip-primary.bs-tooltip-bottom .arrow:before {
            border-bottom-color: #007bff
        }

        .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow:before,
        .tooltip.b-tooltip-primary.bs-tooltip-left .arrow:before {
            border-left-color: #007bff
        }

        .tooltip.b-tooltip-primary .tooltip-inner {
            color: #fff;
            background-color: #007bff
        }

        .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top] .arrow:before,
        .tooltip.b-tooltip-secondary.bs-tooltip-top .arrow:before {
            border-top-color: #6c757d
        }

        .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow:before,
        .tooltip.b-tooltip-secondary.bs-tooltip-right .arrow:before {
            border-right-color: #6c757d
        }

        .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
        .tooltip.b-tooltip-secondary.bs-tooltip-bottom .arrow:before {
            border-bottom-color: #6c757d
        }

        .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow:before,
        .tooltip.b-tooltip-secondary.bs-tooltip-left .arrow:before {
            border-left-color: #6c757d
        }

        .tooltip.b-tooltip-secondary .tooltip-inner {
            color: #fff;
            background-color: #6c757d
        }

        .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top] .arrow:before,
        .tooltip.b-tooltip-success.bs-tooltip-top .arrow:before {
            border-top-color: #28a745
        }

        .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow:before,
        .tooltip.b-tooltip-success.bs-tooltip-right .arrow:before {
            border-right-color: #28a745
        }

        .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
        .tooltip.b-tooltip-success.bs-tooltip-bottom .arrow:before {
            border-bottom-color: #28a745
        }

        .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow:before,
        .tooltip.b-tooltip-success.bs-tooltip-left .arrow:before {
            border-left-color: #28a745
        }

        .tooltip.b-tooltip-success .tooltip-inner {
            color: #fff;
            background-color: #28a745
        }

        .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top] .arrow:before,
        .tooltip.b-tooltip-info.bs-tooltip-top .arrow:before {
            border-top-color: #17a2b8
        }

        .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow:before,
        .tooltip.b-tooltip-info.bs-tooltip-right .arrow:before {
            border-right-color: #17a2b8
        }

        .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
        .tooltip.b-tooltip-info.bs-tooltip-bottom .arrow:before {
            border-bottom-color: #17a2b8
        }

        .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow:before,
        .tooltip.b-tooltip-info.bs-tooltip-left .arrow:before {
            border-left-color: #17a2b8
        }

        .tooltip.b-tooltip-info .tooltip-inner {
            color: #fff;
            background-color: #17a2b8
        }

        .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top] .arrow:before,
        .tooltip.b-tooltip-warning.bs-tooltip-top .arrow:before {
            border-top-color: #ffc107
        }

        .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow:before,
        .tooltip.b-tooltip-warning.bs-tooltip-right .arrow:before {
            border-right-color: #ffc107
        }

        .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
        .tooltip.b-tooltip-warning.bs-tooltip-bottom .arrow:before {
            border-bottom-color: #ffc107
        }

        .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow:before,
        .tooltip.b-tooltip-warning.bs-tooltip-left .arrow:before {
            border-left-color: #ffc107
        }

        .tooltip.b-tooltip-warning .tooltip-inner {
            color: #212529;
            background-color: #ffc107
        }

        .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top] .arrow:before,
        .tooltip.b-tooltip-danger.bs-tooltip-top .arrow:before {
            border-top-color: #dc3545
        }

        .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow:before,
        .tooltip.b-tooltip-danger.bs-tooltip-right .arrow:before {
            border-right-color: #dc3545
        }

        .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
        .tooltip.b-tooltip-danger.bs-tooltip-bottom .arrow:before {
            border-bottom-color: #dc3545
        }

        .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow:before,
        .tooltip.b-tooltip-danger.bs-tooltip-left .arrow:before {
            border-left-color: #dc3545
        }

        .tooltip.b-tooltip-danger .tooltip-inner {
            color: #fff;
            background-color: #dc3545
        }

        .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top] .arrow:before,
        .tooltip.b-tooltip-light.bs-tooltip-top .arrow:before {
            border-top-color: #f8f9fa
        }

        .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow:before,
        .tooltip.b-tooltip-light.bs-tooltip-right .arrow:before {
            border-right-color: #f8f9fa
        }

        .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
        .tooltip.b-tooltip-light.bs-tooltip-bottom .arrow:before {
            border-bottom-color: #f8f9fa
        }

        .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow:before,
        .tooltip.b-tooltip-light.bs-tooltip-left .arrow:before {
            border-left-color: #f8f9fa
        }

        .tooltip.b-tooltip-light .tooltip-inner {
            color: #212529;
            background-color: #f8f9fa
        }

        .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top] .arrow:before,
        .tooltip.b-tooltip-dark.bs-tooltip-top .arrow:before {
            border-top-color: #343a40
        }

        .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow:before,
        .tooltip.b-tooltip-dark.bs-tooltip-right .arrow:before {
            border-right-color: #343a40
        }

        .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
        .tooltip.b-tooltip-dark.bs-tooltip-bottom .arrow:before {
            border-bottom-color: #343a40
        }

        .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow:before,
        .tooltip.b-tooltip-dark.bs-tooltip-left .arrow:before {
            border-left-color: #343a40
        }

        .tooltip.b-tooltip-dark .tooltip-inner {
            color: #fff;
            background-color: #343a40
        }

        .b-icon.bi {
            display: inline-block;
            overflow: visible;
            vertical-align: -.15em
        }

        .b-icon.b-icon-animation-cylon,
        .b-icon.b-iconstack .b-icon-animation-cylon>g {
            transform-origin: center;
            -webkit-animation: b-icon-animation-cylon .75s ease-in-out infinite alternate;
            animation: b-icon-animation-cylon .75s ease-in-out infinite alternate
        }

        @media (prefers-reduced-motion:reduce) {

            .b-icon.b-icon-animation-cylon,
            .b-icon.b-iconstack .b-icon-animation-cylon>g {
                -webkit-animation: none;
                animation: none
            }
        }

        .b-icon.b-icon-animation-cylon-vertical,
        .b-icon.b-iconstack .b-icon-animation-cylon-vertical>g {
            transform-origin: center;
            -webkit-animation: b-icon-animation-cylon-vertical .75s ease-in-out infinite alternate;
            animation: b-icon-animation-cylon-vertical .75s ease-in-out infinite alternate
        }

        @media (prefers-reduced-motion:reduce) {

            .b-icon.b-icon-animation-cylon-vertical,
            .b-icon.b-iconstack .b-icon-animation-cylon-vertical>g {
                -webkit-animation: none;
                animation: none
            }
        }

        .b-icon.b-icon-animation-fade,
        .b-icon.b-iconstack .b-icon-animation-fade>g {
            transform-origin: center;
            -webkit-animation: b-icon-animation-fade .75s ease-in-out infinite alternate;
            animation: b-icon-animation-fade .75s ease-in-out infinite alternate
        }

        @media (prefers-reduced-motion:reduce) {

            .b-icon.b-icon-animation-fade,
            .b-icon.b-iconstack .b-icon-animation-fade>g {
                -webkit-animation: none;
                animation: none
            }
        }

        .b-icon.b-icon-animation-spin,
        .b-icon.b-iconstack .b-icon-animation-spin>g {
            transform-origin: center;
            -webkit-animation: b-icon-animation-spin 2s linear infinite normal;
            animation: b-icon-animation-spin 2s linear infinite normal
        }

        @media (prefers-reduced-motion:reduce) {

            .b-icon.b-icon-animation-spin,
            .b-icon.b-iconstack .b-icon-animation-spin>g {
                -webkit-animation: none;
                animation: none
            }
        }

        .b-icon.b-icon-animation-spin-reverse,
        .b-icon.b-iconstack .b-icon-animation-spin-reverse>g {
            transform-origin: center;
            animation: b-icon-animation-spin 2s linear infinite reverse
        }

        @media (prefers-reduced-motion:reduce) {

            .b-icon.b-icon-animation-spin-reverse,
            .b-icon.b-iconstack .b-icon-animation-spin-reverse>g {
                -webkit-animation: none;
                animation: none
            }
        }

        .b-icon.b-icon-animation-spin-pulse,
        .b-icon.b-iconstack .b-icon-animation-spin-pulse>g {
            transform-origin: center;
            -webkit-animation: b-icon-animation-spin 1s steps(8) infinite normal;
            animation: b-icon-animation-spin 1s steps(8) infinite normal
        }

        @media (prefers-reduced-motion:reduce) {

            .b-icon.b-icon-animation-spin-pulse,
            .b-icon.b-iconstack .b-icon-animation-spin-pulse>g {
                -webkit-animation: none;
                animation: none
            }
        }

        .b-icon.b-icon-animation-spin-reverse-pulse,
        .b-icon.b-iconstack .b-icon-animation-spin-reverse-pulse>g {
            transform-origin: center;
            animation: b-icon-animation-spin 1s steps(8) infinite reverse
        }

        @media (prefers-reduced-motion:reduce) {

            .b-icon.b-icon-animation-spin-reverse-pulse,
            .b-icon.b-iconstack .b-icon-animation-spin-reverse-pulse>g {
                -webkit-animation: none;
                animation: none
            }
        }

        .b-icon.b-icon-animation-throb,
        .b-icon.b-iconstack .b-icon-animation-throb>g {
            transform-origin: center;
            -webkit-animation: b-icon-animation-throb .75s ease-in-out infinite alternate;
            animation: b-icon-animation-throb .75s ease-in-out infinite alternate
        }

        @media (prefers-reduced-motion:reduce) {

            .b-icon.b-icon-animation-throb,
            .b-icon.b-iconstack .b-icon-animation-throb>g {
                -webkit-animation: none;
                animation: none
            }
        }

        @-webkit-keyframes b-icon-animation-cylon {
            0% {
                transform: translateX(-25%)
            }

            to {
                transform: translateX(25%)
            }
        }

        @keyframes b-icon-animation-cylon {
            0% {
                transform: translateX(-25%)
            }

            to {
                transform: translateX(25%)
            }
        }

        @-webkit-keyframes b-icon-animation-cylon-vertical {
            0% {
                transform: translateY(25%)
            }

            to {
                transform: translateY(-25%)
            }
        }

        @keyframes b-icon-animation-cylon-vertical {
            0% {
                transform: translateY(25%)
            }

            to {
                transform: translateY(-25%)
            }
        }

        @-webkit-keyframes b-icon-animation-fade {
            0% {
                opacity: .1
            }

            to {
                opacity: 1
            }
        }

        @keyframes b-icon-animation-fade {
            0% {
                opacity: .1
            }

            to {
                opacity: 1
            }
        }

        @-webkit-keyframes b-icon-animation-spin {
            0% {
                transform: rotate(0)
            }

            to {
                transform: rotate(359deg)
            }
        }

        @keyframes b-icon-animation-spin {
            0% {
                transform: rotate(0)
            }

            to {
                transform: rotate(359deg)
            }
        }

        @-webkit-keyframes b-icon-animation-throb {
            0% {
                opacity: .5;
                transform: scale(.5)
            }

            to {
                opacity: 1;
                transform: scale(1)
            }
        }

        @keyframes b-icon-animation-throb {
            0% {
                opacity: .5;
                transform: scale(.5)
            }

            to {
                opacity: 1;
                transform: scale(1)
            }
        }

        .btn .b-icon.bi,
        .dropdown-item .b-icon.bi,
        .dropdown-toggle .b-icon.bi,
        .input-group-text .b-icon.bi,
        .nav-link .b-icon.bi {
            font-size: 125%;
            vertical-align: text-bottom
        }

        .sr-only {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px
        }

        .sr-only-focusable:active,
        .sr-only-focusable:focus {
            clip: auto;
            height: auto;
            margin: 0;
            overflow: visible;
            position: static;
            width: auto
        }
        @font-face {
            font-family: 'Leelawadee UI';
            src: url('../assets/fonts/LeelUIsl.ttf') format('truetype');
            /* อาจต้องระบุเพิ่มเติมสำหรับรูปแบบไฟล์อื่น ๆ ที่คุณใช้ */
        }
        @font-face {
            font-family: 'Leelawadee UI';
            src: url('../assets/fonts/LeelaUIb.ttf') format('truetype');
            font-weight: bold;
        }
        @font-face {
            font-family: "Font Awesome 5 Brands";
            font-style: normal;
            font-weight: 400;
            font-display: block;
            src: url(https://laoshd.com/_nuxt/fonts/fa-brands-400.89a52ae.eot);
            src: url(https://laoshd.com/_nuxt/fonts/fa-brands-400.89a52ae.eot?#iefix) format("embedded-opentype"), url(https://laoshd.com/_nuxt/fonts/fa-brands-400.c1210e5.woff2) format("woff2"), url(https://laoshd.com/_nuxt/fonts/fa-brands-400.329a95a.woff) format("woff"), url(https://laoshd.com/_nuxt/fonts/fa-brands-400.9e13849.ttf) format("truetype"), url(https://laoshd.com/_nuxt/img/fa-brands-400.216edb9.svg#fontawesome) format("svg")
        }

        .fab {
            font-family: "Font Awesome 5 Brands"
        }

        @font-face {
            font-family: "Font Awesome 5 Free";
            font-style: normal;
            font-weight: 400;
            font-display: block;
            src: url(https://laoshd.com/_nuxt/fonts/fa-regular-400.4079ae2.eot);
            src: url(https://laoshd.com/_nuxt/fonts/fa-regular-400.4079ae2.eot?#iefix) format("embedded-opentype"), url(https://laoshd.com/_nuxt/fonts/fa-regular-400.68c5af1.woff2) format("woff2"), url(https://laoshd.com/_nuxt/fonts/fa-regular-400.3672264.woff) format("woff"), url(https://laoshd.com/_nuxt/fonts/fa-regular-400.1017bce.ttf) format("truetype"), url(https://laoshd.com/_nuxt/img/fa-regular-400.19e27d3.svg#fontawesome) format("svg")
        }

        .fab,
        .far {
            font-weight: 400
        }

        @font-face {
            font-family: "Font Awesome 5 Free";
            font-style: normal;
            font-weight: 900;
            font-display: block;
            src: url(https://laoshd.com/_nuxt/fonts/fa-solid-900.efbd5d2.eot);
            src: url(https://laoshd.com/_nuxt/fonts/fa-solid-900.efbd5d2.eot?#iefix) format("embedded-opentype"), url(https://laoshd.com/_nuxt/fonts/fa-solid-900.ada6e6d.woff2) format("woff2"), url(https://laoshd.com/_nuxt/fonts/fa-solid-900.c6ec080.woff) format("woff"), url(https://laoshd.com/_nuxt/fonts/fa-solid-900.07c3313.ttf) format("truetype"), url(https://laoshd.com/_nuxt/img/fa-solid-900.13de59f.svg#fontawesome) format("svg")
        }

        .fa,
        .far,
        .fas {
            font-family: "Font Awesome 5 Free"
        }

        .fa,
        .fas {
            font-weight: 900
        }

        .nuxt-progress {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            height: 2px;
            width: 0;
            opacity: 1;
            transition: width .1s, opacity .4s;
            background-color: #000;
            z-index: 999999
        }

        .nuxt-progress.nuxt-progress-notransition {
            transition: none
        }

        .nuxt-progress-failed {
            background-color: red
        }

        body {
            background: #fff url(https://laoshd.com/_nuxt/img/bg.e69cdad.png) repeat-x left 0
        }

        .container {
            padding-left: 0;
            padding-right: 0;
            max-width: 1000px
        }

        #header img {
            max-width: 100%
        }

        #content {
            display: flex;
            padding: 10px 0
        }

        #content .c-content {
            width: 690px;
            margin-right: 10px
        }

        #content .c-content .banner {
            max-width: 100%
        }

        #content .r-content {
            width: 300px
        }

        #content .r-content .box-banner {
            margin-bottom: 5px;
            text-align: center
        }

        #content .r-content .box-banner .banner {
            width: 300px;
            max-width: 100%
        }

        @media screen and (max-width:690px) {
            #content {
                display: block;
                padding: 5px
            }

            #content .c-content,
            #content .r-content {
                width: 100%
            }
        }

        .today-result {
            min-height: 150px;
            color: #000;
            /* padding: 20px 50px; */
            /* background: url(https://laoshd.com/_nuxt/img/today-result-bg.169adeb.png) no-repeat top; */
            background-size: 100% 100%
        }

        .today-result h1 {
            color: #000;
            font-weight: 700;
            font-size: 60px;
            letter-spacing: 5px
        }

        .today-result h1 .block-numbers {
            display: flex;
            justify-content: center
        }

        .today-result h1 .block-numbers span {
            display: flex;
            justify-content: center
        }

        .today-result h3 {
            color: #000
        }

        .today-result table {
            margin-top: 20px;
            max-width: 100%;
            border-collapse: collapse;
            border-spacing: 0;
        }

        .today-result table tr td {
            text-align: center;
            padding: 5px;
            font-size: 16px;
            height: 30px;
        }

        @media(max-width:768px) {
            .today-result {
                padding: 0px
            }

            .today-result h3 {
                font-size: 1.5rem
            }
        }

        .history-result[data-v-74acf6b2] {
            color: #000;
            background-color: #fff;
            background: url(https://laoshd.com/_nuxt/img/history-bg.be5690e.png) no-repeat top;
            background-size: cover
        }

        .history-result .history-p[data-v-74acf6b2] {
            padding: 15px
        }

        .history-result .history-header[data-v-74acf6b2] {
            background: url(https://laoshd.com/_nuxt/img/history-header-bg.288857d.png) no-repeat 50%;
            background-size: cover;
            color: #fff;
            font-size: 26px
        }

        .history-result .history-footer[data-v-74acf6b2] {
            height: 6px;
            background-color: #fff;
            background: url(https://laoshd.com/_nuxt/img/history-bg.be5690e.png) no-repeat bottom;
            background-size: cover
        }

        .history-result table[data-v-74acf6b2] {
            width: 100%;
            max-width: 100%;
            border-collapse: collapse;
            border-spacing: 0;
            background-color: #fff
        }

        .history-result table thead tr th[data-v-74acf6b2] {
            text-align: center;
            font-size: 26px
        }

        .history-result table tbody tr td[data-v-74acf6b2] {
            text-align: center;
            border: 1px solid #999;
            padding: 5px;
            font-size: 16px
        }

        @media(max-width:768px) {
            .history-result .history-p[data-v-74acf6b2] {
                padding: 10px
            }
        }

        #footer {
            background: #225da3;
            overflow: hidden;
            color: #fff;
            padding: 15px 0;
            text-align: center;
            margin-bottom: 0
        }
        .datatable_cell__wrapper__ddKL4 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tbl {
  border-collapse: collapse;
  width:100%;
}
.tbl th,
.tbl td {
  padding: 5px;
  border: solid 1px #777;
}
.tbl th {
  background-color: #005790;
}
</style>
